/*
 *   File : amount-input.js
 *   Author : https://evoqins.com
 *   Description : Amount input component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { memo, useEffect, useState } from "react";

const MAX_VALUE = 999999999;

const CustomAmountInput = (props) => {

    const [value, setValue] = useState(props.value);
    const [isInputFocused, setIsInputFocused] = useState(false);


    useEffect(() => {
        setValue(props.value.toLocaleString("en-IN"));
    }, [props.value]);

    const _handleChange = (event) => {
        const input_value = event.target.value;

        // Removing space and comma
        const formatted_value = Number(input_value.replaceAll(",", ''));
        const max_value = props.maxValue || MAX_VALUE;

        if (isNaN(formatted_value) === false) {
            if (formatted_value <= max_value) {
                setValue(formatted_value.toLocaleString("en-IN"));
                props.handleChange(formatted_value);
            }
        } else {
            setValue('');
            props.handleChange('');
        }
    };


    const _preventDefaults = (e) => {
        const key_code = e.keyCode || e.which;
        const is_ctrl_pressed = e.ctrlKey || e.metaKey; // Check if Ctrl key is pressed


        if (key_code == 9 || // Tab key
            (is_ctrl_pressed && (key_code == 65 || key_code == 67 || key_code == 88 || key_code == 86))
        ) {
            // Ctrl+A, Ctrl+C, Ctrl+X, or Ctrl+V    
            e.preventDefault();
        }

        if (
            (key_code >= 48 && key_code <= 57) || // 0-9
            (key_code >= 96 && key_code <= 105) || // Numpad 0-9
            key_code === 8 || // Backspace
            (key_code >= 37 && key_code <= 40) // Arrow keys
        ) {
            // Allow the keypress
        } else {
            e.preventDefault();
        }
    };

    const _handleFocus = () => {
        setIsInputFocused(true);
    };

    const _handleBlur = () => {
        setIsInputFocused(false);
    };


    return (
        <>

            {/* label */}
            <label className={`${props.labelStyle} e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14 mb-2`}
                htmlFor={props.label}>
                {props.label}
                {
                    props.require &&
                    <span className='e-text-red'>*</span>
                }
            </label>

            <div className={`${props.errorMsg && 'e-border-red-1px'} ${isInputFocused ? "e-border-1px-primary-blue" : "e-border-1px-tab-color"}  d-flex align-items-center cursor-pointer e-border-radius-8 bg-white`}>
                {<p className={`${value < 1 && "opacity-75"} padding-12px-tb ps-3  mb-0 e-font-14 e-text-chinese-black e-font-weight-500 bg-white`}>
                    ₹
                </p>}
                <input required
                    // value={value && value.toLocaleString('en-IN')}
                    value={value}
                    id={props.label}
                    type="text"
                    className={`${props.className} bg-white e-text-chinese-black w-100 outline-none e-font-14 e-font-weight-400  border-0 pe-3 ps-1 e-padding-tb-12px e-border-radius-8`}
                    placeholder={props.placeholder}
                    disabled={props.isDisabled}
                    autoComplete="off"
                    onChange={_handleChange}
                    onFocus={_handleFocus}
                    onBlur={_handleBlur}
                    onKeyDown={_preventDefaults}
                />
            </div>

            {/* Error text */}
            <p className={`${props.errorMsg === "" ? "opacity-0" : "opacity-100 "} h-6 mb-2 e-error-message e-font-12 ps-1 line-height-12px pt-1`}>
                {props.errorMsg}
            </p>

        </>
    );
};

export default memo(CustomAmountInput);
