/*
 *   File : confirm-unlink-family-member.js
 *   Author : https://evoqins.com
 *   Description : Confirm and remove family member modal
 *   Integrations : react-toastify
 *   Version : 1.0.0
 *   Date : 29-05-2024
*/

import React, { memo, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

//custom component
import { PrimaryBtn, SecondaryBtn } from '../Cta';
import service from '../../Services/service';
import { _closeModal } from '../../Helper/function';

const ConfirmUnlinkFamilyMember = (props) => {

    const [loading, setLoading] = useState(false);

    // Closing modal when "ESC" pressed
    useEffect(() => {
        _closeModal(props.onCloseModal);
    }, []);

    //handle remove member
    const _handleUnlinkMember = () => {
        setLoading(true);
        _unlinkFamilyMember();
    };


    //======= API CALLS =======//

    //API- Remove family member
    const _unlinkFamilyMember = () => {
        let is_post = true;
        let url = '/family/remove';
        let data = {
            "family_detail_id": props.familyDetailID
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("Family member unlinked successfully");
                let close_btn = document.getElementById("confirm-unlink-member-modal-close");
                close_btn.click();
                props.handleSuccess();
            } else {
                toast.dismiss();
                toast.error(response.data.message);
            }

            setLoading(false);
        })
    };

    return (
        <div className={`modal fade`}
            id="confirm-unlink-member-modal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="Confirm unlink member modal"
            aria-hidden="true">
            <div className={`modal-dialog  modal-dialog-centered`}>
                <div className="modal-content e-border-radius-8 p-0">
                    <div className="modal-header justify-content-between p-4 border-0">
                        <h6 className='mb-0 e-text-chinese-black e-font-16 e-font-manrope e-font-weight-700 line-height-32px'>
                            Unlink family member
                        </h6>
                        <button type="button"
                            id="confirm-unlink-member-modal-close"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={props.onCloseModal}></button>
                    </div>
                    <div className="modal-body pb-4 px-4 pt-0">
                        <p className='mb-2 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-500 line-height-18px '>
                            The family member will no longer be associated with the family head
                        </p>
                        <div className='row margin-top-32px justify-content-end'>
                            <div className='col-4'>
                                <SecondaryBtn name="Cancel"
                                    className="w-100 h-100 py-2"
                                    cancel="modal"
                                    handleClick={props.onCloseModal} />
                            </div>
                            <div className='col-4 text-center'>
                                <PrimaryBtn name="Confirm"
                                    loading={loading}
                                    disabled={loading}
                                    className="d-flex justify-content-center w-100 h-40"
                                    handleClick={_handleUnlinkMember} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(ConfirmUnlinkFamilyMember);