
/*
 *   File : tab.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Tab component
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 28-11-2023
*/

/*import package*/
import React, { useState } from 'react';

/*import styles*/
import styles from '../../Styles/Components/tab.module.scss';

const Tab = (props) => {

	const [index, setIndex] = useState(props.currentIndex);

	return (
		<div className="d-flex gap-20px overflow-scroll pb-2 e-manage-scrollbar e-tab-scrollbar">
			{
				props.data.map((item, key) => {
					return (
						<span
							className={`text-nowrap ${props.className} ${index === key + 1 ? styles.e_active_tab : styles.e_tab}`}
							onClick={() => { setIndex((key + 1)); props.setTab((key + 1), item) }}
							key={key}
							data-bs-target={props.target}
							title={props.tooltip ? props.title(item) : null}
						>
							<span>{item.topic ? item.topic : item}</span>
						</span>
					)
				})
			}
		</div>
	);
}

export default Tab;

