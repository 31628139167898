/*
 *   File : goal-invest.js
 *   Author : https://evoqins.com
 *   Description : Goal Investment modal
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import IcoMoon from "react-icomoon";
import iconSet from "../../Assets/Images/Icons/selection.json";

// Component
import { CustomTextInput, OTPInput, RadioGroup } from "../Form-Elements";
import { SIPDateModal } from ".";
import { PrimaryBtn, SecondaryBtn } from "../Cta";
import { _createMandate, _formatSipDate, _goalSummary, _sendOTP } from "../../Helper";
import service from "../../Services/service";
import { _closeModal } from "../../Helper/function";


// Assets
import Active from "../../Assets/Images/Kyc/circle-active.svg";
import Inactive from "../../Assets/Images/Kyc/circle-inactive.svg";
import UPI from "../../Assets/Images/General/payment_upi.svg";
import NetBanking from "../../Assets/Images/General/payment_net_banking.svg";

// Colors
import Colors from "../../Styles/config.scss";


// Custom data
// SIP mandate information
const SIP_MANDATE_INFO = [
    "For first SIP order, a mandate will be created automate monthly recurring transactions",
    "The mandate is for Rs. 5 lakhs, which will be used for future rebalancing and other SIP.",
    "We won't deduct any extra money other than the SIP order amount using the mandate.",
    "Once the mandate has been created, you can proceed with the first installment."
];

const CONFIRM_PAYMENT = [
    {
        id: 1,
        display_name: "Yes",
    },

    {
        id: 2,
        display_name: "No",
    },
];

function GoalInvestModal(props) {

    const [goalSummary, setGoalSummary] = useState(props.summary);
    const [userData, setUserData] = useState(props.userData);
    const [goalId, setGoalId] = useState(props.goalId);
    const otpInputRef = useRef(null);

    const [focusedInput, setFocusedInput] = useState(0);
    const [toggleInvest, setToggleInvest] = useState(false);
    const [toggleDateModal, setToggleDateModal] = useState(false);
    const [mandateStatus, setMandateStatus] = useState(true);
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [selectedPaymentType, setSelectedPaymentType] = useState("");
    const [isPayingNow, setIsPayingNow] = useState(1);

    // OTP
    const [otpValue, setOtpValue] = useState("");
    const [timer, setTimer] = useState(30);

    // for sip date
    const [sipActiveDates, setSipActiveDates] = useState([]);
    const [selectedSIPDate, setSelectedSIPDate] = useState("");
    const [selectedSIPDateString, setSelectedSIPDateString] = useState("");

    // For btn
    const [loading, setLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    // Closing modal when "ESC" pressed
    useEffect(() => {
        _closeModal(props.onCloseModal);
    }, []);

    useEffect(() => {
        setUserData(props.userData);
    }, [props.userData]);

    useEffect(() => {
        setGoalId(props.goalId);
    }, [props.goalId]);

    useEffect(() => {

        setGoalSummary(props.summary);
        setMandateStatus(props.summary.mandate_status);

        if (props.summary.payment_types.length !== 0) {
            let payment_types = props.summary.payment_types;
            payment_types = payment_types.map(data => {
                return data.id == 2 ? { ...data, image: UPI } : data.id == 1 ? { ...data, image: NetBanking } : data;
            });
            setPaymentMethod(payment_types);
            setSelectedPaymentType(payment_types[0].label);
        }
    }, [props.summary]);

    useEffect(() => {
        if (selectedSIPDate !== "") {
            const formatted_sip_date = _formatSipDate(selectedSIPDate);  // Formatting selected SIP date
            setSelectedSIPDateString(formatted_sip_date);
        };
    }, [selectedSIPDate]);

    // Setting default selected sip date
    useEffect(() => {

        const sip_dates = props.summary.sip_dates.split(",").map(Number);
        setSipActiveDates(sip_dates);
        const default_date = new Date().getDate() + 1;

        if (default_date > 28) {
            setSelectedSIPDate(sip_dates[0]);
        } else {
            if (sip_dates.includes(default_date)) {
                setSelectedSIPDate(default_date);
            } else {
                setSelectedSIPDate(sip_dates[0]);
            };
        };

    }, [props.summary.sip_dates]);

    // Closing Sip date modal when clicking outside
    useEffect(() => {
        window.addEventListener("click", (e) => {
            if (!e.target.closest("#sip-date-modal")) {
                setToggleDateModal(false);
            }
        });

        return window.removeEventListener("click", (e) => {
            if (!e.target.closest("#sip-date-modal")) {
                setToggleDateModal(false);
            }
        });
    }, []);

    //for timer
    useEffect(() => {
        if (toggleInvest === true && timer > 0) {
            // Start the timer when requestOtpSuccess becomes true
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            // Clear the timer when the component unmounts or when the timer reaches 0
            return () => {
                clearInterval(countdown);
            };
        }
    }, [toggleInvest, timer]);

    const _handleConfirmPayingNow = (status) => setIsPayingNow(status);
    const _handlePaymentMethod = (payment_type) => setSelectedPaymentType(payment_type);

    //handle SIP date select
    const _handleSIPDateSelect = (date) => {
        setSelectedSIPDate(date);
        const formatted_sip_date = _formatSipDate(date); // Formatting selected SIP date
        setSelectedSIPDateString(formatted_sip_date);
    };

    const _handleCloseDateModal = () => {
        setToggleDateModal(false);
    };

    // handle toggle invest now 
    const _handleToggleInvest = () => {

        setLoading(true);

        if (goalSummary.mandate_status === false) {

            let param = {
                create: true,
                customer_id: userData.id,
            };

            // Call CreateMandate API
            _createMandate(param).then(response => {
                if (response.status_code === 200 && response.data.mandate_url) {
                    setLoading(false);
                    // Preventing from multiple request
                    setIsDisabled(true);

                    const close_btn = document.querySelector("#goal-invest-modal-close");
                    close_btn.click();

                    props.onSuccess("INITIATE_MANDATE", response.data.mandate_url);

                } else {
                    setLoading(false);
                    setIsDisabled(false);
                    toast.dismiss();
                    toast.error(response.data.message);
                };
            });

            return;
        };

        // API Call to send OTP
        setFocusedInput(2)
        setLoading(true);
        _getOTP();
    };

    //handle back
    const _handleBack = () => {
        if (isDisabled) return;
        setToggleInvest(false);
        setOtpValue("");
        setLoading(false);
        setIsDisabled(false);
        otpInputRef.current.disabled = false;
    };

    //handle confirm otp
    const _handleChangeOTP = (value) => {
        setOtpValue(value);
    };

    const _handleResendOTP = () => {
        let resend = true;
        _getOTP(resend);
    };

    const _handleModalClose = () => {
        setToggleInvest(false);
        setIsPayingNow(true);
        setToggleDateModal(false);
        setSelectedSIPDate("");
        setPaymentMethod();

        // To change the modal open status
        props.onCloseModal();
    };

    //handle invest MF 
    const _handleInvestGoal = () => {

        if (otpValue.length !== 4) return;

        // btn loader
        setLoading(true);
        setIsDisabled(true);
        // API Call for invest in selected goal
        _investGoal();
    };

    // API - send OTP 
    const _getOTP = (resend) => {

        // API CALL from helper function
        const param = {
            type: "Goal",
            id: userData.id,
        };

        _sendOTP(param).then(status => {
            if (status.status_code === 200) {

                setOtpValue("");
                setTimer(30);

                if (resend === true) {
                    setFocusedInput(2);
                    toast.dismiss();
                    toast.success("OTP re-sent successfully", {
                        className: "e-toast",
                    });
                } else {
                    setToggleInvest(true);
                };

            } else {
                toast.dismiss();
                toast.error(status.data.message, {
                    className: "e-toast",

                });
            };

            setLoading(false);
        });
    };

    // API -  investGoal 
    const _investGoal = () => {
        let is_post = true;
        let url = '/goal/invest';
        let data = {
            "type": null,
            "customer_id": userData.id,
            "goal_id": goalId,
            "sip": {
                "amount": goalSummary.sip.amount,
                "date": selectedSIPDate,
                "get_first_installment": goalSummary.lumpsum === null ? isPayingNow === 1 ? true : false : false,
            },
            "payment_type": isPayingNow === 1 ? selectedPaymentType : false,
            "otp": Number(otpValue),
            "platform": "web",
            "lumpsum": goalSummary.lumpsum === null ? null : ({ "amount": goalSummary.lumpsum.amount }),
        }

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                const close_btn = document.querySelector("#goal-invest-modal-close");
                close_btn.click();

                if (response.data.payment_url) {
                    props.onSuccess("PAYMENT_URL_CREATED", response.data.payment_url);
                } else {
                    props.onSuccess("SIP_SCHEDULED");
                };

            } else {
                toast.dismiss();
                toast.error(response.data.message);
                setIsDisabled(false);
                setLoading(false);
                setToggleInvest(false);
            };
        });
    };


    return (
        <div className="modal fade e-manage-modal"
            id="invest-goal-modal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="GoalInvestLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                {
                    toggleInvest === false ?
                        <div className="modal-content e-bg-lotion e-border-radius-8 e-modal-box-shadow p-0">
                            <div className="modal-header border-0 justify-content-between py-3 px-3">
                                <h6 className='mb-0 e-text-chinese-black e-font-18 e-font-manrope e-font-weight-600 line-height-32px'>
                                    Investment details
                                </h6>
                                <button type="button"
                                    id='goal-invest-modal-close'
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={_handleModalClose}></button>
                            </div>

                            {/* Body */}
                            <div className="d-flex flex-column gap-3 modal-body pb-4 px-3 pt-0">
                                <div className='d-flex column-gap-2 align-items-center e-bg-cultured e-border-radius-16 p-2'>
                                    <img src={goalSummary.icon}
                                        draggable={false}
                                        className="object-fit-contain  e-border-radius-10 me-1"
                                        width={40}
                                        height={40}
                                        alt='goal logo' />
                                    <div>
                                        <p className='mb-0 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-600 line-height-22px'>
                                            Life goal : <span>{goalSummary.goal}</span>
                                        </p>
                                    </div>
                                </div>

                                {/* Investment preview */}
                                <h6 className='e-font-14-res mb-0 e-text-chinese-black e-font-16 e-font-manrope e-font-weight-600'>
                                    Investment preview
                                </h6>
                                <div className="row gap-1 e-bg-lavender e-border-radius-16 p-3 m-0">
                                    <div className="d-flex p-0">
                                        <div className="col-6">
                                            <span className="e-text-chinese-black e-font-manrope e-font-14 e-font-weight-500 line-height-24px">
                                                SIP amount :
                                            </span>
                                        </div>
                                        <div className="col-6">
                                            <span className="e-text-primary e-font-manrope e-font-16 e-font-weight-600 line-height-24px">
                                                ₹{goalSummary.sip.amount.toLocaleString('en-IN')}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="d-flex p-0">
                                        <div className="col-6">
                                            <span className="e-text-chinese-black e-font-manrope e-font-14 e-font-weight-500 line-height-24px">
                                                Tenure :
                                            </span>
                                        </div>
                                        <div className="col-6">
                                            <span className="e-text-primary e-font-manrope e-font-16 e-font-weight-600 line-height-24px">
                                                {goalSummary.tenure}{goalSummary.tenure < 2 ? " yr" : " yrs"}
                                            </span>
                                        </div>

                                    </div>
                                    <div className="d-flex p-0">
                                        <div className="col-6">
                                            <span className="e-text-chinese-black e-font-manrope e-font-14 e-font-weight-500 line-height-24px">
                                                Lumpsum amount :
                                            </span>
                                        </div>
                                        <div className="col-6">
                                            <span className="e-text-primary e-font-manrope e-font-16 e-font-weight-600 line-height-24px">
                                                ₹{goalSummary.lumpsum !== null ? goalSummary.lumpsum.amount.toLocaleString('en-IN') : 0}
                                            </span>
                                        </div>

                                    </div>
                                </div>

                                {/* Monthly SIP */}
                                <div className='e-bg-cultured  e-border-radius-16 p-3 mt-1'>
                                    <div className="position-relative"
                                        id='sip-date-modal'>
                                        <CustomTextInput require={true}
                                            type={2}
                                            label='Monthly SIP date'
                                            value={selectedSIPDateString}
                                            placeholder="Choose date"
                                            className="cursor-pointer w-100 e-font-14 e-font-manrope e-font-weight-500 no-outline"
                                            maxLength={8}
                                            readOnly
                                            onClick={() => setToggleDateModal(status => !status)}
                                        />
                                        {
                                            toggleDateModal &&
                                            <div className="position-relative" >
                                                <SIPDateModal
                                                    selectedSIPDate={selectedSIPDate}
                                                    sipDates={sipActiveDates}
                                                    className="position-absolute start-0"
                                                    handleSelect={_handleSIPDateSelect}
                                                    handleCloseDateModal={_handleCloseDateModal}
                                                />
                                            </div>
                                        }
                                    </div>
                                    {
                                        goalSummary.lumpsum === null &&
                                        <div>
                                            <p className='mb-2 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-500 line-height-24px'>
                                                Would you like to proceed with the first instalment at this time?
                                            </p>

                                            {/* confirmation radio button for paying now */}
                                            <div className="d-flex gap-3">
                                                <RadioGroup data={CONFIRM_PAYMENT}
                                                    selected={isPayingNow}
                                                    handleSelect={_handleConfirmPayingNow} />
                                            </div>
                                        </div>
                                    }
                                </div>

                                {/* Payment Method */}
                                {
                                    (isPayingNow == 1 || goalSummary.lumpsum > 0) &&
                                    <div>
                                        <h6 className='e-font-14-res mb-3 e-text-chinese-black e-font-manrope e-font-16 e-font-weight-600 line-height-24px'>
                                            Payment Method
                                        </h6>
                                        <div className='p-3 e-border-radius-16 e-bg-cultured '>
                                            {
                                                paymentMethod.map((data, key) => (
                                                    <React.Fragment key={key}>
                                                        <div className={`${selectedPaymentType === data.slug ? "cursor-default" : "cursor-pointer"} d-flex justify-content-between `}
                                                            onClick={() => _handlePaymentMethod(data.slug)}>
                                                            <p className="d-flex align-items-center gap-2 e-text-chinese-black mb-0 e-font-14 e-font-manrope e-font-weight-500 line-height-22px">
                                                                <img src={data.image}
                                                                    alt={`payment method ${data.label}`}
                                                                    draggable="false" />
                                                                <span>{data.label}</span>
                                                            </p>
                                                            <img src={selectedPaymentType === data.slug ? Active : Inactive}
                                                                draggable="false"
                                                                className={`w-24px width-20px-res`}
                                                                alt="radio" />
                                                        </div>
                                                        {
                                                            key == paymentMethod.length - 2 && paymentMethod.length > 1 &&
                                                            <hr style={{ borderColor: "#D1D1D6" }} />
                                                        }
                                                    </React.Fragment>
                                                ))
                                            }
                                        </div >
                                    </div>
                                }

                                {/* SIP Mandate Infromation */}
                                {
                                    goalSummary.mandate_status === false &&
                                    <div className="d-flex flex-column gap-2 mt-1 e-bg-cosmic-latte  e-border-radius-16 p-3">
                                        <h6 className='d-flex gap-2 align-items-center e-text-mustard-yellow e-font-weight-600 line-height-18px'>
                                            <IcoMoon iconSet={iconSet}
                                                icon={"info"}
                                                size={"1.125rem"}
                                                color={Colors.mustard_yellow} />
                                            Information
                                        </h6>
                                        <div className='d-flex flex-column gap-2'>
                                            {
                                                SIP_MANDATE_INFO.map((item, index) => (
                                                    <p className='d-flex gap-1 e-font-manrope e-text-onyx mb-0 e-font-14 e-font-weight-400 line-height-24px'>
                                                        {index + 1}{"."}
                                                        <span>{item}</span>
                                                    </p>
                                                ))
                                            }
                                        </div>
                                    </div>
                                }

                                <div className='mt-3'>
                                    <PrimaryBtn name={goalSummary.mandate_status === false ? "Initiate Mandate" : "Request OTP"}
                                        loading={loading}
                                        className="w-100 h-40"
                                        disabled={isDisabled}
                                        handleClick={_handleToggleInvest} />
                                </div>
                            </div>
                        </div>
                        :
                        <div className="modal-content e-bg-lotion e-border-radius-8 e-modal-box-shadow p-0">
                            <div className="modal-header gap-3 pt-4 pb-3 px-3 border-0">

                                {/* Hidden close btn for closing modal */}
                                <span id='goal-invest-modal-close'
                                    data-bs-dismiss="modal"
                                    className='d-none'
                                    aria-label="close"
                                    alt='close btn' />
                                <img src={require("../../Assets/Images/Cta/back-icon.svg").default}
                                    draggable={false}
                                    className='cursor-pointer'
                                    alt="arrow-back"
                                    onClick={_handleBack} />
                                <h6 className='e-font-14-res mb-0 e-text-chinese-black e-font-18 e-font-manrope e-font-weight-600 line-height-32px'>
                                    Purchase confirmation OTP
                                </h6>

                            </div>
                            <div className="modal-body pb-4 px-3 pt-0">

                                {/* otp section */}
                                <div className='p-3 e-border-radius-16 e-bg-cultured '>
                                    <p className='mb-0 pt-3 e-text-onyx e-font-16 e-font-manrope e-font-weight-400 line-height-28px'>
                                        An OTP has been sent to your registered
                                        {userData.country_code === "+91" ? "  mobile number" : " email"}
                                        {
                                            userData.country_code === "+91" ?
                                                <span className='ms-1 e-text-primary e-font-weight-500'>
                                                    {userData.country_code}-{userData.phone}
                                                </span>
                                                :
                                                <span className='ms-1 e-text-primary e-font-weight-500'>
                                                    {userData.email}
                                                </span>
                                        }
                                    </p>
                                    <div className='pt-3'>
                                        <label className="e-text-chinese-black e-font-14 e-font-manrope e-font-weight-500 line-height-24px">
                                            Enter your OTP*
                                        </label>
                                        <div className='h-64'>
                                            <OTPInput ref={otpInputRef}
                                                id="otp"
                                                value={otpValue}
                                                spellCheck="false"
                                                focused_input={focusedInput}
                                                onFocus={() => setFocusedInput(2)}
                                                onBlur={() => setFocusedInput(0)}
                                                onChange={_handleChangeOTP} />
                                        </div>

                                        <div className='d-flex pt-2 align-items-center'>
                                            <button className={`${timer === 0 ? "text-decoration-hover-underline" : "e-disable"} border-0 bg-transparent e-text-primary e-font-12 e-font-manrope e-font-weight-500 line-height-26px`}
                                                onClick={_handleResendOTP} >
                                                Re-send OTP
                                            </button>
                                            {timer !== 0 &&
                                                <p className='mb-0 e-text-chinese-black e-font-12 e-font-manrope e-font-weight-600 line-height-26px'>
                                                    in {timer} {timer == 1 ? "second" : "seconds"}
                                                </p>
                                            }
                                        </div>
                                    </div>

                                    <p className='mb-0 pt-3 e-text-light-grey e-font-12 e-font-manrope e-font-weight-400 line-height-18px'>
                                        *In accordance with regulatory, 2FA verification is required in order to create an order.
                                    </p>
                                </div>

                                {/* Mandate success msg for SIP */}
                                {
                                    mandateStatus == false &&
                                    <div className='d-flex gap-2 p-3 e-bg-nyanza-green e-border-radius-16 mt-4'>
                                        <IcoMoon iconSet={iconSet}
                                            icon={"mandate-success"}
                                            size={"1.75rem"} />
                                        <p className='e-text-go-green e-font-manrope e-font-14 e-font-weight-400 line-height-24px letter-spacing-3 mb-0'>
                                            Your mandate has been successfully created. Now, you can proceed with the first instalment
                                        </p>
                                    </div>
                                }
                                <div className='mt-4'>
                                    <PrimaryBtn name={isPayingNow == 1 ? "Create & Send Payment URL" : "Schedule SIP"}
                                        loading={loading}
                                        disabled={isDisabled}
                                        className="mt-2 w-100 d-flex align-items-center justify-content-center h-40"
                                        handleClick={_handleInvestGoal} />
                                </div>
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}

export default memo(GoalInvestModal);