
/*
 *   File : gameTab.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Tab component
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 28-11-2023
*/

/*import package*/
import { useEffect, useState } from 'react';

/*import styles*/
import styles from '../../Styles/Components/fund-tab.module.scss';

const FundsTab = (props) => {
    const [index, setIndex] = useState(props.index);

    useEffect(() => {
        setIndex(props.index);
    }, [props.index]);

    return (
        <div className="d-flex">
            {
                props.risk ?
                    <>
                        {
                            props.data.map((item, key) => {
                                return (
                                    <span
                                        className={item.category_id === index ? `${styles.e_active_tab} me-4 e-active-tab` : `${styles.e_tab} me-4 e-tab`}
                                        onClick={() => { setIndex(item.category_id); props.setTab(item.category_id, item) }} key={key}
                                    >
                                        {item.category}
                                    </span>
                                )
                            })
                        }
                    </>
                    :
                    props.funds ?
                        <>
                            {
                                props.data.map((item, key) => {
                                    return (
                                        <span
                                            className={item.id === index ? `${styles.e_active_tab} me-4 e-active-tab` : `${styles.e_tab} me-4 e-tab`}
                                            onClick={() => { setIndex(item.id); props.setTab(item.id, item.name, item.goal) }} key={key}
                                        >
                                            {item.name}
                                        </span>
                                    )
                                })
                            }
                        </>
                        :
                        props.invest ?
                            <>
                                {
                                    props.data.map((item, key) => {
                                        return (
                                            <span
                                                className={item.id === index ? `${styles.e_active_tab} me-4 e-active-tab` : `${styles.e_tab} me-4 e-tab`}
                                                onClick={() => { setIndex(item.id); props.setTab(item.id) }} key={key}
                                            >
                                                {item.label}
                                            </span>
                                        )
                                    })
                                }
                            </>
                            :
                            <>
                                {
                                    props.data.map((item, key) => {
                                        return (
                                            <span
                                                className={index === (key + 1) ? `${styles.e_active_tab} me-4 e-active-tab` : `${styles.e_tab} me-4 e-tab`}
                                                onClick={() => { setIndex((key + 1)); props.setTab((key + 1)) }} key={key}
                                            >
                                                {item}
                                            </span>
                                        )
                                    })
                                }
                            </>

            }

        </div>
    )
}

export default FundsTab;

