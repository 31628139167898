/*
 *   File : login.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Auth Screen
 *   Integrations : 28-11-2023
 *   Version : 1.0.0
 *   Created : 28-11-2023
 */

/*import packages*/
import { useState, useEffect } from 'react';

/*import assets*/

/*import components*/
import { OuterHeader, Footer } from '../../Components/Navigation/index';
import { Input } from '../../Components/Input/index';
import { PrimaryBtn } from '../../Components/Cta/index';
import { Toast } from '../../Components/Note';

/*import services */
import service from '../../Services/service';

const Login = (props) => {

	const [name, setName] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState(null);

	const [toggleOTP, setToggleOTP] = useState(false);
	const [otp, setOtp] = useState("");
	const [email, setEmail] = useState("");
	const [timer, setTimer] = useState(30);

	const [loader, setLoader] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setError(null);
		}, 4000);
	}, [error]);

	useEffect(() => {

		const _handleOnEnter = (e) => {
			if (e.key === 'Enter') {
				if (otp.length == 4) {
					_handleVerifyOTP();
				}
			}
		};

		document.addEventListener('keydown', _handleOnEnter);

		return () => {
			document.removeEventListener('keydown', _handleOnEnter);
		};


	}, [otp]);

	// useEffect(() => {
	// 	if (toggleOTP === true && timer > 0) {
	// 		const countdown = setInterval(() => {
	// 			setTimer((prevTimer) => prevTimer - 1); // Start the timer when requestOtpSuccess becomes true
	// 		}, 1000);
	// 		// Clear the timer when the component unmounts or when the timer reaches 0
	// 		return () => {
	// 			clearInterval(countdown);
	// 		};
	// 	};
	// }, [toggleOTP, timer]);

	/*---- validation for password eg: Admin@12 ----*/
	const _getOTP = () => {
		setOtp("");
		_login();
	}

	// handle resend OTP
	// const _handleResendOTP = (value, position) => {
	// 	const newOTP = [...otp];
	// 	newOTP[position] = value;
	// 	setOtp(newOTP);
	// 	setTimer(30);
	// 	setOtp("");
	// };

	// handle back to login  
	const _handleBack = () => {
		setToggleOTP(false);
	}

	// handle verify OTP 
	const _handleVerifyOTP = (status) => {
		_verifyOTP();
	}


	/* -------- login api -----*/
	const _login = () => {
		setLoader(true);
		const url = "/auth/login"
		const data = {
			username: name,
			password: password
		}

		service(true, url, data).then((response) => {
			if (response.status_code === 200) {
				setLoader(false);
				setToggleOTP(true);
				setEmail(response.data.email);
				setError(null);
			} else {
				setLoader(false);
				setError(response.data.message);
			}
		})
	}


	// API - verify OTP 
	const _verifyOTP = () => {

		setLoader(true);
		const url = "/auth/verify-otp"
		const data = {
			"email": email,
			"otp": otp,
			"type": "LOGIN"
		}

		service(true, url, data).then((response) => {
			if (response.status === true) {
				setLoader(false);
				setError(null);
				localStorage.setItem('access_token_ifsh_admin', response.access_token.token);
				window.location.href = '/';
			} else {
				setLoader(false);
				setError(response.data.message);
			}
		})
	}


	return (
		<>
			<div className="container-fluid e-auth-wrapper vh-100 d-flex flex-column">
				<OuterHeader type={1} />
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-md-6 mb-2 offset-lg-4 offset-md-3 px-0">
							{/* Login  */}
							<div className="e-form-wrap">
								{
									toggleOTP === false ?
										<>
											<h4>Admin Login</h4>
											<div className="pt-3">
												<label>Username*</label>
												<Input placeholder="Username"
													value={name}
													type="text"
													valueChange={(value) => { setName(value) }} />
											</div>

											<div className="pt-1">
												<label>Password*</label>
												<Input
													placeholder="john@123"
													value={password}
													type="password"
													valueChange={(value) => { setPassword(value) }}
													onSearch={() => {
														_getOTP()
													}} />
											</div>

											<div className="row mt-4 ">
												<div className="col-lg-4 col-md-5 col-sm-4">
													{name && password ?
														<PrimaryBtn name="Login"
															className={loader ? "e-btn-loader" : ""}
															handleClick={_getOTP} />
														:
														<PrimaryBtn name="Login"
															className="e-disabled" />
													}
												</div>
											</div>
										</>
										:
										// otp section
										<>
											<div className='row pb-3'>
												<div className="col-12">
													<h4 className='mb-0 d-flex align-items-center'>
														<img src={require("../../Assets/Images/right-black-icon.svg").default}
															draggable={false}
															role='button'
															className='e-back-btn '
															alt='back-icon'
															onClick={_handleBack} />
														Confirm OTP
													</h4>
												</div>
											</div>
											<p className='mb-0'>
												Enter 4 digit OTP sent to your registered email ID
											</p>
											<div className="pt-3">
												<label>Enter OTP*</label>
												<Input placeholder="1234"
													value={otp}
													autoFocus={true}
													type="password"
													valueChange={(value) => {
														if (value.length <= 4) {
															setOtp(value)
														}
													}} />
											</div>

											{/* <div className='d-flex pt-2'>
											<p className='mb-0'>
												Didn't get OTP?
											</p>
											<button className={`${timer === 0 ? ` ` : ` e-disable`} border-0 bg-transparent`}
												onClick={_handleResendOTP} >
												Re-send OTP
											</button>
											{timer !== 0 &&
												<p className='mb-0 '>
													in {timer} seconds
												</p>
											}
										</div> */}

											<div className="mt-4">
												{otp.length === 4 ?
													<PrimaryBtn name="Verify & proceed"
														className={loader ? "e-btn-loader" : ""}
														handleClick={_handleVerifyOTP} />
													:
													<PrimaryBtn name="Verify & proceed"
														className="e-disabled" />
												}
											</div>
										</>
								}

							</div>
						</div>
					</div>
				</div>
				<Footer type="mailto:hello@fivesto.com" />

			</div>

			<div>
				{
					error !== null ?
						<Toast data={error}
							type="error"
							id="1" />
						:
						null
				}
			</div>
		</>
	);
}

export default Login;