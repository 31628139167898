/*
 *   File : nri.js
 *   Author : https://evoqins.com
 *   Description : NRI user warning modal
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { memo, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// Components
import { PrimaryBtn } from "../Cta";
import { _closeModal } from "../../Helper/function";

function NRIWarningModal(props) {


    // Closing modal when "ESC" pressed
    useEffect(() => {
        _closeModal(props.onCloseModal);
    }, []);

    const navigate = useNavigate();

    const _handleNavigate = () => {
        const close_modal = document.querySelector("#nri-warning-modal-close");
        close_modal.click();
        navigate("/onboarding-users");
    };

    return (
        <div className="modal fade e-manage-modal"
            id="nri-warning-modal"
            tabIndex="-1"
            data-bs-backdrop="static"
            aria-labelledby="NRI warning modal label"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className={`modal-content e-border-radius-8 p-0`}>
                    <div className="modal-header pt-3 px-3 pb-2 border-0 border-0 position-relative">
                        <h6 className='col-11 col-sm-9 pe-4 mx-auto text-center e-font-16-res mb-0 e-text-chinese-black e-font-18 e-font-manrope e-font-weight-600 line-height-26px'>
                            Account opening is restricted for Non-KYC users
                        </h6>
                        <button type="button"
                            id='nri-warning-modal-close'
                            className='cursor-pointer position-absolute end-0 top-50 translate-middle-y btn-close me-3'
                            data-bs-dismiss="modal"
                            aria-label="close"
                            alt='close btn'
                            onClick={props.onCloseModal}></button>
                    </div>
                    <div className="modal-body p-3">
                        <p className='text-center mb-0 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-400 line-height-22px'>
                            Unfortunately, you can't open an investment account, since the regular has not started accepting NRI non-KYC users online.
                        </p>
                        <div className="margin-top-32px text-center">
                            <PrimaryBtn name="Go back"
                                className="py-2 h-40"
                                handleClick={_handleNavigate} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(NRIWarningModal);