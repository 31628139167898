/*
 *   File : aadhaar-fetch-status.js
 *   Author : https://evoqins.com
 *   Description : Digilocker aadhaar fetch status modal component
 *   Integrations : NA
 *   Version : 1.0.0
*/


import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import IcoMoon from "react-icomoon";
import iconSet from "../../Assets/Images/Icons/selection.json";

//custom component
import { PrimaryBtn, SecondaryBtn } from '../Cta';
import service from '../../Services/service';
import { _closeModal } from '../../Helper/function';

// Assets
import WhatsApp from "../../Assets/Images/General/whatsApp.png";

const AadhaarFetchStatusModal = (props) => {

    const [isCopied, setIsCopied] = useState(false);
    const [kycUrl, setKycUrl] = useState(props.aadhaarFetchUrl);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setKycUrl(props.aadhaarFetchUrl);
    }, [props.aadhaarFetchUrl]);

    // Closing modal when "ESC" pressed
    useEffect(() => {
        _closeModal(props.onCloseModal);
    }, []);

    const _handleCopyLink = () => {
        navigator.clipboard.writeText(kycUrl)
            .then(() => {
                setIsCopied(true);
            })
            .catch(() => {
                setIsCopied(false);
                toast.dismiss();
                toast.success("Failed to copy URL, try again");
            });

        // Allowing user to copy it again
        setTimeout(() => {
            setIsCopied(false);
        }, 5000);
    }

    const _handleShareLink = () => {
        window.open('https://web.whatsapp.com/send?phone=' + props.phone + `&text=${encodeURIComponent(kycUrl)}`, '_blank');
    };

    //handle update fetch status
    const _handleUpdateStatus = () => {
        setLoader(true);
        _fetchAadhaarStatus();
    };

    //API - Fetch aadhaar status
    const _fetchAadhaarStatus = () => {

        let is_post = true;
        let url = '/kyc/get-aadhaar';
        let data = {
            "customer_id": props.customerId
        }
        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                if (response.data.status === 'SUCCESSFUL') {
                    let close_btn = document.getElementById("aadhaar-fetch-status-modal-close");
                    close_btn.click();
                    props.onSuccess();
                } else {
                    toast.dismiss();
                    toast.info("Aadhaar fetch is in progress. Please cross-check with the user", { className: "e-toast", });
                }

            } else {
                toast.dismiss();
                toast.error((response.message || "Something went wrong"), { className: "e-toast", });
            };
            setLoader(false);
        });
    };

    // API - send KYC notification to user
    // const _sendKYCNotification = () => {
    //     let is_post = true;
    //     let url = '/kyc/notify';
    //     let data = {
    //         "customer_id": props.customerId
    //     };

    //     service(is_post, url, data).then((response) => {
    //         if (response.status_code === 200) {
    //             toast.dismiss();
    //             toast.success("Notification sent to user successfully");
    //         } else {
    //             toast.dismiss();
    //             toast.success(response.data.message);
    //         };

    //     });
    // };

    return (
        <div className="modal fade"
            data-bs-backdrop="static"
            id="aadhaar-fetch-status-modal"
            tabIndex="-1"
            aria-labelledby="AadhaarFetchStatusModalLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content e-border-radius-8">
                    <div className="modal-header justify-content-between p-0  pb-3 border-0">
                        <h6 className='mb-0 e-text-chinese-black e-font-18 e-font-manrope e-font-weight-600 line-height-32px'>
                            Share link and send email
                        </h6>
                        <button type="button"
                            id='aadhaar-fetch-status-modal-close'
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={props.onCloseModal}></button>
                    </div>
                    <div className="modal-body text-center p-0 pt-4">
                        <p className='e-text-dark-grey e-font-14 e-font-manrope e-font-weight-400 line-height-24px mb-4'>
                            Copy the below link to share it through other mediums and send an email notification to inform the user to complete the Aadhaar fetching.
                        </p>

                        <div className="d-flex flex-nowrap gap-3 e-bg-alice-blue  py-2 e-padding-lr-12px e-border-radius-8">
                            <p className='text-start bg-white text-wrap flex-fill e-padding-all-12px e-border-radius-8 mb-0  e-text-chinese-black e-font-12 e-font-manrope e-font-weight-400 line-height-18px'>
                                {kycUrl}
                            </p>
                            <span className='text-end cursor-pointer col-auto d-flex align-items-center gap-2 e-text-primary e-font-14 e-font-manrope e-font-weight-600 line-height-18px'
                                onClick={_handleCopyLink}>
                                <IcoMoon iconSet={iconSet}
                                    icon={"copy-icon"}
                                    size={"1.125rem"} />
                                {isCopied ? "Copied" : "Copy link"}
                            </span>
                        </div>
                        <div className='mt-3 text-end d-flex gap-3 justify-content-end'>
                            <span className="e-text-chinese-black e-font-14 e-font-manrope e-font-weight-600 line-height-18px e-border-radius-8 e-border-otp px-3 cursor-pointer col-auto d-flex align-items-center gap-2 "
                                onClick={_handleShareLink}>
                                <img src={WhatsApp}
                                    alt="whatsApp icon"
                                    draggable="false"
                                    width={20}
                                    height={20} />
                                Share to WhatsApp
                            </span>
                            <a href={`mailto:${props.email}`}>
                                <PrimaryBtn name="Send email"
                                    // handleClick={_sendKYCNotification}
                                    className="e-padding-tb-12px h-40" />
                            </a>
                        </div>

                        <div className='row mt-5 justify-content-end'>
                            <div className='col-5'>
                                <SecondaryBtn name="Close"
                                    className="w-100 h-40 py-0"
                                    cancel="modal"
                                    handleClick={props.onCloseModal} />
                            </div>
                            <div className='col-5'>
                                <PrimaryBtn name="Fetch status"
                                    loading={loader}
                                    disabled={loader}
                                    className="w-100 h-40"
                                    handleClick={_handleUpdateStatus} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AadhaarFetchStatusModal;