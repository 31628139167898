/*
 *   File : download-report.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : User investment download report component
 *   Integrations :
 *   Version : v1.0
 *   Created : 29-11-2024
*/

import { useState } from "react";
import { toast } from "react-toastify";

//************ Components  **************// 
import { DateRangePicker } from "../../Components/Input"
import { PrimaryBtn } from "../../Components/Cta";
import service from "../../Services/service";
import { EmptyNote } from "../../Components/Note";


function DownloadReport(props) {

    const [capitalGainReport, setCapitalGainReport] = useState({
        start: null,
        end: null,
    });
    const [capitalGainLoader, setCapitalGainLoader] = useState(false);

    const [transactionReport, setTransactionReport] = useState({
        start: null,
        end: null,
    });
    const [transactionLoader, setTransactionLoader] = useState(false);

    const [holdingsLoader, setHoldingsLoader] = useState(false);



    // handle change capital gain report data
    const _handleCapitalDateChange = (start, end) => {
        setCapitalGainReport({ start: start, end: end });
    };

    // handle change capital gain report data
    const _handleTransactionDateChange = (start, end) => {
        setTransactionReport({ start: start, end: end });
    };

    // handle download capital gain report
    const _handleDownloadCapitalGainReport = () => {
        // API
        setCapitalGainLoader(true);
        _downloadCapitalGainData();
    };

    // handle download capital gain report
    const _handleDownloadTransactionReport = () => {
        // API
        setTransactionLoader(true);
        _downloadTransactionData();
    };

    // handle download holdings report
    const _handleDownloadHoldingsReport = () => {
        // API
        setHoldingsLoader(true);
        _downloadHoldingsData();
    };


    //================================ //
    // ========== API CAllS ========== //

    // API - download users data
    const _downloadCapitalGainData = () => {

        const url = '/user/capitalgain-report';

        const data = {
            "from_date": capitalGainReport.start ? capitalGainReport.start.split('-').reverse().join('-') : null,
            "end_date": capitalGainReport.end ? capitalGainReport.end.split('-').reverse().join('-') : null,
            "customer_id": props.userId
        };

        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                let a = document.createElement("a");
                a.href = response.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                toast.dismiss();
                toast.success("Capital gain report downloaded successfully");
            } else {
                toast.dismiss();
                toast.error(response.data.message);
            }
            setCapitalGainLoader(false);
        })
    };

    // API - download transaction data
    const _downloadTransactionData = () => {

        const url = '/user/transaction-report';

        const data = {
            "from_date": transactionReport.start ? transactionReport.start.split('-').reverse().join('-') : null,
            "end_date": transactionReport.end ? transactionReport.end.split('-').reverse().join('-') : null,
            "customer_id": props.userId
        };

        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                let a = document.createElement("a");
                a.href = response.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                toast.dismiss();
                toast.success("Transaction report downloaded successfully");
            } else {
                toast.dismiss();
                toast.error(response.data.message);
            }
            setTransactionLoader(false);
        })
    };

    // API - download holdings data
    const _downloadHoldingsData = () => {

        const url = '/user/holdings-report';
        const data = {
            "customer_id": props.userId
        };

        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                let a = document.createElement("a");
                a.href = response.data.url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                toast.dismiss();
                toast.success("Holdings report downloaded successfully");
            } else {
                toast.dismiss();
                toast.error(response.data.message);
            }
            setHoldingsLoader(false);
        })
    };

    return (
        <div className="card e-dashboard-card border-0  min-vh-100 p-4">
            {
                props.kyc_status === "INVESTED" ?
                    <>

                        {/* Capital Gain Report */}
                        <div className="">
                            <h2 className="e-font-manrope e-font-weight-600 e-font-16 e-text-chinese-black">
                                Capital Gain Report
                            </h2>

                            <div className="col-8 col-md-5 col-lg-4">
                                <label className="mb-2 e-filter-label">Select start and end date</label>
                                <DateRangePicker
                                    start={capitalGainReport.start}
                                    end={capitalGainReport.end}
                                    selectPrevious={true}
                                    onDatesChange={_handleCapitalDateChange}
                                />
                            </div>
                            <PrimaryBtn
                                className={`${capitalGainLoader ? "e-padding-tb-4px " : "e-padding-tb-12px"} col-4 col-xl-3 mt-3 h-40`}
                                name="Download report"
                                loading={capitalGainLoader}
                                handleClick={_handleDownloadCapitalGainReport} />
                        </div>

                        {/* Transaction Report */}
                        <div className="mt-4">
                            <h2 className="e-font-manrope e-font-weight-600 e-font-16 e-text-chinese-black">
                                Transaction Report
                            </h2>

                            <div className="col-8 col-md-5 col-lg-4">
                                <label className="mb-2 e-filter-label">Select start and end date</label>
                                <DateRangePicker
                                    start={transactionReport.start}
                                    end={transactionReport.end}
                                    selectPrevious={true}
                                    onDatesChange={_handleTransactionDateChange}
                                />
                            </div>
                            <PrimaryBtn
                                className={`${transactionLoader ? "e-padding-tb-4px " : "e-padding-tb-12px"} col-4 col-xl-3 mt-3 h-40`}
                                name="Download report"
                                loading={transactionLoader}
                                handleClick={_handleDownloadTransactionReport} />
                        </div>

                        {/* Holdings Report */}
                        <div className="mt-4">
                            <h2 className="mb-0 e-font-manrope e-font-weight-600 e-font-16 e-text-chinese-black">
                                Holdings Report
                            </h2>

                            <PrimaryBtn
                                className={`${holdingsLoader ? "e-padding-tb-4px " : "e-padding-tb-12px"} col-4 col-xl-3 mt-3 h-40`}
                                name="Download report"
                                loading={holdingsLoader}
                                handleClick={_handleDownloadHoldingsReport} />
                        </div>

                    </>
                    :
                    <EmptyNote note="User not yet invested" />
            }


        </div>
    )
}

export default DownloadReport