/*
 *   File : rejection-details.js
 *   Author : https://evoqins.com
 *   Description : Kyc rejection details modal
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { useEffect } from "react";
import { _closeModal } from "../../Helper/function";

function KYCRejectionDetailsModal(props) {

    // Closing modal when "ESC" pressed
    useEffect(() => {
        _closeModal(props.onModalClose);
        _closeModal(props.onCloseModal);
    }, []);

    return (
        <div className="modal fade e-manage-modal"
            data-bs-backdrop="static"
            id={"kyc-rejection-details"}
            tabIndex="-1"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content e-border-radius-8 e-modal-box-shadow">
                    <div className="modal-header justify-content-between pb-3 pt-0  px-1 border-0">
                        <h6 className='mb-0 e-text-charleston-green e-font-18  e-font-manrope e-font-weight-600'>
                            Rejection reasons
                        </h6>
                        <button type="button"
                            id={`kyc-rejection-details`}
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={props.onModalClose}></button>
                    </div>
                    <div className="modal-body pb-0 px-1 pt-0">
                        {
                            props.data.map((item, i) => (
                                <p className="mb-2 align-items-center d-flex gap-2 e-font-14 e-text-charleston-green e-font-manrope e-font-weight-500">
                                    <span className="px-2 py-1 bg-danger-subtle rounded-1 e-font-14">{i + 1}</span>
                                    {item}
                                </p>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KYCRejectionDetailsModal;