/*
 *   File : profileKyc.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Profile Kyc Card
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 28-11-2023
 */

/*import packages*/
import React, { useEffect, useState } from 'react';

/*import style*/
import styles from '../../Styles/Components/profile-kyc.module.scss';

/*import assets*/
import Yes from '../../Assets/Images/Card/tick.svg';
import PNGFileIcon from '../../Assets/Images/General/png-file-icon.png';
import Edit from '../../Assets/Images/Cta/edit.svg';
import EditAddress from '../Modal/edit-address';
import { Modal } from 'bootstrap';

const ProfileKyc = (props) => {

	let is_edit_allowed = (props.data.kyc.status !== "INVESTED" && props.data.kyc.status !== "ACCOUNT_CREATED" && props.data.kyc.status !== "KYC_SUBMITTED" && props.data.kyc.status !== "KYC_ESIGN_REQUIRED")
	const [showAddressEditModal, setShowAddressEditModal] = useState(false);
	const [addressEditType, setAddressEditType] = useState("");

	useEffect(() => {

		if (showAddressEditModal) {
			let modal = new Modal(document.getElementById("edit-user-address-modal"));
			modal.show();
		};

	}, [showAddressEditModal]);


	// handle address edit
	const _handleAddressEdit = (type) => {
		setShowAddressEditModal(true);
		setAddressEditType(type)
	};


	return (
		<>
			<div className={`${styles.e_profileKyc_card} card`} id={props.id}>
				<div className="row">
					<div className="col-lg-12 col-12 text-start d-inline-flex ps-0 pb-2">
						<h5>
							{props.title}

						</h5>
						{
							props.verified ?
								<span className={`${styles.e_verified}`}>
									<img src={Yes} className="me-1" alt="" />Verified
								</span>
								:
								null
						}
						{
							props.edit && is_edit_allowed ?
								<div className={`${styles.e_edit_icon} position-absolute d-flex gap-2 align-items-center`}
									onClick={props.onEdit}>
									<img src={Edit}
										alt="edit" />
									<span className='e-font-14'>
										{props.edit && is_edit_allowed && "Manage KYC"}
									</span>
								</div>
								:
								null
						}
					</div>
					{
						props.type === 1 ?
							<div className={`${styles.e_content_wrap} `}>
								<div className="row row-gap-3">
									<div className="col-4 col-sm-4">
										<h5 className={`${styles.e_content_title}`}>
											Name
										</h5>
										<h5 className={`${styles.e_content}`}>
											{
												props.data.overview.name && props.data && props.data.overview ?
													props.data.overview.name
													:
													"--"
											}
										</h5>
									</div>
									<div className="col-4 col-sm-4">
										<h5 className={`${styles.e_content_title}`}>
											Customer id
										</h5>
										<h5 className={`${styles.e_content}`}>
											{
												props.data.overview.customer_id && props.data && props.data.overview ?
													props.data.overview.customer_id
													:
													"--"
											}
										</h5>
									</div>
									{
										props.data.overview.dob &&
										<div className="col-4 col-sm-4">
											<h5 className={`${styles.e_content_title}`}>
												DOB
											</h5>
											<h5 className={`${styles.e_content}`}>
												{
													props.data.overview.dob && props.data && props.data.overview ?
														props.data.overview.dob
														:
														"--"
												}
											</h5>
										</div>
									}

									{
										props.data.overview.gender &&
										<div className="col-4 col-sm-4">
											<h5 className={`${styles.e_content_title}`}>
												Gender
											</h5>
											<h5 className={`${styles.e_content}`}>
												{
													props.data.overview.gender && props.data && props.data.overview ?
														props.data.overview.gender
														:
														"--"
												}
											</h5>
										</div>
									}

									{
										props.data.overview.marital_status &&
										<div className="col-4 col-sm-4 ">
											<h5 className={`${styles.e_content_title}`}>
												Marital status
											</h5>
											<h5 className={`${styles.e_content}`}>
												{
													props.data.overview.marital_status && props.data && props.data.overview ?
														props.data.overview.marital_status
														:
														"--"
												}
											</h5>
										</div>
									}
									<div className="col-4 col-sm-4">
										<h5 className={`${styles.e_content_title}`}>
											Phone
										</h5>
										<h5 className={`${styles.e_content}`}>
											<a href={`tel:${props.data.overview.phone}`}
												style={{ color: "currentColor" }}>
												{
													props.data.overview.phone ?
														props.data.overview.phone
														:
														"--"
												}
											</a>
										</h5>
									</div>

									{
										props.data.overview.email &&
										<div className="col-4 col-sm-4">
											<h5 className={`${styles.e_content_title}`}>
												Email
											</h5>
											<h5 className={`${styles.e_content}`}>
												<a style={{ color: "currentColor" }}
													href={`mailto:${props.data.overview.email}`}>
													{
														props.data.overview.email && props.data && props.data.overview ?
															props.data.overview.email.toLowerCase()
															:
															"--"
													}
												</a>
											</h5>
										</div>
									}
									<div className="col-4 col-sm-4">
										<h5 className={`${styles.e_content_title}`}>
											Email verified
										</h5>
										<h5 className={`${styles.e_content}`}>
											{
												props.data.overview.is_email_verified ? "Yes" : "No"
											}
										</h5>
									</div>
									{
										props.data.overview.occupation &&
										<div className="col-4 col-sm-4">
											<h5 className={`${styles.e_content_title}`}>
												Occupation
											</h5>
											<h5 className={`${styles.e_content}`}>
												{
													props.data.overview.occupation && props.data && props.data.overview ?
														props.data.overview.occupation
														:
														"--"
												}
											</h5>
										</div>
									}

									{
										props.data.overview.wealth_source &&
										<div className="col-4 col-sm-4">
											<h5 className={`${styles.e_content_title}`}>
												Source of wealth
											</h5>
											<h5 className={`${styles.e_content}`}>
												{
													props.data.overview.wealth_source && props.data && props.data.overview ?
														props.data.overview.wealth_source
														:
														"--"
												}
											</h5>
										</div>
									}
									{
										props.data.overview.annual_income >= 1 &&
										<div className="col-4 col-sm-4">
											<h5 className={`${styles.e_content_title}`}>
												Annual income
											</h5>
											<h5 className={`${styles.e_content}`}>
												{
													props.data.overview.annual_income && props.data && props.data.overview ?
														`₹${props.data.overview.annual_income.toLocaleString('en-IN')}`
														:
														"-"
												}
											</h5>
										</div>
									}

									<div className="col-4 col-sm-4">
										<h5 className={`${styles.e_content_title}`}>
											Indian citizen
										</h5>
										<h5 className={`${styles.e_content}`}>
											{
												props.data.overview.is_indian_citizen ? "Yes" : "No"
											}
										</h5>
									</div>


									<div className="col-4 col-sm-4">
										<h5 className={`${styles.e_content_title}`}>
											Kyc complaint
										</h5>
										<h5 className={`${styles.e_content}`}>
											{
												props.data.overview.kyc_complaint ? "Yes" : "No"
											}
										</h5>
									</div>
									<div className="col-4 col-sm-4">
										<h5 className={`${styles.e_content_title}`}>
											User platform
										</h5>
										<h5 className={`${styles.e_content}`}>
											{
												props.data.overview.platform ? props.data.overview.platform : "--"
											}
										</h5>
									</div>
									<div className="col-4 col-sm-4">
										<h5 className={`${styles.e_content_title}`}>
											Created By
										</h5>
										<h5 className={`${styles.e_content}`}>
											{
												props.data.created_by === null ? "User" : "Admin"
											}
										</h5>
									</div>
								</div>
							</div>
							: props.type === 2 ?
								<div className={`${styles.e_content_wrap} `}>
									<div className="row flex-wrap row-gap-3">
										{
											props.data.kyc.pan &&
											<div className="col-4 col-sm-4">
												<h5 className={`${styles.e_content_title}`}>
													PAN
												</h5>
												<h5 className={`${styles.e_content}`}>
													{
														props.data.kyc.pan ? props.data.kyc.pan.toUpperCase() : "-"
													}
												</h5>
											</div>
										}
										{
											props.data.kyc.aadhaar_card_number &&
											<div className="col-4 col-sm-4">
												<h5 className={`${styles.e_content_title}`}>
													Aadhaar (last 4 digits)
												</h5>
												<h5 className={`${styles.e_content}`}>
													{
														props.data.kyc.aadhaar_card_number ? props.data.kyc.aadhaar_card_number : "--"
													}
												</h5>
											</div>
										}

										<div className="col-4 col-sm-4">
											<h5 className={`${styles.e_content_title} mb-1`}>
												Status
											</h5>
											{
												props.data && props.data.kyc && props.data.kyc.status ?
													<p className={`${styles.e_content} mb-0`}>
														{props.data.kyc.status}
													</p>
													:
													"--"
											}

										</div>
										{
											props.data.kyc.country &&
											<>
												<div className="col-4 col-sm-4">
													<h5 className={`${styles.e_content_title}`}>
														Country
													</h5>
													<h5 className={`${styles.e_content}`}>
														{
															props.data.kyc.country ? props.data.kyc.country : "--"
														}
													</h5>
												</div>
											</>
										}
										<div className="col-4 col-sm-4">
											<h5 className={`${styles.e_content_title}`}>
												Indian tax payer
											</h5>
											<h5 className={`${styles.e_content}`}>
												{
													props.data.overview.is_indian_tax_payer ? "Yes" : "No"
												}
											</h5>
										</div>
										{
											props.data.kyc.mother_name &&
											<>
												<div className="col-4 col-sm-4">
													<h5 className={`${styles.e_content_title}`}>
														Father name
													</h5>
													<h5 className={`${styles.e_content}`}>
														{
															props.data.kyc.father_name
														}
													</h5>
												</div>
												<div className="col-4 col-sm-4">
													<h5 className={`${styles.e_content_title}`}>
														Mother name
													</h5>
													<h5 className={`${styles.e_content}`}>
														{
															props.data.kyc.mother_name
														}
													</h5>
												</div>
											</>
										}
										{
											props.data.overview.is_indian_citizen === false &&
											<>
												<div className="col-4 col-sm-4">
													<h5 className={`${styles.e_content_title}`}>
														Tax country
													</h5>
													<h5 className={`${styles.e_content}`}>
														{
															props.data.kyc.tax_country ? props.data.kyc.tax_country : "--"
														}
													</h5>
												</div>
												<div className="col-4 col-sm-4">
													<h5 className={`${styles.e_content_title}`}>
														Tax reference number
													</h5>
													<h5 className={`${styles.e_content}`}>
														{
															props.data.kyc.tax_reference_number ? props.data.kyc.tax_reference_number : "--"
														}
													</h5>
												</div>
											</>
										}


										{/* Per-Address */}
										{
											props.data.kyc.address.permanent_address["address_line1"] !== undefined &&
											<div div className="col-4 col-sm-4 ">
												<h5 className={`${styles.e_content_title} mb-1 d-flex gap-2 align-items-center`}>
													Permanent address
													{
														is_edit_allowed &&
														<img src={Edit}
															alt="edit"
															className={`${styles.e_edit_icon}`}
															onClick={() => _handleAddressEdit(1)}
														/>
													}
												</h5>
												{
													props.data && props.data.kyc && props.data.kyc.address && props.data.kyc.address.permanent_address && Object.values(props.data.kyc.address.permanent_address).length > 0 ?
														<p className={`${styles.e_content} mb-1`}>
															{props.data.kyc.address.permanent_address.address_line1}

															<p className='mb-0'>
																{props.data.kyc.address.permanent_address.address_line2}
															</p>
															<p className='mb-1'>
																{`${props.data.kyc.address.permanent_address.city}, 
																${props.data.kyc.address.permanent_address.state} - 
																${props.data.kyc.address.permanent_address.pincode}.`}
															</p>

														</p>
														:
														"--"
												}

											</div>
										}

										{/* Corr-Address */}
										{
											props.data.overview.is_indian_tax_payer === false &&
											props.data.kyc.address.correspondence_address["address_line1"] !== undefined &&
											<div className="col-4 col-sm-4">
												<h5 className={`${styles.e_content_title} mb-1 d-flex gap-2 align-items-center`}>
													Correspondence address
													{
														is_edit_allowed &&
														<img src={Edit}
															alt="edit"
															className={`${styles.e_edit_icon}`}
															onClick={() => _handleAddressEdit(2)}
														/>
													}
												</h5>
												{
													props.data && props.data.kyc && props.data.kyc.address.correspondence_address && props.data.kyc.address && Object.values(props.data.kyc.address.correspondence_address).length > 0 ?
														<p className={`${styles.e_content} mb-1`}>
															{props.data.kyc.address.correspondence_address.address_line1}

															<p className='mb-0'>{props.data.kyc.address.correspondence_address.address_line2}</p>
															<p className='mb-1'>
																{`${props.data.kyc.address.correspondence_address.city}, 
															${props.data.kyc.address.correspondence_address.state} - 
															${props.data.kyc.address.correspondence_address.pincode}.`}
															</p>

														</p>
														:
														props.data && props.data.kyc && props.data.kyc.address && props.data.kyc.address.permanent_address && Object.values(props.data.kyc.address.permanent_address).length > 0 ?
															<p className={`${styles.e_content} mb-1`}>
																{props.data.kyc.address.permanent_address.address_line1}

																<p className='mb-0'>{props.data.kyc.address.permanent_address.address_line2}</p>
																<p className='mb-1'>
																	{`${props.data.kyc.address.permanent_address.city}, ${props.data.kyc.address.permanent_address.state},
															${props.data.kyc.address.permanent_address.pincode}`}

																</p>

															</p>
															:
															"--"
												}

											</div>
										}

										{/* Address proof section */}
										{/* Address proof selected */}
										{
											props.data.kyc.address_proof.proof &&
											<>
												<div className="w-100"></div>
												<div className="col-4 col-sm-4">
													<h5 className={`${styles.e_content_title}`}>
														Address proof
													</h5>
													<h5 className={`${styles.e_content}`}>
														{
															props.data.kyc.address_proof.proof ? props.data.kyc.address_proof.proof : "--"
														}
													</h5>
												</div>
											</>
										}
										{
											props.data.kyc.address_proof.issue_date &&
											<div className="col-4 col-sm-4">
												<h5 className={`${styles.e_content_title}`}>
													Issue date
												</h5>
												<h5 className={`${styles.e_content}`}>
													{
														props.data.kyc.address_proof.issue_date ? props.data.kyc.address_proof.issue_date : "--"
													}
												</h5>
											</div>
										}
										{
											props.data.kyc.address_proof.expiry_date &&
											<div className="col-4 col-sm-4">
												<h5 className={`${styles.e_content_title}`}>
													Expiry date
												</h5>
												<h5 className={`${styles.e_content}`}>
													{
														props.data.kyc.address_proof.expiry_date ? props.data.kyc.address_proof.expiry_date : "--"
													}
												</h5>
											</div>
										}
										{
											props.data.kyc.address_proof.proof_number &&
											<div className="col-4 col-sm-4">
												<h5 className={`${styles.e_content_title}`}>
													Proof_number
												</h5>
												<h5 className={`${styles.e_content}`}>
													{
														props.data.kyc.address_proof.proof_number ? props.data.kyc.address_proof.proof_number : "--"
													}
												</h5>
											</div>
										}
										{/* Showing uploaded documents if user is non kyc */}
										{/* props.data.kyc.identity_proof_url => it will be pan image */}
										{
											props.data.kyc.identity_proof_url &&
											<div className="row">
												<div className="col-12">
													<h5 className={`${styles.e_content_title} mb-3 mt-2`}>
														Uploaded documents
													</h5>
													<div className="row flex-wrap gap-3">
														{/* Pan file */}
														{
															props.data.kyc.identity_proof_url &&
															<div className=" col-auto">
																<h5 className={`${styles.e_content_title} mb-3`}>
																	PAN
																</h5>
																<div className='d-flex align-items-center gap-2'>
																	<a href={props.data.kyc.identity_proof_url}
																		target='_blank'
																		className='border px-3 py-2  e-border-radius-8 e-border-chinese-white'>
																		<img src={PNGFileIcon}
																			alt="file icon"
																			className='me-2'
																			width={24} height={24} />
																		<span className="text-nowrap e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
																			{props.data.kyc.identity_proof_url.split("?")[0].split("/").at(-1)}
																		</span>
																	</a>
																</div>
															</div>
														}
														{/* Photo file */}
														{
															props.data.kyc.photo_proof_url &&
															<div className=" col-auto">
																<h5 className={`${styles.e_content_title} mb-3`}>
																	Photo
																</h5>
																<div className='d-flex align-items-center gap-2'>
																	<a href={props.data.kyc.photo_proof_url}
																		target='_blank'
																		className='border px-3 py-2  e-border-radius-8 e-border-chinese-white'>
																		<img src={PNGFileIcon}
																			alt="file icon"
																			className='me-2'
																			width={24} height={24} />
																		<span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
																			{props.data.kyc.photo_proof_url.split("?")[0].split("/").at(-1)}
																		</span>
																	</a>
																</div>
															</div>
														}
														{/* Sign file */}
														{
															props.data.kyc.signature_pro3of_url &&
															<div className=" col-auto">
																<h5 className={`${styles.e_content_title} mb-3`}>
																	Signature
																</h5>
																<div className='d-flex align-items-center gap-2'>
																	<a href={props.data.kyc.signature_proof_url}
																		target='_blank'
																		className='border px-3 py-2 e-border-radius-8 e-border-chinese-white' >
																		<img src={PNGFileIcon}
																			alt="file icon"
																			className='me-2'
																			width={24} height={24} />
																		<span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
																			{props.data.kyc.signature_proof_url.split("?")[0].split("/").at(-1)}
																		</span>
																	</a>
																</div>
															</div>
														}
														{/* Bank file */}
														{
															props.data.kyc.bank_proof_url &&
															<div className=" col-auto">
																<h5 className={`${styles.e_content_title} mb-3`}>
																	Bank proof
																</h5>
																<div className='d-flex align-items-center gap-2'>
																	<a href={props.data.kyc.bank_proof_url}
																		target='_blank'
																		className='border px-3 py-2  e-border-radius-8 e-border-chinese-white'>
																		<img src={PNGFileIcon}
																			alt="file icon"
																			className='me-2'
																			width={24} height={24} />
																		<span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
																			{props.data.kyc.bank_proof_url.split("?")[0].split("/").at(-1)}
																		</span>
																	</a>
																</div>
															</div>
														}
														{/* Address proof file */}
														{
															props.data.kyc.address_proof.address_proof_url &&

															<div className=" col-auto">
																<h5 className={`${styles.e_content_title} mb-3`}>
																	Address front
																</h5>
																<div className='d-flex align-items-center gap-2'>
																	<a href={props.data.kyc.address_proof.address_proof_url}
																		target='_blank'
																		className='border px-3 py-2  e-border-radius-8 e-border-chinese-white'>
																		<img src={PNGFileIcon}
																			alt="file icon"
																			className='me-2'
																			width={24} height={24} />
																		<span className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
																			{props.data.kyc.address_proof.address_proof_url.split("?")[0].split("/").at(-1)}
																		</span>
																	</a>
																</div>
															</div>
														}
														{/* Address proof file */}
														{
															props.data.kyc.address_proof.proof_back &&
															<div className=" col-auto">
																<h5 className={`${styles.e_content_title} mb-3`}>
																	Address back
																</h5>
																<div className='d-flex align-items-center gap-2'>
																	<a href={props.data.kyc.address_proof.proof_back}
																		target='_blank'
																		className='border px-3 py-2  e-border-radius-8 e-border-chinese-white'>
																		<img src={PNGFileIcon}
																			alt="file icon"
																			className='me-2'
																			width={24} height={24} />
																		<span className="text-nowrap e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14">
																			{props.data.kyc.address_proof.proof_back.split("?")[0].split("/").at(-1)}
																		</span>
																	</a>
																</div>
															</div>
														}
													</div>
												</div>
											</div>
										}
									</div>
								</div>
								: props.type === 3 ?
									<div className={`${styles.e_content_wrap} `}>
										<div className="row row-gap-3">

											{
												props.data.bank.name &&
												<>
													<div className="col-6">
														<h5 className={`${styles.e_content_title}`}>
															Bank
														</h5>
														<h5 className={`${styles.e_content}`}>
															{
																props.data && props.data.bank && Object.values(props.data.bank).length > 0 && props.data.bank.name ?
																	props.data.bank.name
																	: "--"
															}
														</h5>
													</div>
													<div className="col-6">
														<h5 className={`${styles.e_content_title}`}>
															Account No.
														</h5>
														<h5 className={`${styles.e_content}`}>
															{
																props.data && props.data.bank && Object.values(props.data.bank).length > 0 && props.data.bank.account_number ?
																	props.data.bank.account_number
																	: "--"
															}
														</h5>
													</div>
													<div className="col-6">
														<h5 className={`${styles.e_content_title}`}>
															IFSC
														</h5>
														<h5 className={`${styles.e_content}`}>
															{
																props.data && props.data.bank && Object.values(props.data.bank).length > 0 && props.data.bank.ifsc ?
																	props.data.bank.ifsc
																	: "--"
															}
														</h5>
													</div>
													<div className="col-6">
														<h5 className={`${styles.e_content_title}`}>
															Account holder
														</h5>
														<h5 className={`${styles.e_content}`}>
															{
																props.data && props.data.bank && Object.values(props.data.bank).length > 0 && props.data.bank.account_holder ?
																	props.data.bank.account_holder
																	: "--"
															}
														</h5>
													</div>
													<div className="col-6">
														<h5 className={`${styles.e_content_title}`}>
															Account type
														</h5>
														<h5 className={`${styles.e_content}`}>
															{
																props.data && props.data.bank && Object.values(props.data.bank).length > 0 && props.data.bank.account_type ?
																	props.data.bank.account_type
																	: "--"
															}
														</h5>
													</div>
													<div className="col-6">
														<h5 className={`${styles.e_content_title}`}>
															Branch
														</h5>
														<h5 className={`${styles.e_content}`}>
															{
																props.data && props.data.bank && Object.values(props.data.bank).length > 0 && props.data.bank.branch ?
																	props.data.bank.branch
																	: "--"
															}
														</h5>
													</div>
												</>
											}
											<div className='col-6'>
												<h5 className={`${styles.e_content_title}`}>
													Auto-debit
												</h5>
												<h5 className={`${styles.e_content}`}>
													{
														props.data && props.data.bank && Object.values(props.data.bank).length > 0 ?
															props.data.bank.autopay_id !== null ?
																"On"
																: "Off"
															:
															"--"
													}
												</h5>
											</div>
											{
												props.data.bank.autopay_id &&
												<div className='col-6'>
													<h5 className={`${styles.e_content_title}`}>
														Mandate ID
													</h5>
													<h5 className={`${styles.e_content}`}>
														{
															props.data && props.data.bank && Object.values(props.data.bank).length > 0 && props.data.bank.autopay_id ?
																props.data.bank.autopay_id
																: "--"
														}
													</h5>
												</div>
											}
										</div>
									</div>
									: props.type === 4 ?
										<div className={`${styles.e_content_wrap} `}>
											<div className="row row-gap-3">
												<div className="col-6">
													<h5 className={`${styles.e_content_title}`}>
														Bank
													</h5>
													<h5 className={`${styles.e_content}`}>
														{
															props.data && props.data.bank && Object.values(props.data.bank).length > 0 && props.data.bank.name ?
																props.data.bank.name
																: "--"
														}
													</h5>
												</div>
												<div className="col-6">
													<h5 className={`${styles.e_content_title}`}>
														ID
													</h5>
													<h5 className={`${styles.e_content}`}>
														{
															props.data && props.data.bank && Object.values(props.data.bank).length > 0 && props.data.bank.autopay_id ?
																props.data.bank.autopay_id
																: "--"
														}
													</h5>
												</div>

												<div className="col-6 pb-2">
													<h5 className={`${styles.e_content_title}`}>
														Auto-debit
													</h5>
													<h5 className={`${styles.e_content}`}>
														{
															props.data && props.data.bank && Object.values(props.data.bank).length > 0 ?
																props.data.bank.autopay_id !== null ?
																	"On"
																	: "Off"
																:
																"--"
														}
													</h5>
												</div>
											</div>
										</div>
										: props.type === 5 ?
											<div className={`${styles.e_content_wrap} `}>
												<div className="row">
													{
														props.data.nominee.nominee.length === 0 ?
															<div className="col-6">
																<h5 className={`${styles.e_content_title}`}>
																	Nominee Opt-In
																</h5>
																<h5 className={`${styles.e_content} mb-0`}>
																	No
																</h5>
															</div>
															:

															<div className="row">
																<div className="col-12 d-flex flex-column gap-3">

																	{
																		props.data.nominee.nominee.map((nominee, key) => (
																			<div className="row row-gap-3" key={key}>
																				{
																					props.data.nominee.nominee.length > 1 &&
																					<h6 className={`mb-0 e-font-manrope e-font-weight-700  e-font-14 e-text-chinese-black`}>
																						Nominee {key + 1}
																					</h6>
																				}
																				<div className="col-6">
																					<h5 className={`${styles.e_content_title}`}>
																						Name
																					</h5>
																					<h5 className={`${styles.e_content}`}>
																						{
																							nominee.name ? nominee.name : "--"
																						}
																					</h5>
																				</div>
																				<div className="col-6">
																					<h5 className={`${styles.e_content_title}`}>
																						Relation
																					</h5>
																					<h5 className={`${styles.e_content}`}>
																						{
																							nominee.relation ? nominee.relation : "--"
																						}
																					</h5>
																				</div>
																				<div className="col-6">
																					<h5 className={`${styles.e_content_title}`}>
																						Dob
																					</h5>
																					<h5 className={`${styles.e_content}`}>
																						{
																							nominee.dob ? nominee.dob : "--"
																						}
																					</h5>
																				</div>
																				<div className="col-6">
																					<h5 className={`${styles.e_content_title}`}>
																						Allocation
																					</h5>
																					<h5 className={`${styles.e_content}`}>
																						{
																							nominee.allocation ? nominee.allocation : "--"
																						}
																					</h5>
																				</div>
																				{
																					nominee.guardian_name &&
																					<>

																						<div className="col-6">
																							<h5 className={`${styles.e_content_title}`}>
																								Guardian name
																							</h5>
																							<h5 className={`${styles.e_content}`}>
																								{
																									nominee.guardian_name ? nominee.guardian_name : "--"
																								}
																							</h5>
																						</div>
																						<div className="col-6">
																							<h5 className={`${styles.e_content_title}`}>
																								Guardian pan
																							</h5>
																							<h5 className={`${styles.e_content}`}>
																								{
																									nominee.guardian_pan ? nominee.guardian_pan : "--"
																								}
																							</h5>
																						</div>
																						<div className="col-6">
																							<h5 className={`${styles.e_content_title}`}>
																								Guardian relationship
																							</h5>
																							<h5 className={`${styles.e_content}`}>
																								{
																									nominee.guardian_relationship ? nominee.guardian_relationship : "--"
																								}
																							</h5>
																						</div>
																					</>
																				}
																			</div>
																		))
																	}
																</div>
															</div>
													}

												</div>
											</div>
											:
											null
					}
				</div>
			</div>

			{
				showAddressEditModal &&
				<EditAddress data={props.data}
					handleEditSuccess={props.onAddressEdit}
					editPermanentAddress={addressEditType === 1 ? true : false}
					onCloseModal={() => setShowAddressEditModal(false)} />
			}

		</>
	);
}

export default ProfileKyc;

