/*
 *   File : overlay.js
 *   Author : https://evoqins.com
 *   Description :  Modal open overlay
 *   Integrations : NA
 *   Version : 1.0.0
*/


const OverLay = ({ isOpen, onClose, children }) => {
    return (
        isOpen &&
        <div className='overlay-background' onClick={onClose}>
            <div className='overlay-container' >
                {children}
            </div>
        </div>
    );
};

export default OverLay;