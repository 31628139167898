/*
 *   File : userDetail.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : User Detail Card
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 28-11-2023
 */

/*import packages*/
import React, { memo, useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

// Component
import { TextBtn } from '../Cta';

/*Style Import*/
import styles from '../../Styles/Components/user-detail.module.scss';
import Colors from "../../Styles/config.scss";

const UserDetail = (props) => {

	const has_access = props.type == 2 ? "" : (props.data.kyc.status !== "INVESTED" && props.data.kyc.status !== "ACCOUNT_CREATED" && props.data.kyc.status !== "KYC_SUBMITTED" && props.data.kyc.status !== "KYC_ESIGN_REQUIRED");
	const [shortName, setShortName] = useState("");


	useEffect(() => {
		if (props.type == 2) {
			setShortName(props.data.name.split(" ").map(name => name.at(0)).slice(0, 2).join("").toUpperCase());
		} else {
			setShortName(props.data.overview.name.split(" ").map(name => name.at(0)).slice(0, 2).join("").toUpperCase());
		}

	}, [props.data]);

	return (
		<div className={`${styles.e_user_detail_card} card position-relative`}>
			{
				props.type == 2 ?
					<div className="row flex-wrap pe-3">
						<div className="col-4">
							<div className='row'>
								<div className='col'>
									<div className="d-flex align-items-center">
										<span className={`${styles.e_profile_img}`}>
											{shortName}
										</span>
										<div className={`${styles.e_span} p-0 ps-3 pe-2 d-flex gap-2 align-items-start flex-column`}>
											<span className="e-text-white e-font-manrope e-font-weight-500  e-font-10 e-bg-cornflower-blue px-2 py-1 pe-2 e-border-radius-top-left-16">
												Family head
											</span>
											<h4 className='mb-0 e-font-24'>{props.data.name}</h4>
											<span className='e-font-14 e-font-weight-500'>
												<span className='e-text-dark-silver'>Family ID : </span>
												<span className='e-text-chinese-black'>{props.data.ifsh_family_id}</span>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className={`${styles.e_detail_sec} col-8 p-3 py-4 h-auto m-0 align-self-center`} >
							<div className="row justify-content-between">
								<div className="col d-flex flex-column justify-content-between">
									<h5 className={`${styles.e_detail_title} mb-3`}>
										Family invested
									</h5>
									<p className={`${styles.e_detail_sub_title}`} >
										{props.data.invested_amount_text}
									</p>
								</div>
								<div className="col d-flex flex-column justify-content-between">
									<h5 className={`${styles.e_detail_title} mb-3`}>
										Current value
									</h5>
									<p className={`${styles.e_detail_sub_title}`} >
										{props.data.current_amount_text}
									</p>
								</div>
								<div className="col position-relative d-flex flex-column justify-content-between">
									<h5 className={`${styles.e_detail_title} mb-3`}>
										Total P&L
									</h5>
									<p className={`${styles.e_detail_sub_title}`} >
										{
											"₹" + Number((props.data.current_amount - props.data.invested_amount).toFixed(2)).toLocaleString("en-IN")
										}
									</p>
								</div>
								<div className="col position-relative d-flex flex-column justify-content-between">
									<h5 className={`${styles.e_detail_title} mb-3`}>
										Individual AUM
									</h5>
									<p className={`${styles.e_detail_sub_title}`} >
										{props.data.individual_aum_text}
									</p>
								</div>
								<div className="col position-relative d-flex flex-column justify-content-between">
									<h5 className={`${styles.e_detail_title} mb-3`}>
										Family AUM
									</h5>
									<p className={`${styles.e_detail_sub_title}`} >
										{props.data.family_aum_text}
									</p>
								</div>
							</div>
						</div>
					</div>
					:
					<div className="row flex-wrap">
						<div className="col-lg-4 col-5">
							<div className='row'>
								<div className='col'>
									<div className="d-flex align-items-center">
										<span className={`${styles.e_profile_img}`}>
											{shortName}
										</span>
										<span className={`${styles.e_span} p-0 ps-3 pe-2`}>
											{
												props.data.overview && props.data.overview.name && props.data ?
													<h4 className=''>{props.data.overview.name}</h4>
													:
													null
											}

										</span>
									</div>
								</div>
							</div>
						</div>
						<div className={`${styles.e_detail_sec} col-lg-8 col-7 p-3 h-auto m-0 align-self-center`} >
							{
								props.data && props.data.investment_summary ?
									<div className="row justify-content-between align-items-center row-gap-3">
										<div className="col-auto d-flex flex-column justify-content-between">
											<h5 className={`${styles.e_detail_title} mb-3`}>Amount Invested</h5>

											<p className={`${styles.e_detail_sub_title}`} >{props.data.investment_summary.invested_amount_text}</p>

										</div>
										<div className="col-auto d-flex flex-column justify-content-between">
											<h5 className={`${styles.e_detail_title} mb-3`}>Total Returns</h5>

											<p className={`${styles.e_detail_sub_title}`}>{props.data.investment_summary.returns_amount_text}</p>

										</div>
										<div className="col-auto position-relative d-flex flex-column justify-content-between">
											<h5 className={`${styles.e_detail_title} mb-3`}>Total returns(%)
												{/* <img
											className="ps-2"
											src={Icon} ref={wrapperRef} alt=""
											onMouseEnter={() => setTooltip(true)}
											onMouseLeave={() => setTooltip(false)}
											style={{ cursor: "pointer" }}
										/> */}
											</h5>
											<p className={`${styles.e_detail_sub_title}`}>{props.data.investment_summary.returns_percentage_text}</p>
										</div>
										<div className="col-auto position-relative d-flex flex-column justify-content-between">
											<h5 className={`${styles.e_detail_title} mb-3`}>Risk score</h5>
											<p className={`${styles.e_detail_sub_title}`}>{props.data.overview.risk_score || "--"}</p>
										</div>
										<div className="col-auto">
											<Popup
												className={"e-popup"}
												on="hover"
												offsetY={10}
												arrow={false}
												trigger={<span className="e-more rotate-90deg m-0"></span>}
												position="bottom right">
												<div className='p-2'>
													{
														has_access &&
														<TextBtn name="Add a member"
															className="e-font-weight-500 d-flex align-items-center gap-2 mb-2"
															icon={"add"}
															icon_color={Colors.primary_color}
															icon_size={18}
															handleClick={props.handleAddFamilyMember} />
													}
													<TextBtn name="Delete user"
														className="e-font-weight-500 e-text-red d-flex align-items-center gap-2"
														icon={"delete-icon"}
														icon_color={Colors.red}
														icon_size={18}
														handleClick={props.handleDeleteUser} />
												</div>
											</Popup>
										</div>

									</div>
									:
									null
							}

						</div>



					</div>
			}
		</div>
	)
}

export default memo(UserDetail);