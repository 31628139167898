/*
 *   File : mf-invest.js
 *   Author : https://evoqins.com
 *   Description : Mutual Fund Investment modal
 *   Integrations : React toastify
 *   Version : 1.0.0
*/

import React, { useRef, useEffect, useState, memo } from 'react';
import { toast } from "react-toastify";
import IcoMoon from "react-icomoon";
import iconSet from "../../Assets/Images/Icons/selection.json";

// Component
import { CustomAmountInput, CustomTextInput, OTPInput, RadioGroup } from "../Form-Elements";
import { SIPDateModal } from ".";
import { PrimaryBtn } from "../Cta";
import { _basketSummary, _createMandate, _formatSipDate, _goalSummary, _mfSummary, _sendOTP } from "../../Helper";
import service from "../../Services/service";
import { FundsTab } from '../DataFormating';

// Assets
import Active from "../../Assets/Images/Kyc/circle-active.svg";
import Inactive from "../../Assets/Images/Kyc/circle-inactive.svg";
import UPI from "../../Assets/Images/General/payment_upi.svg";
import NetBanking from "../../Assets/Images/General/payment_net_banking.svg";
import { _closeModal } from '../../Helper/function';

// Colors
import Colors from "../../Styles/config.scss";

// SIP mandate information
const SIP_MANDATE_INFO = [
    "For first SIP order, a mandate will be created automate monthly recurring transactions",
    "The mandate is for Rs. 5 lakhs, which will be used for future rebalancing and other SIP.",
    "We won't deduct any extra money other than the SIP order amount using the mandate.",
    "Once the mandate has been created, you can proceed with the first installment."
];

const CONFIRM_PAYMENT = [
    {
        id: 1,
        display_name: "Yes",
    },

    {
        id: 2,
        display_name: "No",
    },
];


const AMOUNT_DATA = [
    {
        id: 1,
        amount: 2000,
    },

    {
        id: 2,
        amount: 5000,
    },

    {
        id: 3,
        amount: 10000,
    },
    {
        id: 4,
        amount: 25000,
    },
    {
        id: 5,
        amount: 100000,
    },
];

function MFInvestModal(props) {

    const otpInputRef = useRef(null);

    const [userData, setUserData] = useState(props.userData);
    const [activeTab, setActiveTab] = useState(1);
    const [fundSummary, setFundSummary] = useState(props.fundSummary);

    const [sip, setSip] = useState(0);
    const [lumpsum, setLumpsum] = useState(0);
    const [minInvestment, setMinInvestment] = useState({});
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [selectedPaymentType, setSelectedPaymentType] = useState("");
    const [isPayingNow, setIsPayingNow] = useState(1);
    const [mandateStatus, setMandateStatus] = useState(true);
    const [investmentTabs, setInvestmentTabs] = useState([]);


    const [toggleInvest, setToggleInvest] = useState(false);
    const [toggleDateModal, setToggleDateModal] = useState(false);

    const [otpValue, setOtpValue] = useState("");
    const [timer, setTimer] = useState(30);

    const [focusedInput, setFocusedInput] = useState(0);
    const [errorMsgSIP, setErrorMsgSIP] = useState("");
    const [errorMsgLumpsum, setErrorMsgLumpsum] = useState("");
    const [errorMsgSipDate, setErrorMsgSipDate] = useState("");

    // for sip date
    const [sipActiveDates, setSipActiveDates] = useState([]);
    const [selectedSIPDate, setSelectedSIPDate] = useState("");
    const [selectedSIPDateString, setSelectedSIPDateString] = useState("");

    const [loading, setLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    // Closing modal when "ESC" pressed
    useEffect(() => {
        _closeModal(props.onCloseModal);
    }, []);


    useEffect(() => {

        setUserData(props.userData);
        setActiveTab(props.investType);

        setSip(props.fundSummary.min_investment.sip);
        setLumpsum(props.fundSummary.min_investment.lumpsum);
        setFundSummary(props.fundSummary);
        setMandateStatus(props.fundSummary.mandate_status);
        setMinInvestment({
            min_sip: props.fundSummary.min_investment.sip,
            min_lumpsum: props.fundSummary.min_investment.lumpsum,
        });

        if (props.availableInvestmentTypes.sip) {
            let sip_dates = props.fundSummary.sip_dates.split(",").map(Number);
            setSipActiveDates(sip_dates);
        }

        if (props.fundSummary.payment_types.length !== 0) {
            let payment_types = props.fundSummary.payment_types;
            payment_types = payment_types.map(data => {
                return data.id == 2 ? { ...data, image: UPI } : data.id == 1 ? { ...data, image: NetBanking } : data;
            });
            setPaymentMethod(payment_types);
            setSelectedPaymentType(payment_types[0].label);
        }

        if (props.availableInvestmentTypes) {

            let investment_tabs = [];

            if (props.availableInvestmentTypes.sip === true) {
                investment_tabs = [...investment_tabs, { id: 1, label: "SIP" }];
            };

            if (props.availableInvestmentTypes.lumpsum === true) {
                investment_tabs = [...investment_tabs, { id: 2, label: "Lumpsum" }];
            };

            setActiveTab(props.availableInvestmentTypes.sip ? 1 : 2);

            setInvestmentTabs(investment_tabs);
        };


    }, [props]);

    //for timer
    useEffect(() => {
        if (toggleInvest === true && timer > 0) {
            // Start the timer when requestOtpSuccess becomes true
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            // Clear the timer when the component unmounts or when the timer reaches 0
            return () => {
                clearInterval(countdown);
            };
        }
    }, [toggleInvest, timer]);

    // Setting default selected sip date
    useEffect(() => {

        if (props.availableInvestmentTypes.sip === false) return;

        const sip_dates = props.fundSummary.sip_dates.split(",").map(Number);
        const default_date = new Date().getDate() + 1;

        if (default_date > 28) {
            setSelectedSIPDate(sip_dates[0]);
        } else {
            if (sip_dates.includes(default_date)) {
                setSelectedSIPDate(default_date);
            } else {
                setSelectedSIPDate(sip_dates[0]);
            }
        }
    }, [props.fundSummary.sip_dates]);

    // Closing Sip date modal when clicking outside
    useEffect(() => {
        window.addEventListener("click", (e) => {
            if (!e.target.closest("#mf-sip-date-modal")) {
                setToggleDateModal(false);
            }
        });

        return window.removeEventListener("click", (e) => {
            if (!e.target.closest("#mf-sip-date-modal")) {
                setToggleDateModal(false);
            }
        });

    }, []);

    useEffect(() => {
        if (selectedSIPDate !== "") {
            // Formatting selected SIP date
            // helper function
            const formatted_sip_date = _formatSipDate(selectedSIPDate);
            setSelectedSIPDateString(formatted_sip_date);
        };
    }, [selectedSIPDate]);

    //handle tab change
    const _handleTabChange = (slected_tab_id) => {
        setActiveTab(slected_tab_id);
        setIsPayingNow(1);
        setLoading(false);
        setIsDisabled(false);
        setSelectedPaymentType(props.fundSummary.payment_types[0].label);
    };

    //handle amount select
    const _handleAmountSelect = (amount) => {
        // removing error msg from input
        setErrorMsgLumpsum("");
        setErrorMsgSIP("");

        if (activeTab === 1) {
            setSip(amount);
        } else {
            setLumpsum(amount);
        }
    };

    const _handleConfirmPayingNow = (status) => setIsPayingNow(status);
    const _handlePaymentMethod = (payment_type) => setSelectedPaymentType(payment_type);

    //handle confirm otp
    const _handleChangeOTP = (value) => {
        setOtpValue(value);
    };

    // Function to handle OTP digit input for a specific position
    const _handleResendOTP = () => {
        // API call for resending otp
        let resend = true;
        _getOTP(resend);
    };

    //handle back
    const _handleBack = () => {
        if (isDisabled) return;
        setToggleInvest(false);
        setOtpValue("");
        setLoading(false);
        setIsDisabled(false);
        otpInputRef.current.disabled = false;
    };

    const _handleCloseDateModal = () => {
        setToggleDateModal(false);
    };

    const _handleInputLumpsumChange = (value) => {
        if (activeTab === 1) {
            setErrorMsgSIP("");
            setSip(value);
        } else {
            setErrorMsgLumpsum("");
            setLumpsum(value);
        };
    };

    const _handleModalClose = () => {
        setToggleInvest(false);
        setActiveTab(props.investmentType);
        setIsPayingNow(1);
        setToggleDateModal(false);
        setSelectedSIPDate("");
        setSip(0);
        setLumpsum(0);
        setErrorMsgSIP("");
        setErrorMsgLumpsum("");
        setErrorMsgSipDate("");

        // To change the modal open status
        props.onCloseModal();
    };

    //handle SIP date select
    const _handleSIPDateSelect = (date) => {
        setErrorMsgSipDate("");
        setSelectedSIPDate(date);

        // Formatting selected SIP date
        // helper function
        const formatted_sip_date = _formatSipDate(date);
        setSelectedSIPDateString(formatted_sip_date);
    };

    //handle invest MF 
    const _handleInvestMF = () => {

        toast.dismiss();

        if (otpValue.length === 0) return;

        if (otpValue.length !== 4) {
            toast.error("OTP Invalid", {
                className: "e-toast",

            })
            return;
        };

        // btn loader
        setLoading(true);
        setIsDisabled(true);
        // API Call for invest in selected fund
        _investMF();
    };

    const _handleToggleDateModal = () => {
        document.getElementById("mf-sip-date-modal").scrollIntoView({ behavior: "smooth" });
        setToggleDateModal(status => !status)
    };

    // handle toggle invest now 
    const _handleToggleInvest = () => {
        // Validation for SIP Investment
        if (activeTab === 1) {
            if (sip === 0) {
                setErrorMsgSIP("Please enter amount");
                return;
            } else if (sip < minInvestment.min_sip) {
                setErrorMsgSIP(`Min investment should be ₹${minInvestment.min_sip.toLocaleString("en-IN")}`);
                return;
            };

            if (selectedSIPDate == "") {
                setErrorMsgSipDate("Please select date");
                return;
            };

            if (fundSummary.mandate_status == false) {

                setLoading(true);



                // Call CreateMandate API
                let param = {
                    create: true,
                    customer_id: userData.id,
                };
                _createMandate(param).then(response => {

                    if (response.status_code === 200 && response.data.mandate_url) {
                        setLoading(false);
                        // Preventing from multiple request
                        setIsDisabled(true);

                        const close_btn = document.querySelector("#mf-invest-modal-close");
                        close_btn.click();

                        props.onSuccess("INITIATE_MANDATE", response.data.mandate_url);
                    } else {
                        toast.dismiss();
                        toast.error(response.data.message);
                        setLoading(false);
                    };
                });

                return;
            };
        };

        // Validation for Lumpsum Investment
        if (activeTab === 2) {
            if (lumpsum === 0) {
                setErrorMsgLumpsum("Please provide a lumpsum amount, as it is required for processing");
                return;
            } else if (lumpsum < minInvestment.min_lumpsum) {
                setErrorMsgLumpsum(`Min investment should be ₹${minInvestment.min_lumpsum.toLocaleString("en-IN")}`);
                return;
            }
        };

        // API Call to send OTP
        setFocusedInput(2);
        setLoading(true);
        _getOTP();
    };

    // API - send OTP 
    const _getOTP = (resend) => {

        // API CALL from helper function
        const param = {
            type: "MF",
            id: userData.id
        };

        _sendOTP(param).then(response => {
            if (response.status_code === 200) {

                setOtpValue("");
                setTimer(30);

                if (resend === true) {
                    setFocusedInput(2);
                    toast.dismiss();
                    toast.success("OTP re-sent successfully", {
                        className: "e-toast",
                    });
                } else {
                    setToggleInvest(true);
                };

            } else {
                toast.dismiss();
                toast.error(response.data.message);
            };

            setLoading(false);
        })
    };

    //API -  investMF
    const _investMF = () => {

        let is_post = true;
        let url = '/goal/mf-invest';
        let data = {
            "type": null,
            "payment_type": isPayingNow === 1 ? selectedPaymentType : false,
            "otp": Number(otpValue),
            "mf_id": fundSummary.mf_id,
            "platform": "web",
            "customer_id": userData.id
        };

        if (activeTab == 1) {
            data = {
                ...data,
                "sip": {
                    "amount": sip,
                    "date": selectedSIPDate,
                    "tenure": 1,
                    "get_first_installment": isPayingNow === 1 ? true : false
                },
            };
        } else {
            data = {
                ...data,
                "lumpsum": {
                    "amount": lumpsum
                },
            };
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                const close_btn = document.querySelector("#mf-invest-modal-close");
                close_btn.click();

                if (response.data.payment_url) {
                    props.onSuccess("PAYMENT_URL_CREATED", response.data.payment_url);
                } else {
                    props.onSuccess("SIP_SCHEDULED");
                };

            } else {
                otpInputRef.current.disabled = false;
                toast.dismiss();
                toast.error(response.data.message, { className: "e-toast", });
                setToggleInvest(false);
                setIsDisabled(false);
                setLoading(false);
            };
        });
    };

    return (
        <div className="modal fade e-manage-modal"
            id="invest-mf-modal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="MFInvestLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <>
                    {
                        toggleInvest === false ?
                            <div className="modal-content e-bg-lotion e-border-radius-8 e-modal-box-shadow p-0">
                                <div className="modal-header justify-content-between border-0 py-3 px-3 ">
                                    <h6 className='mb-0 e-text-chinese-black e-font-18 e-font-manrope e-font-weight-600 line-height-32px'>
                                        Investment details
                                    </h6>
                                    <button type="button"
                                        id='mf-invest-modal-close'
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={_handleModalClose}></button>
                                </div>
                                <div className="modal-body pb-4 px-3 pt-0">
                                    <div className='e-border-radius-16 e-bg-cultured p-3'>
                                        <div className="col d-flex gap-2 align-items-center">
                                            <img src={fundSummary.image}
                                                width={48}
                                                height={48}
                                                className="object-fit-contain p-1 e-border-radius-10 me-1 e-bg-white align-self-start"
                                                draggable="false"
                                                alt="fund logo" />
                                            <div className="d-flex flex-column">
                                                <h3 className="e-ellipse e-text-chinese-black e-font-14 e-font-manrope e-font-weight-600 line-height-20px">
                                                    {fundSummary.name}
                                                </h3>
                                                <div className="d-flex flex-wrap gap-2">
                                                    <span className={`px-3 py-1 e-font-12 e-border-radius-24 e-border-1px-primary-blue e-text-primary e-font-manrope e-font-weight-400 line-height-18px`}>
                                                        {fundSummary.scheme_category}
                                                    </span>
                                                    {
                                                        fundSummary.option_name !== null &&
                                                        <span className={`px-3 py-1 e-font-12 e-border-radius-24 e-border-1px-primary-blue e-text-primary e-font-manrope e-font-weight-400 line-height-18px`}>
                                                            {fundSummary.option_name}
                                                        </span>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='mt-3 p-3 e-border-radius-16 e-bg-cultured'>
                                        {/* tab component */}
                                        <div className='d-flex'>
                                            <div className="e-tab-container">
                                                <FundsTab index={activeTab}
                                                    invest
                                                    data={investmentTabs}
                                                    setTab={_handleTabChange} />
                                            </div>
                                        </div>

                                        {/* custom amount input */}
                                        <div className='row mt-3 position-relative'>
                                            <div className={`${activeTab === 2 ? "col-sm-7" : "col-sm-6"}`}>
                                                <CustomAmountInput require={true}
                                                    label={activeTab === 1 ? "Monthly amount " : "One time investment amount "}
                                                    value={activeTab === 1 ? sip : lumpsum}
                                                    maxLength={8}
                                                    errorMsg={activeTab === 1 ? errorMsgSIP : errorMsgLumpsum}
                                                    placeholder={activeTab === 1 ? "SIP amount" : "Lumpusm amount"}
                                                    handleChange={_handleInputLumpsumChange} />

                                            </div>
                                            {activeTab === 1 &&
                                                <div className='col-sm-6  mb-2 mb-sm-0 order-3 order-sm-2'
                                                    id='mf-sip-date-modal'>
                                                    <CustomTextInput require={true}
                                                        type={2}
                                                        label='Monthly SIP date '
                                                        value={selectedSIPDateString}
                                                        placeholder="Choose date"
                                                        className="cursor-pointer w-100 e-font-12 e-font-manrope e-font-weight-500 no-outline"
                                                        maxLength={8}
                                                        readOnly={true}
                                                        errorMsg={errorMsgSipDate}
                                                        onClick={_handleToggleDateModal}
                                                    />
                                                    {
                                                        toggleDateModal &&
                                                        <SIPDateModal
                                                            selectedSIPDate={selectedSIPDate}
                                                            sipDates={sipActiveDates}
                                                            className="position-absolute start-0"
                                                            handleSelect={_handleSIPDateSelect}
                                                            handleCloseDateModal={_handleCloseDateModal} />
                                                    }
                                                </div>
                                            }
                                            <div className="col-12 order-2 order-sm-3 mb-3 mb-sm-0 mt-1 mt-sm-0">
                                                {/* amount select  */}
                                                <div className="d-flex flex-wrap gap-2 pt-2">
                                                    {
                                                        AMOUNT_DATA.map((data, key) => (
                                                            <p key={key}
                                                                className={`${(activeTab === 1 ? sip === data.amount : lumpsum === data.amount) ?
                                                                    'e-border-otp-active e-text-primary cursor-default' : 'cursor-pointer e-text-chinese-black e-border-1px-chinese-black'}
                                                                mb-0 px-2 py-1 e-border-radius-8  e-font-14 e-primary-font-inter e-font-weight-500 line-height-24px`}
                                                                onClick={() => _handleAmountSelect(data.amount)}>
                                                                ₹{data.amount.toLocaleString("en-IN")}
                                                            </p>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            activeTab === 1 &&
                                            <>
                                                <p className='mb-2 pt-3 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-500 line-height-24px'>
                                                    Would you like to proceed with the first instalment at this time?
                                                </p>

                                                {/* confirmation radio button */}
                                                <div className="d-flex gap-3">
                                                    <RadioGroup data={CONFIRM_PAYMENT}
                                                        selected={isPayingNow}
                                                        handleSelect={_handleConfirmPayingNow} />
                                                </div>
                                            </>
                                        }

                                    </div>

                                    {/* Payment Method */}
                                    {
                                        isPayingNow === 1 &&
                                        <div>
                                            <h6 className='my-3 e-text-chinese-black e-font-manrope e-font-16 e-font-weight-600 line-height-24px'>
                                                Payment Method
                                            </h6>
                                            <div className='p-3 e-border-radius-16 e-bg-cultured'>
                                                {paymentMethod.map((data, key) => (
                                                    <React.Fragment key={key}>
                                                        <div className={`${selectedPaymentType !== data.slug ? "cursor-pointer" : "cursor-default"} d-flex justify-content-between gap-2`}
                                                            onClick={() => _handlePaymentMethod(data.slug)}>
                                                            <p className="d-flex align-items-center gap-2 e-text-chinese-black mb-0 e-font-14 e-font-manrope e-font-weight-500 line-height-22px">
                                                                <img src={data.image}
                                                                    alt={`payment method ${data.label}`}
                                                                    draggable="false" />
                                                                <span>{data.label}</span>
                                                            </p>
                                                            <img src={selectedPaymentType === data.slug ? Active : Inactive}
                                                                draggable="false"
                                                                className="w-24px width-20px-res"
                                                                alt="radio" />
                                                        </div>
                                                        {key == paymentMethod.length - 2 && paymentMethod.length > 1 &&
                                                            <hr style={{ borderColor: "#D1D1D6" }} />}
                                                    </React.Fragment>
                                                ))}
                                            </div >
                                        </div>
                                    }

                                    {/* SIP Mandate Infromation */}
                                    {
                                        activeTab === 1 && fundSummary.mandate_status === false &&
                                        <div className="d-flex flex-column mt-4 gap-2 mt-1 e-bg-cosmic-latte  e-border-radius-16 p-3">
                                            <h6 className='d-flex gap-2 align-items-center e-text-mustard-yellow e-font-weight-600 line-height-18px'>
                                                <IcoMoon iconSet={iconSet}
                                                    icon={"info"}
                                                    size={"1.125rem"}
                                                    color={Colors.mustard_yellow} />
                                                Information
                                            </h6>
                                            <div className='d-flex flex-column gap-2'>
                                                {
                                                    SIP_MANDATE_INFO.map((item, index) => (
                                                        <p className='d-flex gap-1 e-font-manrope e-text-onyx mb-0 e-font-14 e-font-weight-400 line-height-24px'>
                                                            {index + 1}{"."}
                                                            <span>{item}</span>
                                                        </p>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    }

                                    <div className='pt-4'>
                                        <PrimaryBtn name={`${activeTab == 1 ? fundSummary.mandate_status === false ? "Initiate Mandate" : "Request OTP" : "Request OTP"}`}
                                            loading={loading}
                                            className="e-font-14-res w-100 h-40"
                                            disabled={isDisabled}
                                            handleClick={_handleToggleInvest} />
                                    </div>
                                </div>
                            </div>
                            :

                            <div className="modal-content e-bg-lotion e-border-radius-8 e-modal-box-shadow p-0">
                                <div className="modal-header justify-content-start gap-3 pt-4 pb-3 px-3 border-0">

                                    {/* Hidden close btn for closing modal */}
                                    <span id='mf-invest-modal-close'
                                        data-bs-dismiss="modal"
                                        className='d-none'
                                        aria-label="close"
                                        alt='close btn'
                                    />

                                    <img src={require("../../Assets/Images/Cta/back-icon.svg").default}
                                        draggable={false}
                                        className='cursor-pointer'
                                        alt="arrow-back"
                                        onClick={_handleBack} />
                                    <h6 className='e-font-14-res mb-0 e-text-chinese-black e-font-18 e-font-manrope e-font-weight-600 line-height-32px'>
                                        Purchase confirmation OTP
                                    </h6>

                                </div>
                                <div className="modal-body pb-4 px-3 pt-0">

                                    {/* otp section */}
                                    <div className='p-3 e-border-radius-16 e-bg-cultured'>
                                        <p className='mb-0 pt-3 e-text-onyx e-font-16 e-font-manrope e-font-weight-400 line-height-28px'>
                                            An OTP has been sent to your registered
                                            {userData.country_code === "+91" ? "  mobile number" : " email"}
                                            {
                                                userData.country_code === "+91" ?
                                                    <span className='ms-1 e-text-primary e-font-weight-500'>
                                                        {userData.country_code}-{userData.phone}
                                                    </span>
                                                    :
                                                    <span className='ms-1 e-text-primary e-font-weight-500'>
                                                        {userData.email}
                                                    </span>
                                            }
                                        </p>
                                        <div className='pt-3'>
                                            <label className="e-font-14-res e-text-chinese-black e-font-14 e-font-manrope e-font-weight-500 line-height-24px">
                                                Enter your OTP*
                                            </label>
                                            <OTPInput ref={otpInputRef}
                                                id="otp"
                                                value={otpValue}
                                                spellCheck="false"
                                                focused_input={focusedInput}
                                                onFocus={() => setFocusedInput(2)}
                                                onBlur={() => setFocusedInput(0)}
                                                onChange={_handleChangeOTP} />

                                            <div className='d-flex pt-2 align-items-center'>
                                                <button className={`${timer === 0 ? "text-decoration-hover-underline" : "e-disable"} border-0 bg-transparent e-text-primary e-font-12 e-font-manrope e-font-weight-500 line-height-26px`}
                                                    onClick={_handleResendOTP} >
                                                    Re-send OTP
                                                </button>
                                                {
                                                    timer !== 0 &&
                                                    <p className='mb-0 e-text-chinese-black e-font-12 e-font-manrope e-font-weight-600 line-height-26px'>
                                                        in {timer} {timer == 1 ? "second" : "seconds"}
                                                    </p>
                                                }
                                            </div>
                                        </div>

                                        <p className='mb-0 pt-3 e-text-light-grey e-font-12 e-font-manrope e-font-weight-400 line-height-18px'>
                                            *In accordance with regulatory, 2FA verification is required in order to create an order.
                                        </p>
                                    </div>

                                    {/* Mandate success msg for SIP */}
                                    {
                                        mandateStatus == false && activeTab == 1 &&
                                        <div className='d-flex gap-2 p-3 e-bg-nyanza-green e-border-radius-16 mt-3'>
                                            <IcoMoon iconSet={iconSet}
                                                icon={"mandate-success"}
                                                size={"1.75rem"} />
                                            <p className='e-text-go-green e-font-manrope e-font-14 e-font-weight-400 line-height-24px letter-spacing-3 mb-0'>
                                                Your mandate has been successfully created. Now, you can proceed with the first instalment
                                            </p>
                                        </div>
                                    }
                                    <div className='pt-4'>
                                        <PrimaryBtn name={isPayingNow == 1 ? "Create & Send Payment URL" : "Schedule SIP"}
                                            loading={loading}
                                            disabled={isDisabled}
                                            className="e-font-14-res w-100 h-40"
                                            handleClick={_handleInvestMF} />
                                    </div>
                                </div>
                            </div>
                    }
                </>
            </div>
        </div >
    )
};

export default memo(MFInvestModal);