/*
 *   File : selected-goal.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Selected goal calculation component
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 29-02-2024
 */

import { memo, useEffect, useState } from "react";

// Component
import { CustomAmountInput, CustomSlider, RadioGroup } from "../Form-Elements";
import { PrimaryBtn } from "../Cta";


// Custom data
const INFLATION = [
    {
        id: 1,
        display_name: "Yes"
    },
    {
        id: 2,
        display_name: "No"
    },
];

const LUMPSUM_SELECT = [
    {
        id: 1,
        display_name: "Yes"
    },
    {
        id: 2,
        display_name: "No"
    },
];

function LifeGoalCard(props) {

    const [target, setTarget] = useState("");
    const [targetErr, setTargetErr] = useState("");
    const [sipStepUp, setSipStepUp] = useState(10);
    const [lumpsum, setLumpsum] = useState("");
    const [lumpsumErr, setLumpsumErr] = useState("");
    const [inflationSelect, setInflationSelect] = useState(1);
    const [lumpSumSelect, setLumpSumSelect] = useState(1);
    const [tenure, setTenure] = useState(0);

    useEffect(() => {

        if (props.selectedGoal["short name"] == "Post Graduation") {
            setTenure(props.selectedGoal.meta_data.from.default);
        } else {
            setTenure(props.selectedGoal.meta_data.tenure.default);
        };

        setTarget(0);
        setLumpsum(0);

    }, [props.selectedGoal]);

    const _handleTargetChange = (amt) => {
        setTarget(amt);
        setTargetErr("");
    };

    const _handleInflationSelect = (id) => {
        setInflationSelect(id);
    };

    const _handleLumpsumChange = (lumpsum) => {
        setLumpsum(lumpsum);
        setLumpsumErr("");
    };

    //handle tenure slider
    const _handleTenureSlider = (value) => {
        setTenure(value);
    };

    const _handleSipStepUpChange = (step_up) => {
        setSipStepUp(step_up);
    };

    const _handleLumpsumSelect = (id) => {
        setLumpSumSelect(id);
        if (id === 2) {
            setLumpsumErr("");
            setLumpsum(0);
        }
    };

    const _handleSetGoal = () => {

        let validation = true;

        if (target == "" || target == 0) {
            setTargetErr("Please provide target amount");
            validation = false;
        };

        if (lumpSumSelect === 1 && (lumpsum == "" || lumpsum == 0)) {
            setLumpsumErr("Please provide lumpsum amount");
            validation = false;
        };

        if (validation) {
            const data = {
                "is_inflation_enabled": inflationSelect === 1 ? true : false,
                "tenure": tenure,
                "target": target,
                "lumpsum": lumpsum,
                "step_sip": sipStepUp,
                "lumpsum_select": lumpSumSelect == 1 ? true : false,
            };

            props.handleSetGoal(data);

        };

    };

    return (
        <div>
            {/* Goal Inputs */}
            <div className="e-bg-ghost-white e-border-radius-8  p-4 pt-3 mt-4">
                <div className="row flex-wrap row-gap-3 ">
                    <div className="col-4">
                        <CustomAmountInput
                            label={props.title}
                            value={target}
                            require
                            placeholder="0"
                            errorMsg={targetErr}
                            handleChange={_handleTargetChange} />
                    </div>
                    <div className="col-4">
                        <div className="d-flex justify-content-between pt-1 mb-3">
                            <label className={`e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14`}>
                                Tenure
                            </label>
                            <span className="e-text-primary e-font-14 e-font-manrope e-font-weight-800">
                                {tenure} {tenure > 1 ? "Yrs" : "Yr"}
                            </span>
                        </div>

                        <CustomSlider min={props.tenureMin}
                            max={props.tenureMax}
                            step={1}
                            labelType="Year"
                            defaultValue={tenure}
                            handleChange={_handleTenureSlider} />
                    </div>
                    <div className="col-4">
                        <div className="d-flex justify-content-between pt-1 mb-3">
                            <label className={`e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14`}>
                                Step up sip%
                            </label>
                            <span className="e-text-primary e-font-14 e-font-manrope e-font-weight-800">
                                {sipStepUp} %
                            </span>
                        </div>

                        <CustomSlider min={props.selectedGoal.meta_data.step_sip.min}
                            max={props.selectedGoal.meta_data.step_sip.max}
                            step={1}
                            labelType="Percentage"
                            defaultValue={sipStepUp}
                            handleChange={_handleSipStepUpChange} />
                    </div>
                    <div className="col-4">
                        <label className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14 mb-3">
                            Invest lumpsum now?
                        </label>
                        <div className="d-flex gap-3">
                            <RadioGroup
                                data={LUMPSUM_SELECT}
                                selected={lumpSumSelect}
                                handleSelect={_handleLumpsumSelect} />
                        </div>
                    </div>
                    {
                        lumpSumSelect == 1 &&
                        <div className="col-4">
                            <CustomAmountInput
                                label="Lumpsum amount"
                                require={lumpSumSelect === 1 ? true : false}
                                placeholder="0"
                                value={lumpsum}
                                errorMsg={lumpsumErr}
                                handleChange={_handleLumpsumChange} />
                        </div>
                    }
                    <div className="col-4">
                        <label className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14 mb-3">
                            Inflation adjusted amount({props.selectedGoal.meta_data.inflation_rate}%)
                        </label>
                        <div className="d-flex gap-3">
                            <RadioGroup
                                data={INFLATION}
                                selected={inflationSelect}
                                handleSelect={_handleInflationSelect} />
                        </div>

                    </div>

                    <div>
                        <PrimaryBtn name="Calculate"
                            loading={props.loading}
                            className="mt-2 col-sm-5 col-md-4 col-lg-3 col-xl-2 d-flex align-items-center justify-content-center h-40"
                            handleClick={_handleSetGoal} />
                    </div>
                </div>
            </div>

        </div >
    )
}

export default memo(LifeGoalCard);