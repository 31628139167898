/*
 *   File : risk-score.js
 *   Author : https://evoqins.com
 *   Description : Risk score editing modal
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

//custom component
import { PrimaryBtn } from '../Cta';
import service from '../../Services/service';
import SelectBox from '../Dropdown/select-box';
import { _closeModal } from '../../Helper/function';

const SetRiskScoreModal = (props) => {

    const [riskScore, setRiskScore] = useState("");
    const [riskScoreErr, setRiskScoreErr] = useState("");
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setRiskScore({
            value: props.riskScore || 6,
            label: props.riskScore || 6
        });
    }, [props.riskScore]);

    // Closing modal when "ESC" pressed
    useEffect(() => {
        _closeModal(props.onCloseModal);
    }, []);

    const _handleRiskScoreSelect = (selected) => {
        setRiskScore(selected);
        setRiskScoreErr("");
    };

    // API - set risk score
    const _setRiskScore = () => {

        if (riskScore === "") {
            setRiskScoreErr("Please select risk score to proceed");
            return;
        };

        setLoading(true);

        let is_post = true;
        let url = "/kyc/set-risk-score";
        let data = {
            "customer_id": props.customerId,
            "risk_score": riskScore["value"]
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("Risk score updated successfully");
                props.handleSetRiskScore(riskScore["value"]);
                let close_btn = document.getElementById("risk-score-modal-close");
                close_btn.click();

            } else {
                toast.dismiss();
                toast.error(response.data.message);
            };
            setLoading(false);
        });
    };


    return (
        <div className={`modal fade e-manage-modal `}
            id="risk-score-modal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="risk score modal"
            aria-hidden="true">
            <div className={`modal-dialog  modal-dialog-centered`}>
                <div className="modal-content e-border-radius-8 p-0 h-90vh">
                    <div className="modal-header justify-content-space-between pt-4  px-4 pb-0 border-0">
                        <h6 className='mb-0 e-text-chinese-black e-font-18 e-font-manrope e-font-weight-600 line-height-32px'>
                            Select risk score
                        </h6>
                        <button id="risk-score-modal-close"
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={props.onCloseModal}></button>
                    </div>
                    <div className="modal-body pb-4 px-4 d-flex flex-column justify-content-between">
                        <div>
                            <p className="mb-2 e-filter-label">
                                Select risk score*
                            </p>
                            <div className='position-relative'>

                                <SelectBox
                                    value={riskScore ? riskScore : null}
                                    options={props.riskScoreData}
                                    selectChange={_handleRiskScoreSelect}
                                    placeholder="9"
                                    isSearchable={false} />
                            </div>
                            {/* Error text */}
                            <p className={`${riskScoreErr === "" ? "opacity-0" : "opacity-100 "} h-6 mb-2 e-error-message e-font-12 ps-1 line-height-12px pt-1`}>
                                {riskScoreErr}
                            </p>

                        </div>
                        <PrimaryBtn name={"Save"}
                            loading={loading}
                            className="mt-auto ms-auto col-6 d-flex align-items-center justify-content-center h-40"
                            handleClick={_setRiskScore} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SetRiskScoreModal;