/*
 *   File : life-goal-retirement.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Retirement goal order creation card
 *   Integrations : React toastify
 *   Version : 1.0.0
 *   Created : 24-04-2024
 */

import { useEffect, useState } from "react";

// Components
import { CustomAmountInput, CustomSlider, RadioGroup } from "../Form-Elements";
import { PrimaryBtn } from "../Cta";
import { _setGoal } from "../../Helper";

// Assets
import Edit from '../../Assets/Images/Cta/edit.svg';

// Custom data
const INFLATION = [
    {
        id: 1,
        display_name: "Yes"
    },
    {
        id: 2,
        display_name: "No"
    },
];

const LUMPSUM_SELECT = [
    {
        id: 1,
        display_name: "Yes"
    },
    {
        id: 2,
        display_name: "No"
    },
];

// Investment select 
const CALCULATE_CORPUS_SELECT = [
    {
        id: 1,
        display_name: "Yes"
    },
    {
        id: 2,
        display_name: "No"
    },
];

function RetirementGoalCard(props) {

    const [target, setTarget] = useState("");
    const [sipStepUp, setSipStepUp] = useState(10);
    const [lumpsum, setLumpsum] = useState("");
    const [inflationSelect, setInflationSelect] = useState(1);
    const [lumpSumSelect, setLumpSumSelect] = useState(1);

    const [calculateCorpusSelect, setCalculateCorpusSelect] = useState(1);
    const [currentAge, setCurrentAge] = useState(props.selectedGoal.meta_data.current_age.default);
    const [retireAge, setRetireAge] = useState(props.selectedGoal.meta_data.retirement_age.default);
    const [monthlyExpense, setMonthlyExpense] = useState(0);
    const [monthlyExpenseErr, setMonthlyExpenseErr] = useState("");
    const [calculatedCorpusData, setCalculatedCorpusData] = useState(props.corpusData);
    const [corpusLoader, setCorpusLoader] = useState(props.loading);

    const [tenure, setTenure] = useState(retireAge - currentAge);

    const [targetErr, setTargetErr] = useState("");
    const [lumpsumErr, setLumpsumErr] = useState("");


    useEffect(() => {

        if (props.corpusData) {
            setCalculatedCorpusData(props.corpusData);
            setTarget(props.corpusData.corpus_amount);
            setCorpusLoader(false);
            setTenure(retireAge - currentAge);
        };

    }, [props.corpusData]);

    const _handleTargetChange = (amt) => {
        setTarget(amt);
        setTargetErr("");
    };

    const _handleMonthlyExpChange = (amt) => {
        setMonthlyExpense(amt);
        setMonthlyExpenseErr("");
    };

    const _handleInflationSelect = (id) => {
        setInflationSelect(id);
    };

    const _handleLumpsumChange = (lumpsum) => {
        setLumpsum(lumpsum);
        setLumpsumErr("");
    };

    const _handleEditCorpus = () => {
        setCalculatedCorpusData("");
        props.onEditCorpus();
    };

    const _handleLumpsumSelect = (id) => {
        setLumpSumSelect(id);
        if (id === 2) {
            setLumpsumErr("");
            setLumpsum(0);
        }
    };

    const _handleSetGoal = () => {

        let validation = true;

        if (target == "" || target == 0) {
            setTargetErr("Please provide target amount");
            validation = false;
        };

        if (lumpSumSelect === 1 && (lumpsum == "" || lumpsum == 0)) {
            setLumpsumErr("Please provide lumpsum amount");
            validation = false;
        };

        if (validation) {
            const data = {
                "is_inflation_enabled": inflationSelect === 1 ? true : false,
                "tenure": tenure,
                "target": target,
                "lumpsum": lumpsum,
                "step_sip": sipStepUp,
                "lumpsum_select": lumpSumSelect == 1 ? true : false,
            };

            props.handleSetGoal(data);

        };

    };

    const _handleCalculateCorpus = () => {

        if (monthlyExpense === 0) {
            setMonthlyExpenseErr("Please provide your monthly expenses")
        } else {
            setCorpusLoader(true);
            let data = {
                current_age: currentAge,
                retire_age: retireAge,
                monthly_expense: monthlyExpense
            }
            props.handleCalculateCorpus(data);
        }

    };

    const _handleCalculateCorpusSelect = (selected_id) => {
        setCalculateCorpusSelect(selected_id);
        if (selected_id === 2) {
            setCalculatedCorpusData("");
        };
        setMonthlyExpense(0);
        setTarget(0);
    };

    return (
        <div>
            {/* Calculate retirment corpus */}
            <div className="">
                <p className="mb-2 e-filter-label pt-1">
                    Want to calculate your retirement corpus
                </p>
                <div className='d-flex flex-wrap gap-3'>
                    <RadioGroup data={CALCULATE_CORPUS_SELECT}
                        selected={calculateCorpusSelect}
                        handleSelect={_handleCalculateCorpusSelect} />
                </div>
            </div>
            {/* Corpus calculation inputs */}
            {
                calculateCorpusSelect === 1 &&
                <div className="mt-4 e-bg-ghost-white e-border-radius-8  p-4 pt-3">
                    {
                        calculatedCorpusData["corpus_amount_text"] === undefined &&
                        <>
                            <div className="d-flex gap-4">
                                <div className="col">
                                    <div className="d-flex justify-content-between pt-1 mb-3">
                                        <label className={`e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14`}>
                                            Current age
                                        </label>
                                        <span className="e-text-primary e-font-14 e-font-manrope e-font-weight-800">
                                            {currentAge} {currentAge > 1 ? "Yrs" : "Yr"}
                                        </span>
                                    </div>
                                    <CustomSlider min={props.selectedGoal.meta_data.current_age.min_age}
                                        max={props.selectedGoal.meta_data.current_age.max_age}
                                        labelType="Year"
                                        step={1}
                                        defaultValue={currentAge}
                                        handleChange={(value) => setCurrentAge(value)} />
                                </div>
                                <div className="col">
                                    <div className="d-flex justify-content-between pt-1 mb-3">
                                        <label className={`e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14`}>
                                            Retirement age
                                        </label>
                                        <span className="e-text-primary e-font-14 e-font-manrope e-font-weight-800">
                                            {retireAge} {retireAge > 1 ? "Yrs" : "Yr"}
                                        </span>
                                    </div>

                                    <CustomSlider min={props.selectedGoal.meta_data.retirement_age.min}
                                        max={props.selectedGoal.meta_data.retirement_age.max}
                                        step={1}
                                        labelType="Year"
                                        defaultValue={retireAge}
                                        handleChange={(value) => setRetireAge(value)} />
                                </div>
                                <div className="col mb-2">
                                    <CustomAmountInput
                                        require
                                        label="Monthly expenses"
                                        value={monthlyExpense}
                                        maxValue={999999}
                                        placeholder="0"
                                        errorMsg={monthlyExpenseErr}
                                        handleChange={_handleMonthlyExpChange} />
                                </div>
                            </div>
                            <div className="col-2 mt-1">
                                <PrimaryBtn className={`d-flex align-items-center justify-content-center w-100 h-40`}
                                    loading={corpusLoader ? true : false}
                                    name="Calculate"
                                    handleClick={_handleCalculateCorpus} />
                            </div>
                        </>
                    }
                    {
                        calculatedCorpusData &&
                        <div className="d-flex flex-column gap-3 bg-white p-4 e-border-radius-8 mt-2">
                            <h6 className="d-flex justify-content-between e-filter-label mb-0 e-font-16">
                                Recommended retirement corpus
                                <img src={Edit}
                                    width={14}
                                    height={14}
                                    className="cursor-pointer"
                                    alt="edit"
                                    onClick={_handleEditCorpus}
                                />
                            </h6>
                            <p className="e-filter-label e-text-light-grey mb-0">
                                Based on your current expenses and age, you will need the
                                following amount to ensure a peaceful retirement life.
                            </p>
                            <p className="e-filter-label e-font-18 e-text-primary mb-0 fw-bolder">
                                {calculatedCorpusData.corpus_amount_text}
                            </p>
                        </div>
                    }
                </div>
            }

            {/* Goal Inputs */}
            {
                (calculateCorpusSelect === 2 || calculatedCorpusData["corpus_amount_text"]) &&
                <div className="e-bg-ghost-white e-border-radius-8  p-4 pt-3 mt-4">
                    <div className="row flex-wrap row-gap-3 ">
                        <div className="col-4">
                            <CustomAmountInput
                                label={props.title}
                                value={target}
                                require
                                placeholder="0"
                                errorMsg={targetErr}
                                handleChange={_handleTargetChange} />
                        </div>
                        <div className="col-4">
                            <div className="d-flex justify-content-between pt-1 mb-3">
                                <label className={`e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14`}>
                                    Tenure
                                </label>
                                <span className="e-text-primary e-font-14 e-font-manrope e-font-weight-800">
                                    {tenure} {tenure > 1 ? "Yrs" : "Yr"}
                                </span>
                            </div>

                            <CustomSlider min={props.tenureMin}
                                max={props.tenureMax}
                                step={1}
                                labelType="Year"
                                defaultValue={tenure}
                                handleChange={(value) => setTenure(value)} />
                        </div>
                        <div className="col-4">
                            <div className="d-flex justify-content-between pt-1 mb-3">
                                <label className={`e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14`}>
                                    Step up sip%
                                </label>
                                <span className="e-text-primary e-font-14 e-font-manrope e-font-weight-800">
                                    {sipStepUp} %
                                </span>
                            </div>

                            <CustomSlider min={props.selectedGoal.meta_data.step_sip.min}
                                max={props.selectedGoal.meta_data.step_sip.max}
                                step={1}
                                labelType="Percentage"
                                defaultValue={sipStepUp}
                                handleChange={(value) => setSipStepUp(value)} />
                        </div>
                        <div className="col-4">
                            <label className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14 mb-3">
                                Invest lumpsum now?
                            </label>
                            <div className="d-flex gap-3">
                                <RadioGroup
                                    data={LUMPSUM_SELECT}
                                    selected={lumpSumSelect}
                                    handleSelect={_handleLumpsumSelect} />
                            </div>
                        </div>
                        {
                            lumpSumSelect == 1 &&
                            <div className="col-4">
                                <CustomAmountInput
                                    label="Lumpsum amount"
                                    require={lumpSumSelect === 1 ? true : false}
                                    placeholder="0"
                                    value={lumpsum}
                                    errorMsg={lumpsumErr}
                                    handleChange={_handleLumpsumChange} />
                            </div>
                        }
                        <div className="col-4">
                            <label className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14 mb-3">
                                Inflation adjusted amount({props.selectedGoal.meta_data.inflation_rate}%)
                            </label>
                            <div className="d-flex gap-3">
                                <RadioGroup
                                    data={INFLATION}
                                    selected={inflationSelect}
                                    handleSelect={_handleInflationSelect} />
                            </div>

                        </div>

                        <div>
                            <PrimaryBtn name="Calculate"
                                loading={props.loading}
                                className="mt-2 col-2 d-flex align-items-center justify-content-center h-40"
                                handleClick={_handleSetGoal} />
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default RetirementGoalCard;