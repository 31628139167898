/*
 *   File : searchInput.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Input box
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 128-11-2023
 */
/*import package*/
import React, { useState } from 'react';

/*import style*/
import styles from '../../Styles/Components/search-input.module.scss';

const SearchInput = (props) => {
	//eslint-disable-next-line
	const [value, setValue] = useState("");

	/* --- on change pass value ----*/
	const valueChange = (e) => {
		setValue(e.target.value);
		props.valueChange(e.target.value);
	}

	/* ---- pass value on press of enter ---*/
	const keyPress = (e) => {
		if (e.keyCode === 13) {
			if (props.onSearch) {
				props.onSearch(e.target.value);
			}
		}
	}

	return (
		<div className={`${styles.e_input_wrap} flex-fill`}>
			<input id={props.id}
				value={props.value}
				type={props.type}
				//eslint-disable-next-line
				className={`${styles.e_search_input_box}` + " " + props.className}
				onChange={(e) => { valueChange(e) }}
				placeholder={props.placeholder}
				onKeyDown={e => keyPress(e)}
				disabled={props.disabled}
				{...props} />
				
		</div>
	);
}
export default SearchInput;


