/*
 *   File : check-kyc.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : New customer creation page
 *   Integrations :react-router-dom
 *   Version : v1.0
 *   Created : 23-02-2024
 */

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Components
import { PAN, PersonalDetail, AddAddress, AddBank, AddNominee, Summary, Digilocker } from "./Helper";
import { Loader } from "../../Components/Loader";
import { _getSignupProgress } from "../../Helper";
import service from "../../Services/service";

function CheckKyc() {

    const navigate = useNavigate();
    const location = useLocation();

    const [progressData, setProgressData] = useState("");
    const [kycStep, setKycStep] = useState(location.state ? location.state.current_step : 0);
    const [customerId, setCustomerId] = useState(location.state ? location.state.customer_id : 0);
    const [countryData, setCountryData] = useState([]);
    const [panError, setPanError] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // API call - get country data
        _getCountry();

        if (customerId) {
            _getSignupProgress(customerId).then(response => {
                if (response.status_code === 200) {

                    setProgressData(response.data);
                    let status = response.data.kyc_data.status_1;
                    let current_step = response.data.current_step;

                    // Step 0 => PAN
                    // Step 1 => Summary page (for step and showing errors)
                    // Step 2 => Personal details
                    // Step 3 => DigiLocker - Aadhaar fetching
                    // Step 4 => Address details
                    // Step 5 => Bank details 
                    // Step 6 => Nominee Addition

                    if (status === "KYC_SUBMITTED" || status === "KYC_ESIGN_REQUIRED" || status === "INVESTED" || status === "ACCOUNT_CREATED") {
                        navigate(-1);
                    } else {
                        if (current_step === 0) {
                            setKycStep(0);
                        } else {
                            if (location.state.current_step) {
                                setKycStep(location.state.current_step);
                            } else {
                                // Update with summary page
                                setKycStep(1);
                            }
                        }
                    };

                } else {
                    setKycStep(0);
                }

                setLoading(false);

            });
        } else {
            setLoading(false);
            setKycStep(0);
        };

    }, []);

    //handle account setup flow
    const _handleUpdateStep = (current_step, customer_id, is_pan_error) => {

        navigate(location.pathname, {
            state: {
                current_step,
                customer_id
            },
            replace: true
        });

        setKycStep(current_step);
        setPanError(is_pan_error);

        if (customer_id) {
            setCustomerId(customer_id);
        };

        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
    };

    //API- Get countries
    const _getCountry = () => {
        let is_post = false;
        let url = '/general/countries';
        let data = null;

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCountryData(response.data.countries);
            } else {
                setCountryData([]);
            }
        });
    };

    return (

        loading === false ? (
            <div className="row position-relative mt-3">
                <div className="col-12">
                    <span className="e-font-manrope e-font-14">
                        <span className="p-0 e-text-white cursor-pointer"
                            onClick={() => navigate("/onboarding-users")}>
                            Onboarding users
                        </span>
                        <span className="e-text-tab-color"> / Create new user</span>
                    </span>
                    <div className={`mt-4 bg-white min-vh-100 e-border-radius-16px ${kycStep !== 0 ? "overflow-hidden" : ""} p-4`}>
                        <div>
                            {
                                kycStep === 0 &&
                                <PAN data={progressData}
                                    panError={panError}
                                    onUpdateStep={_handleUpdateStep} />
                            }
                            {
                                kycStep === 1 &&
                                <Summary data={progressData}
                                    customerId={customerId}
                                    onUpdateStep={_handleUpdateStep} />
                            }

                            {
                                kycStep === 2 &&
                                <PersonalDetail country={countryData}
                                    onUpdateStep={_handleUpdateStep}
                                    customerId={customerId} />
                            }
                            {
                                kycStep === 3 &&
                                <Digilocker customerId={customerId}
                                    onUpdateStep={_handleUpdateStep} />
                            }
                            {
                                kycStep === 4 &&
                                <AddAddress country={countryData}
                                    customerId={customerId}
                                    onUpdateStep={_handleUpdateStep} />
                            }
                            {
                                kycStep === 5 &&
                                <AddBank customerId={customerId}
                                    onUpdateStep={_handleUpdateStep} />
                            }
                            {
                                kycStep === 6 &&
                                <AddNominee customerId={customerId}
                                    onUpdateStep={_handleUpdateStep} />
                            }
                        </div>
                    </div>

                </div>
            </div>
        ) : (
            <Loader />
        )


    );
};

export default CheckKyc;



