/*
 *   File : nominee.js
 *   Author : https://evoqins.com
 *   Description : Nominee details card component
 *   Integrations : NA
 *   Version : 1.0.0
*/


import React, { useEffect, useState, memo } from 'react';
import { differenceInYears, subDays, format } from 'date-fns';
import { Modal } from 'bootstrap';

//custom component
import { CustomTextInput, DatePicker } from '../Form-Elements';
import { SelectionSuggestModal } from '../Modal';
import { _formatDateFormat } from '../../Helper';

const NomineeCard = (props) => {

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");

    const [selectedRelation, setSelectedRelation] = useState([]);
    const [relationError, setRelationError] = useState("");

    const [allocation, setAllocation] = useState("");
    const [allocationError, setAllocationError] = useState("");

    const [dob, setDob] = useState("");
    const [dobError, setDobError] = useState("");

    const [relationData, setRelationData] = useState(props.data);

    const [guardianName, setGuardianName] = useState("");
    const [guardianNameError, setGuardianNameError] = useState("");

    const [selectedGuardianRelation, setSelectedGuardianRelation] = useState([]);
    const [guardianRelationError, setGuardianRelationError] = useState("");

    const [pan, setPan] = useState("");
    const [panError, setPanError] = useState("");

    const [showSuggestModal, setShowSuggestModal] = useState(false);
    const [modalType, setModalType] = useState("");

    const IS_MINOR = differenceInYears(new Date(), new Date(dob)) < 18;
    const [initialStatus, setInitialStatus] = useState(true);

    // Prefilling already entered data
    useEffect(() => {

        if (props.nomineeData.name !== null) {
            setName(props.nomineeData.name);
        }
        if (props.nomineeData.dob !== null) {
            setDob(_formatDateFormat(props.nomineeData.dob));
        };

        if (props.nomineeData.allocation !== null) {
            setAllocation(props.nomineeData.allocation);
        }

        if (props.nomineeData.guardian_name !== null) {
            setGuardianName(props.nomineeData.guardian_name);
        }

        if (props.nomineeData.guardian_pan !== null) {
            setPan(props.nomineeData.guardian_pan.toUpperCase());
        }

        if (props.data && props.data.kyc_relationship) {

            if (props.nomineeData.relation_id !== null) {
                let selected_relation = props.data.kyc_relationship.find((data) => data.id == props.nomineeData.relation_id);
                setSelectedRelation(selected_relation);
            }
        };

        if (props.data && props.data.nominee_minor_relation) {

            if (props.nomineeData.guardian_relationship_id !== null) {
                let selected_guardian = props.data.nominee_minor_relation.find((data) => data.id == props.nomineeData.guardian_relationship_id);
                setSelectedGuardianRelation(selected_guardian);
            }
        };

        setInitialStatus(false);

    }, [props.nomineeData]);

    useEffect(() => {

        if (showSuggestModal === true) {
            if (modalType === 1) {
                let relation_modal = new Modal(document.getElementById(`select-suggest-modal${props.index}`));
                relation_modal.show();
            } else if (modalType === 2) {
                let guardian_relation = new Modal(document.getElementById(`select-guardian-relation${props.index}`));
                guardian_relation.show();
            }
        };

    }, [showSuggestModal]);

    useEffect(() => {
        setNameError("");
    }, [name]);

    useEffect(() => {
        setAllocationError("");
    }, [allocation]);

    useEffect(() => {
        setDobError("");
    }, [dob]);

    useEffect(() => {
        setRelationError("");
    }, [selectedRelation])

    useEffect(() => {
        setGuardianNameError("");
    }, [guardianName]);

    useEffect(() => {
        setGuardianRelationError("");
    }, [selectedGuardianRelation]);

    useEffect(() => {
        setPanError("");
    }, [pan]);

    useEffect(() => {
        if (name == "") {
            setNameError(props.error?.name_error);
        };

        if (selectedRelation.length === 0) {
            setRelationError(props.error?.relation_id_error);
        };

        if (allocation == "") {
            setAllocationError(props.error?.allocation_error);
        }

        if (dob == "") {
            setDobError(props.error?.dob_error);
        }

        if (props.error && IS_MINOR) {
            if (guardianName == "") {
                setGuardianNameError(props.error.guardian_name_error);
            };

            if (selectedGuardianRelation.length === 0) {
                setGuardianRelationError(props.error.guardian_relationship_id_error);
            };

            if (pan === "") {
                setPanError(props.error.guardian_pan_error);
            }

        };

    }, [props, IS_MINOR]);

    useEffect(() => {
        if (props.data) {
            setRelationData(props.data);
        }
    }, [props.data]);

    useEffect(() => {
        if (props.fetchNominee === true) {
            let nominee = {
                "name": name.length !== 0 ? name : null,
                "relation_id": selectedRelation.length !== 0 ? selectedRelation.id : null,
                "dob": dob.length !== 0 ? format(dob, "dd-MM-yyyy") : null,
                "allocation": allocation.length !== 0 ? Number(allocation) : null,
                "guardian_name": IS_MINOR && guardianName.length !== 0 && IS_MINOR ? guardianName : null,
                "guardian_relationship_id": IS_MINOR && selectedGuardianRelation.id ? selectedGuardianRelation.id : null,
                "guardian_pan": IS_MINOR && pan.length !== 0 ? pan : null
            };

            props.getNomineeData(nominee, props.index);
        }

    }, [props.fetchNominee]);



    //handle nominee name change
    const _handleNameChange = (value) => {
        setName(value);
    };

    //handle select relation
    const _handleSelectRelation = (value) => {
        setSelectedRelation(value);
    };

    const _handleAllocationChange = (value) => {
        let allowed_numbers = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", ""];
        // Preventing user from typing other than numbers
        if (value.length <= 3 && allowed_numbers.includes(value.slice(-1))) {
            setAllocation(value);
        };

    };

    //handle change date
    const _handleDateChange = (date) => {
        setDob(date);
    };

    const _handleGuardianNameChange = (e) => setGuardianName(e)

    //handle modal show
    const _handleOccupationModal = (type) => {
        setShowSuggestModal(true);
        setModalType(type);
    };

    //handle select guardian relation
    const _handleSelectGuardianRelation = (value) => {
        setSelectedGuardianRelation(value);
    };

    const _handlePanChange = (value) => {
        if (value.length <= 10) {
            setPan(value.toUpperCase());
        };
    };

    return (
        <>

            {
                // initialStatus === false &&
                true &&
                <div>
                    {/* Nominee name */}
                    <div id={`name-${props.index}`} className='h-90 mb-2'>
                        <CustomTextInput label="Nominee name"
                            require
                            placeholder="John Doe"
                            type="text"
                            value={name}
                            errorMsg={nameError}
                            handleChange={_handleNameChange} />
                    </div>

                    {/* Relationship */}
                    <div id={`relation-${props.index}`}
                        className='h-90 position-relative mb-2'>
                        <CustomTextInput label="Relation"
                            require
                            className="cursor-pointer"
                            placeholder="Select relation"
                            dropdown={true}
                            type="text"
                            value={selectedRelation.name}
                            errorMsg={relationError}
                            modalOpen={showSuggestModal}
                            readOnly
                            onClick={() => _handleOccupationModal(1)} />
                    </div>

                    {/* allocation*/}
                    <div id={`allocation-${props.index}`}
                        className='h-90 mb-2'>
                        <CustomTextInput label="Allocation"
                            require
                            maxLength={3}
                            placeholder="Eg:50%"
                            type="text"
                            value={allocation}
                            errorMsg={allocationError}
                            handleChange={_handleAllocationChange} />
                    </div>

                    {/* Date picker */}
                    <div id={`dob-${props.index}`}>
                        <h6 className='mb-2 e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px'>
                            Date of birth
                            <span className="e-text-red">*</span>
                        </h6>
                        <DatePicker value={dob}
                            activeDate={subDays(new Date(), 1)}
                            error={dobError}
                            maxDate={subDays(new Date(), 1)}
                            onChange={_handleDateChange} />
                    </div>

                    {/* Checking the nominee is a minor */}

                    {
                        IS_MINOR &&
                        <div className='pt-3'>
                            {/* Nominee name */}
                            <div id={`guardian-name-${props.index}`} className='h-90 mb-2'>
                                <CustomTextInput label="Guardian name"
                                    require
                                    placeholder="John Doe"
                                    type="text"
                                    value={guardianName}
                                    errorMsg={guardianNameError}
                                    handleChange={_handleGuardianNameChange} />
                            </div>

                            {/*Guardian Relationship */}
                            <div id={`guardian-relation-${props.index}`}
                                className='h-90 position-relative mb-2'>
                                <CustomTextInput label="Relation"
                                    require
                                    placeholder="Select relation"
                                    className="cursor-pointer"
                                    type="text"
                                    dropdown={true}
                                    value={selectedGuardianRelation.name}
                                    errorMsg={guardianRelationError}
                                    modalOpen={showSuggestModal}
                                    readOnly
                                    onClick={() => _handleOccupationModal(2)} />
                            </div>

                            {/* guardian pan */}
                            <div id={`guardian-pan-${props.index}`}
                                className='mb-2'>
                                <CustomTextInput label="Enter your guardian PAN"
                                    require
                                    placeholder="AFDSHJK890"
                                    type="text"
                                    value={pan}
                                    maxLength={10}
                                    errorMsg={panError}
                                    handleChange={_handlePanChange} />
                            </div>
                        </div>
                    }

                </div >
            }

            {/* relation modal */}
            {
                (showSuggestModal == true) &&
                <SelectionSuggestModal title="Choose a relation"
                    data={relationData.kyc_relationship}
                    type={props.index}
                    id={`select-suggest-modal${props.index}`}
                    selectedOption={selectedRelation}
                    onSelectOption={_handleSelectRelation}
                    onModalClose={() => setShowSuggestModal(false)} />
            }

            {/* relation modal */}

            {
                (showSuggestModal == true) &&
                <SelectionSuggestModal title="Choose a guardian relation*"
                    data={relationData.nominee_minor_relation}
                    type={2}
                    id={`select-guardian-relation${props.index}`}
                    selectedOption={selectedGuardianRelation}
                    onSelectOption={_handleSelectGuardianRelation}
                    onModalClose={() => setShowSuggestModal(false)} />
            }
        </>
    )

}
export default memo(NomineeCard);