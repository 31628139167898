/*
 *   File : verify-email.js
 *   Author : https://evoqins.com
 *   Description : verify email and otp modal
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { memo, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

//custom component
import { PrimaryBtn, SecondaryBtn } from '../Cta';
import { CustomTextInput, OTPInput } from '../Form-Elements';
import service from '../../Services/service';
import { _closeModal } from '../../Helper/function';

const VerifyEmailModal = (props) => {

    const inputRef = useRef(null);

    const [email, setEmail] = useState(props.email);
    const [emailError, setEmailError] = useState("");
    const [verifyToggle, setVerifyToggle] = useState(props.verify ? true : false);
    const [otpValue, setOtpValue] = useState("");
    const [otpError, setOtpError] = useState("");
    const [focusedInput, setFocusedInput] = useState(0);
    const [otp, setOtp] = useState(['', '', '', '']); // Initialize an array for otp
    const [timer, setTimer] = useState(30);
    const [loading, setLoading] = useState(false);

    // Closing modal when "ESC" pressed
    useEffect(() => {
        _closeModal(props.onCloseModal);
    }, []);


    //for timer
    useEffect(() => {
        if (verifyToggle === true && timer > 0) {
            // Start the timer when requestOtpSuccess becomes true
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            // Clear the timer when the component unmounts or when the timer reaches 0
            return () => {
                clearInterval(countdown);
            };
        }
    }, [verifyToggle, timer]);

    //handle email change
    const _handleEmailChange = (value) => {
        setEmail(value.toLowerCase());
        setEmailError("");
    };

    //handle verify otp
    const _handleGetOTP = () => {
        // email validation 
        let validate_email = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;

        if (validate_email.test(email)) {
            setTimer(30);
            setOtpValue("");
            setOtpError("");
            setLoading(true);
            _sendOTP();
        } else {
            setEmailError("Please enter a valid email id");
        }
    }

    //handle confirm pin
    const _handleChangeOTP = (value) => {
        setOtpValue(value);
        setOtpError("");
    };

    // handle OTP resend
    const _handleOTPResend = (value, position) => {
        const newOTP = [...otp];
        newOTP[position] = value;
        setOtp(newOTP);
        setTimer(30);
        setOtpValue("");
        let resend = true; //resend otp
        _sendOTP(resend);
    };

    //handle verify otp
    const _handleVerifyOTP = () => {
        if (otpValue.length === 0) {
            setOtpError("Please enter a valid OTP.");
        } else if (otpValue.length !== 4) {
            setOtpError("Please enter the full OTP");
        }
        else {
            setLoading(true);
            _verifyOTP();
        }
    }

    //API- send OTP
    const _sendOTP = (resend) => {

        let type = resend ? "RESEND" : null;
        let is_post = true;
        let url = '/kyc/send-otp';
        let data = {
            "email": email,
            "type": type,
            "customer_id": props.customerId
        }

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                if (type === "RESEND") {
                    setFocusedInput(2);
                    toast.dismiss();
                    toast.success("OTP sent successfully to registered email");
                } else {
                    setVerifyToggle(true);
                };

            } else {
                toast.dismiss();
                toast.error(response.data.message);
            }

            setLoading(false);
        })
    }

    //API- verify OTP
    const _verifyOTP = () => {
        let is_post = true;
        let url = '/kyc/verify-email-otp';
        let data = {
            "email_otp": otpValue,
            "customer_id": props.customerId
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                toast.dismiss();
                if (props.edit === true) {
                    toast.success("Email updated successfully");
                } else {
                    toast.success("Email verified successfully");
                };
                let close_btn = document.getElementById("verify-email-modal-close");
                close_btn.click();
                props.onSuccess(otpValue, email);

            } else {
                toast.dismiss();
                toast.error(response.data.message);
            }

            setLoading(false);
        })
    }

    return (
        <div className={`modal fade`}
            id="verify-email-modal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="verifyEmailModalLabel"
            aria-hidden="true">
            <div className={`modal-dialog  modal-dialog-centered`}>
                <div className="modal-content e-border-radius-8 p-0">
                    <div className="modal-header justify-content-space-between pt-4  px-4 pb-0 border-0">
                        <h6 className='mb-0 e-text-chinese-black e-font-18 e-font-manrope e-font-weight-600 line-height-32px'>
                            {props.edit ? "Edit email" : "Verify email"}
                        </h6>
                        <button type="button"
                            id="verify-email-modal-close"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={props.onCloseModal}></button>
                    </div>
                    <div className="modal-body pb-4 px-4 ">
                        {
                            verifyToggle === false ?
                                <>
                                    {
                                        props.edit === false &&
                                        <p className='mb-2 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-500 line-height-24px '>
                                            Email verification is required to receive updates on the order status.
                                        </p>
                                    }
                                    <div id='email'>
                                        <CustomTextInput label="Email"
                                            require
                                            type="text"
                                            placeholder="john@gmail.com"
                                            value={email}
                                            errorMsg={emailError}
                                            handleChange={_handleEmailChange} />
                                    </div>
                                    {/* custom buttons */}
                                    <div className='row mt-3 justify-content-end'>
                                        <div className='col-4'>
                                            <SecondaryBtn name="Back"
                                                className="w-100 h-100 py-2"
                                                cancel="modal"
                                                handleClick={() => {
                                                    props.onCloseModal();
                                                }} />
                                        </div>
                                        <div className='col-4'>
                                            <PrimaryBtn name="Verify OTP"
                                                loading={loading}
                                                disabled={loading}
                                                className="w-100 h-40 d-flex justify-content-center"
                                                handleClick={_handleGetOTP} />
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='pb-4'>
                                        <label className="e-text-chinese-black e-font-14 e-font-manrope e-font-weight-500 line-height-24px">
                                            OTP received on Email*
                                        </label>
                                        <div className='h-64'>
                                            <OTPInput ref={inputRef}
                                                id="otp"
                                                autoFocus={true}
                                                value={otpValue}
                                                spellCheck="false"
                                                focused_input={focusedInput}
                                                error={otpError}
                                                onFocus={() => setFocusedInput(2)}
                                                onBlur={() => setFocusedInput(0)}
                                                onChange={_handleChangeOTP} />
                                        </div>
                                        {/* timer */}
                                        <div className='d-flex pt-1 align-items-center'>
                                            <button className={`${timer !== 0 && " e-disable"} ps-0 border-0 bg-transparent e-text-egyptian-blue e-font-12 e-font-manrope e-font-weight-500 line-height-26px`}
                                                onClick={_handleOTPResend} >
                                                Re-send OTP
                                            </button>
                                            {timer !== 0 &&
                                                <p className='mb-0 e-text-chinese-black e-font-12 e-font-manrope e-font-weight-600 line-height-26px'>
                                                    in {timer} {timer == 1 ? "second" : "seconds"}
                                                </p>
                                            }
                                        </div>
                                    </div>
                                    {/* custom buttons */}
                                    <div className='row pt-2 justify-content-end'>
                                        <div className='col-4'>
                                            <SecondaryBtn name="Back"
                                                className="w-100 h-100 py-2"
                                                handleClick={() => {
                                                    setVerifyToggle(false);
                                                }} />
                                        </div>
                                        <div className='col-4'>
                                            <PrimaryBtn name="Continue"
                                                loading={loading}
                                                disabled={loading}
                                                className="w-100 h-40 d-flex justify-content-center"
                                                handleClick={_handleVerifyOTP} />
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(VerifyEmailModal);