/*
 *   File :digilocker.js
 *   Author : https://evoqins.com
 *   Description :Digilocker page
 *   Integrations : react-redux,react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';

//custom components
import { PrimaryBtn } from '../../../Components/Cta';
import { AadhaarFetchStatusModal } from '../../../Components/Modal';
import { Loader } from '../../../Components/Loader';
import service from '../../../Services/service';
import { _getSignupProgress } from '../../../Helper';

// Assets
import LoaderImg from '../../../Assets/Images/Loader/btn-loader-blue.gif';


const Digilocker = (props) => {

    const [customerId, setCustomerId] = useState(props.customerId);
    const [progressData, setProgressData] = useState("");
    const [aadhaarFetchUrl, setAadhaarFetchUrl] = useState("");
    const [fetchResponse, setFetchResponse] = useState(false);

    const [loader, setLoader] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [showAadhaarFetchModal, setShowAadhaarFetchModal] = useState(false);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
    }, []);

    useEffect(() => {
        setCustomerId(props.customerId);
    }, [props.customerId]);

    useEffect(() => {
        if (customerId) {
            setLoadingData(true);
            _getSignupProgress(customerId).then(response => {
                if (response.status_code === 200) {
                    setProgressData(response.data);
                } else {
                    setProgressData("");
                }
            });
        }
    }, [customerId]);

    useEffect(() => {
        if (progressData) {
            if (progressData.is_identity_verified === true) {
                if (progressData.kyc_data.status_1 === "ADDRESS_PROOF" || progressData.current_step >= 3 || progressData.kyc_user === true) {
                    props.onUpdateStep(4, customerId);
                }
            }
            setLoadingData(false);
        }
    }, [progressData]);

    // Open phone verify modal
    useEffect(() => {
        if (showAadhaarFetchModal) {
            new Modal(document.querySelector("#aadhaar-fetch-status-modal")).show();
        }
    }, [showAadhaarFetchModal]);

    // handle fetch 
    const _handleFetch = () => {
        setLoader(true);
        _getAadhaarFetchUrl();
    };

    // handle save and continue 
    const _handleSave = () => {
        props.onUpdateStep(4, customerId);
    };

    // handle close modal
    const _handleCloseModal = () => {
        setShowAadhaarFetchModal(false);
        setLoader(false);
    };

    // handle aadhaar fetch success
    const _handleFetchSuccess = () => {
        setShowAadhaarFetchModal(false);
        setLoader(false);
        setFetchResponse(true);
    };

    //API - Fetch aadhaar details from DigiLocker
    const _getAadhaarFetchUrl = () => {

        let is_post = true;
        let url = '/kyc/get-aadhaar';
        let data = {
            "customer_id": customerId
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200 && response.data.redirect_url !== null) {
                setAadhaarFetchUrl(response.data.redirect_url);
                setShowAadhaarFetchModal(true);
            } else {
                if (response.data.status === "SUCCESSFUL") {
                    setFetchResponse(true)
                } else {
                    toast.dismiss();
                    toast.error(response.message, { className: "e-toast", });
                }
            };
            setLoader(false);
        });
    };

    return (
        <>
            {
                loadingData === false ?
                    <div className='px-0 pb-4'>
                        <div className='col-xl-6 col-lg-7 col-sm-10 col-12'>
                            <div className='padding-26px-top'>
                                <img src={require("../../../Assets/Images/Kyc/left-arrow.svg").default}
                                    draggable={false}
                                    className='cursor-pointer'
                                    alt='arrow'
                                    onClick={() => props.onUpdateStep(2)} />
                            </div>

                            {/* step flow */}
                            <div className='position-relative'>
                                <div className='e-bg-lavender h-6 mt-4 e-border-radius-16'></div>
                                <div className='position-absolute h-6 e-bg-header-bg e-border-radius-16 top-0'
                                    style={{ width: `${(100 / progressData.step_detail.length) * 3}%` }}></div>
                                <p className='mb-0 text-end pt-1 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-400 line-height-24px'>
                                    Step 3 of {progressData.step_detail.length}
                                </p>
                            </div>

                            {/* Personal details */}
                            <div className=' pt-3'>
                                <h3 className='e-text-chinese-black e-font-18 e-font-manrope e-font-weight-600 line-height-24px'>
                                    Aadhaar fetching
                                </h3>
                                <div className='row pt-4'>
                                    <div className='col-lg-8 col-md-6 col-sm-9 col-12'>
                                        <div className='e-bg-cultured p-4 e-border-radius-16'>
                                            <label className='e-text-chinese-black e-font-weight-500 e-font-manrope line-height-24px e-font-14'>
                                                Fetch Aadhaar from Digilocker*
                                            </label>

                                            <div className={`${loader ? 'pe-none' : ''} ${fetchResponse === true ? 'e-border-1px-sonic-silver pe-none' : 'e-bg-lavender-blue-dark e-border-1px-egyptian-blue'}  mt-3 mb-2 text-center e-border-radius-24 e-padding-tb-12px cursor-pointer`}
                                                onClick={_handleFetch} >
                                                {loader ?
                                                    <img src={LoaderImg}
                                                        width={20}
                                                        height={20}
                                                        className='object-fit-contain my-1'
                                                        draggable="false"
                                                        alt='loader' />
                                                    :
                                                    <img src={require("../../../Assets/Images/Kyc/digilocker.webp")}
                                                        draggable={false}
                                                        width={114}
                                                        height={28}
                                                        className=''
                                                        alt='digilocker' />
                                                }
                                            </div>

                                            {fetchResponse === true &&
                                                <>
                                                    <span className='e-text-go-green e-font-14 e-font-manrope e-font-weight-500 line-height-14px'>
                                                        Aadhaar fetched successfully
                                                    </span>
                                                    <img src={require("../../../Assets/Images/Kyc/success-icon.svg").default}
                                                        draggable={false}
                                                        className='ps-2'
                                                        alt='success' />

                                                    <div className='row padding-40px-top'>
                                                        <div className='col-auto'>
                                                            <PrimaryBtn name={"Save & Continue"}
                                                                className="w-100 e-padding-tb-12px px-4"
                                                                handleClick={_handleSave} />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            showAadhaarFetchModal &&
                            <AadhaarFetchStatusModal aadhaarFetchUrl={aadhaarFetchUrl}
                                customerId={customerId}
                                email={progressData.kyc_data.email}
                                phone={progressData.kyc_data.mobile_country_code + progressData.kyc_data.mobile}
                                onCloseModal={_handleCloseModal}
                                onSuccess={_handleFetchSuccess} />
                        }

                    </div>
                    :
                    <div className="min-h-100vh text-center pt-5" >
                        <Loader />
                    </div>

            }
        </>
    )
}

export default Digilocker;
