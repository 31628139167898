/*
 *   File : confirm-delete-user.js
 *   Author : https://evoqins.com
 *   Description : Modal to confirm and delete user 
 *   Integrations : react-toastify
 *   Version : 1.0.0
 *   Date : 23-08-2024
*/

import React, { memo, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { _closeModal } from '../../Helper/function';

//custom component
import { PrimaryBtn, SecondaryBtn } from '../Cta';
import service from '../../Services/service';

const ConfirmDeleteUser = (props) => {

    const [loading, setLoading] = useState(false);

    // Closing modal when "ESC" pressed
    useEffect(() => {
        _closeModal(props.onCloseModal);
    }, []);

    //handle remove member
    const _handleDeleteUser = () => {
        setLoading(true);
        _deleteUser();
    };


    //======= API CALLS =======//

    //API- Remove family member
    const _deleteUser = () => {
        let is_post = true;
        let url = '/user/delete-customer';
        let data = {
            "customer_id": props.userId
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("User deleted successfully");
                let close_btn = document.getElementById("confirm-user-delete-modal-close");
                close_btn.click();
                props.handleSuccess();
            } else {
                toast.dismiss();
                toast.error(response.data.message);
            }

            setLoading(false);
        })
    };

    return (
        <div className={`modal fade`}
            id="confirm-user-delete-modal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="Confirm unlink member modal"
            aria-hidden="true">
            <div className={`modal-dialog  modal-dialog-centered`}>
                <div className="modal-content e-border-radius-8 p-3 mx-sm-5">
                    <div className="modal-header justify-content-between p-0 border-0 mb-2">
                        <h6 className='e-text-chinese-black e-font-16 e-font-manrope e-font-weight-700 line-height-32px'>
                            Confirm
                        </h6>
                        <button type="button"
                            id="confirm-user-delete-modal-close"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={props.onCloseModal}></button>
                    </div>
                    <div className="modal-body p-0">
                        <p className='mb-2 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-500 line-height-18px pe-4'>
                            The user will be permanently deleted. This action cannot be undone.
                        </p>
                        <div className='row margin-top-32px justify-content-end'>
                            <div className='col-4'>
                                <SecondaryBtn name="No, cancel"
                                    className="w-100 h-100 py-2 px-0"
                                    cancel="modal"
                                    handleClick={props.onCloseModal} />
                            </div>
                            <div className='col-4 text-center'>
                                <PrimaryBtn name="Delete user"
                                    loading={loading}
                                    disabled={loading}
                                    className="d-flex justify-content-center w-100 h-40 px-0"
                                    handleClick={_handleDeleteUser} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default memo(ConfirmDeleteUser);