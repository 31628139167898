/*
 *   File : add-address.js
 *   Author : https://evoqins.com
 *   Description : Add adress page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/


import React, { useEffect, useState } from 'react';
import { Modal } from 'bootstrap';

//custom component
import { SelectionSuggestModal } from '../../../Components/Modal';
import { CustomTextInput } from '../../../Components/Form-Elements';
import { _checkFileSize, _formatDateFormat, _getSignupProgress } from '../../../Helper';
import { PrimaryBtn } from '../../../Components/Cta';
import { toast } from 'react-toastify';
import { Loader } from '../../../Components/Loader';
import service from '../../../Services/service';

const AddAddress = (props) => {

    //get general data from redux
    const [progressData, setProgressData] = useState("");
    const [customerId, setCustomerId] = useState(props.customerId);

    const [address, setAddress] = useState("");
    const [addressError, setAddressError] = useState("");
    const [secondAddress, setSecondAddress] = useState("");
    const [secondAddressError, setSecondAddressError] = useState("");
    const [selectedState, setSelectedState] = useState([]);
    const [stateError, setStateError] = useState("");
    const [selectedCity, setSelectedCity] = useState([]);
    const [cityError, setCityError] = useState("");
    const [countryData, setCountryData] = useState(props.country);
    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [pinCode, setPinCode] = useState("");
    const [pinCodeError, setPinCodeError] = useState("");

    const [correspondAddress, setCorrespondAddress] = useState(true);
    const [loading, setLoading] = useState(false); // save button
    const [loadingData, setLoadingData] = useState(true); // API Data loader 

    //correspond
    const [correspondNation, setCorrespondNation] = useState([]);
    const [correspondNationError, setCorrespondNationError] = useState("");
    const [correspondAddressOne, setCorrespondAddressOne] = useState("");
    const [correspondAddressError, setCorrespondAddressError] = useState("");
    const [correspondSecondAddress, setCorrespondSecondAddress] = useState("");
    const [correspondSecondAddressError, setCorrespondSecondAddressError] = useState("");
    const [correspondState, setCorrespondState] = useState([]);
    const [correspondStateError, setCorrespondStateError] = useState("");
    const [correspondCity, setCorrespondCity] = useState("");
    const [correspondCityError, setCorrespondCityError] = useState("");
    const [correspondPinCode, setCorrespondPinCode] = useState("");
    const [correspondPinCodeError, setCorrespondPinCodeError] = useState("");

    const [showSuggestModal, setShowSuggestModal] = useState(false);
    const [modalType, setModalType] = useState("");

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });

        // API call - get state data
        _getState();

    }, []);

    useEffect(() => {
        setCustomerId(props.customerId);
    }, [props.customerId]);

    useEffect(() => {
        if (customerId) {
            setLoadingData(true);
            // API call
            _getSignupProgress(customerId).then(response => {
                if (response.status_code === 200) {
                    setProgressData(response.data);
                } else {
                    setProgressData("");
                }
            });
        }
    }, [customerId]);

    useEffect(() => {

        if (progressData) {

            if (progressData.kyc_data.address["permanent_address"] !== undefined) {

                if (progressData.kyc_data.address.permanent_address["address_line1"] !== undefined) {
                    setAddress(progressData.kyc_data.address.permanent_address.address_line1);
                    setSecondAddress(progressData.kyc_data.address.permanent_address.address_line2);
                    setSelectedState({ id: progressData.kyc_data.address.permanent_address.state_id, name: progressData.kyc_data.address.permanent_address.state });
                    setSelectedCity({ id: progressData.kyc_data.address.permanent_address.city_id, name: progressData.kyc_data.address.permanent_address.city });
                    setPinCode(progressData.kyc_data.address.permanent_address.pincode);
                }

            };

            if (progressData.kyc_data.address['corresponding_address'] !== undefined) {

                if (progressData.kyc_data.address.corresponding_address["address_line1"]) {
                    setCorrespondAddressOne(progressData.kyc_data.address.corresponding_address?.address_line1);
                    setCorrespondSecondAddress(progressData.kyc_data.address.corresponding_address.address_line2);
                    setCorrespondNation({ id: progressData.kyc_data.address.corresponding_address.country_id, name: progressData.kyc_data.address.corresponding_address.country });
                    setCorrespondState(progressData.kyc_data.address.corresponding_address.state);
                    setCorrespondCity(progressData.kyc_data.address.corresponding_address.city);
                    setCorrespondPinCode(progressData.kyc_data.address.corresponding_address.pincode);
                } else {
                    setCorrespondNation({
                        id: progressData.kyc_data.tax_country_id,
                        name: progressData.kyc_data.tax_country
                    });
                }

            } else {
                setCorrespondNation({
                    id: progressData.kyc_data.tax_country_id,
                    name: progressData.kyc_data.tax_country
                });
            }

            setCorrespondAddress(progressData.kyc_data.is_indian_tax_payer ? true : false);
            setLoadingData(false);
        };

    }, [progressData]);

    //Modal
    useEffect(() => {

        if (showSuggestModal === true) {
            if (modalType === 1) {
                let state_modal = new Modal(document.getElementById('select-state-modal'));
                state_modal.show();
            } else if (modalType === 2) {
                let city_modal = new Modal(document.getElementById('select-city-modal'));
                city_modal.show();
            } else if (modalType === 3) {
                let city_modal = new Modal(document.getElementById('select-correspond-country-modal'));
                city_modal.show();
            }
        };

    }, [showSuggestModal])

    useEffect(() => {
        setAddressError("");
    }, [address]);

    useEffect(() => {
        setSecondAddressError("");
    }, [secondAddress]);

    useEffect(() => {
        setStateError("");
    }, [selectedState]);

    useEffect(() => {
        setCityError("");
    }, [selectedCity]);

    useEffect(() => {
        setPinCodeError("");
    }, [pinCode]);

    //correspond error
    useEffect(() => {
        setCorrespondNationError("");
    }, [correspondNation]);

    useEffect(() => {
        setCorrespondAddressError("");
    }, [correspondAddressOne]);

    useEffect(() => {
        setCorrespondSecondAddressError("");
    }, [correspondSecondAddress]);

    useEffect(() => {
        setCorrespondStateError("");
    }, [correspondState]);

    useEffect(() => {
        setCorrespondCityError("");
    }, [correspondCity]);

    useEffect(() => {
        setCorrespondPinCodeError("");
    }, [correspondPinCode]);

    useEffect(() => {
        setCountryData(props.country);
    }, [props.country]);

    useEffect(() => {
        if (selectedState.name) {
            // API call
            _getCity();
        }
    }, [selectedState]);

    //handle modal
    const _handleSuggestionModal = (type) => {
        setModalType(type);
        setShowSuggestModal(true);
    };

    //handle address change 
    const _handleAddressChange = (value) => {
        setAddress(() => value);
    };

    //handle select state
    const _handleSelectState = (value) => {
        setSelectedState(value);
        // clearing city when state changes
        setSelectedCity({ id: null, name: "" });
    }

    //handle select city
    const _handleSelectCity = (value) => {
        setSelectedCity(value);
    }

    const _handlePinChange = (value) => {
        setPinCode(value);
    };

    // correspond handler 
    //handle select correspond nation
    const _handleCorrespondNation = (value) => {
        setCorrespondNation(value);
        setCorrespondAddressError("");
        setCorrespondAddressOne("");
        setCorrespondSecondAddress("");
        setCorrespondSecondAddressError("");
        setCorrespondState("");
        setCorrespondCity("");
        setCorrespondPinCode("");
        setCorrespondStateError("");
        setCorrespondCityError("");
        setCorrespondPinCodeError("");
    };

    const _handleCorrPinCodeChange = (value) => {
        setCorrespondPinCode(value);
    };

    //handle submit data
    const _handleSubmit = () => {

        let errors = [];
        let validation = true;

        if (progressData.kyc_user) {
            if (address.length === 0) {
                setAddressError("Please provide your address details");
                errors.push("address-1");
                validation = false;
            }
            if (secondAddress.length === 0) {
                setSecondAddressError("Please provide your additional address details");
                errors.push("address-2");
                validation = false;
            }

            if (selectedState.length === 0) {
                setStateError("Please provide your state name");
                errors.push("state");
                validation = false;
            }

            if (selectedCity.name.length === 0) {
                setCityError("Please provide your city name");
                errors.push("city");
                validation = false;
            }

            if (pinCode.length === 0 || pinCode.length < 6) {
                setPinCodeError(pinCode.length === 0 ? "Provide your PIN code" : "Enter full pincode");
                errors.push("pincode");
                validation = false;
            }


        }

        if (progressData.kyc_data.is_indian_tax_payer === false) {
            if (correspondNation.length === 0) {
                setCorrespondNationError("Please provide your nationality information");
                errors.push("corrs-nationality");
                validation = false;
            }
            if (correspondAddressOne.length === 0) {
                setCorrespondAddressError("Please provide your address details")
                errors.push("corrs-address-1");
                validation = false;
            }

            if (correspondSecondAddress.length === 0) {
                setCorrespondSecondAddressError("Please provide your additional address details");
                errors.push("corrs-address-2");
                validation = false;
            }

            if (correspondState.length === 0) {
                setCorrespondStateError("Please provide your correspond state");
                errors.push("corrs-state");
                validation = false;
            }

            if (correspondCity.length === 0) {
                setCorrespondCityError("Please provide your correspond city");
                errors.push("corrs-city");
                validation = false;
            }

            if (correspondPinCode.length === 0 || correspondPinCode.length < 6) {
                setCorrespondPinCodeError(correspondPinCode.length === 0 ? "Please provide your ZIP code" : "Please provide your full ZIP code");
                errors.push("corrs-pincode");
                validation = false;
            }
        };

        // Scrolling to first error
        if (errors.length !== 0) {
            let first_err_id = errors[0];
            let element = document.getElementById(first_err_id);
            element.scrollIntoView({ behavior: "smooth" });
        };

        // Saving details
        if (validation === true) {
            _saveAddress();
            setLoading(true);
        };

    };

    //API- Get state
    const _getState = () => {

        let is_post = false;
        let url = '/general/states';
        let data = null;

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setStateData(response.data);
            } else {
                setStateData([]);
            }

        });
    };

    //API- Post City
    const _getCity = () => {

        let is_post = true;
        let url = '/general/cities';
        let data = {
            "state_id": selectedState ? selectedState.id : null,
        };
        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setCityData(response.data);
            } else {
                setCityData([]);
            }
        });
    };

    //API- Post save address
    const _saveAddress = () => {

        let is_india_tax_payer = progressData.kyc_data.is_indian_tax_payer;
        let is_post = true;
        let url = '/kyc/save-address';
        let data = {
            "customer_id": props.customerId,
            "address": {
                "permanent_address": {
                    "address_line1": address,
                    "address_line2": secondAddress || "",
                    "pincode": pinCode,
                    "country_id": 251, /// permanent address always india
                    "city": selectedCity.name,
                    "state_name": selectedState.name,
                    "state_id": selectedState.id,
                    "city_id": selectedCity.id
                },
                "corresponding_address": is_india_tax_payer == true ? ({}) : ({
                    "address_line1": correspondAddressOne,
                    "address_line2": correspondSecondAddress,
                    "pincode": correspondPinCode,
                    "country_id": correspondNation.id,
                    "city": correspondCity || null,
                    "state_name": correspondState || null,
                    "state_id": correspondState.id || null,
                    "city_id": correspondCity.id || null
                })
            },
            "is_same_corresponding_address": correspondAddress,
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("Address details saved successfully");
                props.onUpdateStep(5, customerId);
            } else {
                toast.dismiss();
                toast.error(response.data.message);
            }

            setLoading(false);
        });
    };

    return (

        <>
            {
                loadingData == false ? (
                    <div className='px-0 pb-4'>
                        <div className='col-xl-6 col-lg-7 col-sm-10 col-12'>
                            <div className='padding-26px-top'>
                                <img src={require("../../../Assets/Images/Kyc/left-arrow.svg").default}
                                    draggable={false}
                                    className='cursor-pointer'
                                    alt='arrow'
                                    onClick={() => props.onUpdateStep(2)} />
                            </div>
                            {/* step flow */}
                            <div className='position-relative'>
                                <div className='e-bg-lavender h-6 mt-4 e-border-radius-16'></div>
                                <div className='position-absolute h-6 e-bg-header-bg e-border-radius-16 top-0'
                                    style={{ width: `${(100 / progressData.step_detail.length) * (progressData.kyc_data.is_kyc_compliant ? 3 : 4)}%` }}></div>
                                <p className='mb-0 text-end pt-1 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-400 line-height-24px'>
                                    Step {progressData.kyc_data.is_kyc_compliant ? 3 : 4} of {progressData.step_detail.length}
                                </p>
                            </div>

                            {/* Address details section */}
                            <div className='pt-3'>
                                <h3 className='mb-4 e-text-charleston-green e-font-18  e-font-manrope e-font-weight-600 line-height-24px'>
                                    Address details
                                </h3>

                                <h3 className='mb-2 e-text-charleston-green e-font-16  e-font-manrope e-font-weight-500 line-height-24px'>
                                    Permanent address*
                                    {
                                        progressData.kyc_user === false &&
                                        <span className='e-font-14'>
                                            (as per Aadhaar)
                                        </span>

                                    }
                                </h3>

                                {/* Address details */}
                                {
                                    progressData.kyc_user === false ?
                                        <div className='col mb-3'>
                                            <div className="e-font-14 e-text-charleston-green e-font-weight-400 e-font-manrope line-height-22px e-bg-cultured e-border-radius-8 p-3 border">
                                                {address}, <br />
                                                {selectedCity.name}, <br />
                                                {selectedState.name} - {pinCode}.
                                            </div>
                                        </div>
                                        :
                                        <>
                                            {/* Nationality select */}
                                            <div id='nationality'
                                                className='h-90 position-relative mb-2'>
                                                <CustomTextInput label="Nationality"
                                                    require
                                                    placeholder="India"
                                                    type="text"
                                                    value={"India"}
                                                    disabled
                                                    readOnly />
                                            </div>

                                            {/* address line 1 */}
                                            <div id='address-1'
                                                className='h-90 mb-2'>
                                                <CustomTextInput label="Address line 1"
                                                    require
                                                    placeholder="202,Madhav Apts, jawar road......."
                                                    type="text"
                                                    value={address}
                                                    errorMsg={addressError}
                                                    handleChange={_handleAddressChange} />
                                            </div>

                                            {/* address line 2 */}
                                            <div id='address-2'
                                                className='h-90 mb-2'>
                                                <CustomTextInput label="Address line 2"
                                                    require
                                                    placeholder="202,Madhav Apts, jawar road......."
                                                    type="text"
                                                    value={secondAddress}
                                                    errorMsg={secondAddressError}
                                                    handleChange={(e) => {
                                                        setSecondAddress(e);
                                                    }} />
                                            </div>

                                            {/* state and city */}
                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <div id='state'
                                                        className='h-90 position-relative mb-2'>
                                                        <CustomTextInput label="State"
                                                            require
                                                            className="cursor-pointer"
                                                            placeholder="Kerala"
                                                            type="text"
                                                            value={selectedState.name}
                                                            errorMsg={stateError}
                                                            readOnly
                                                            onClick={() => _handleSuggestionModal(1)} />
                                                        <img src={require("../../../Assets/Images/Kyc/drop-down.svg").default}
                                                            draggable={false}
                                                            className='position-absolute end-0 top-46px me-3'
                                                            alt='icon' />
                                                    </div>
                                                </div>

                                                {/*select city*/}
                                                <div className='col-lg-6'>
                                                    <div id='city'
                                                        className={`${selectedState.name ? "" : "e-disable"} h-90 position-relative mb-2`}>
                                                        <CustomTextInput label="City"
                                                            require
                                                            className="cursor-pointer"
                                                            placeholder="Kannur"
                                                            type="text"
                                                            value={selectedCity.name}
                                                            errorMsg={cityError}
                                                            readOnly
                                                            onClick={() => _handleSuggestionModal(2)} />
                                                        <img src={require("../../../Assets/Images/Kyc/drop-down.svg").default}
                                                            draggable={false}
                                                            className='position-absolute end-0 top-46px me-3'
                                                            alt='icon' />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* pincode */}
                                            <div id='pincode'
                                                className='h-90 mb-2'>
                                                <CustomTextInput label="Pincode"
                                                    require
                                                    placeholder="670676"
                                                    type="number"
                                                    maxLength={6}
                                                    value={pinCode}
                                                    errorMsg={pinCodeError}
                                                    handleChange={_handlePinChange} />
                                            </div>



                                        </>
                                }

                                {
                                    progressData.kyc_data.is_indian_tax_payer === false &&
                                    <>
                                        <h6 className='mb-2 e-text-charleston-green e-font-16  e-font-manrope e-font-weight-500 line-height-24px'>
                                            Overseas address*
                                        </h6>
                                        {/* Nationality select */}
                                        <div id='corrs-nationality' className='h-90 position-relative mb-2'>
                                            <CustomTextInput label="Nationality"
                                                require
                                                placeholder="India"
                                                type="text"
                                                value={correspondNation.name}
                                                errorMsg={correspondNationError}
                                                readOnly
                                                className="cursor-pointer"
                                                onClick={() => _handleSuggestionModal(3)} />
                                            <img src={require("../../../Assets/Images/Kyc/drop-down.svg").default}
                                                draggable={false}
                                                className='position-absolute end-0 top-46px me-3'
                                                alt='icon' />
                                        </div>
                                        {/* address line 1 */}
                                        <div id='corrs-address-1' className='h-90 mb-2'>
                                            <CustomTextInput label="Address line 1"
                                                require
                                                placeholder="202,Madhav Apts, jawar road......."
                                                type="text"
                                                value={correspondAddressOne}
                                                errorMsg={correspondAddressError}
                                                handleChange={(e) => {
                                                    setCorrespondAddressOne(e);
                                                }} />
                                        </div>
                                        {/* address line 2 */}
                                        <div id='corrs-address-2' className='h-90 mb-2'>
                                            <CustomTextInput label="Address line 2"
                                                require
                                                placeholder="202,Madhav Apts, jawar road......."
                                                type="text"
                                                value={correspondSecondAddress}
                                                errorMsg={correspondSecondAddressError}
                                                handleChange={(e) => {
                                                    setCorrespondSecondAddress(e);
                                                }} />
                                        </div>
                                        {/* state and city */}
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <div id='corrs-state'
                                                    className='h-90 position-relative mb-2'>
                                                    <CustomTextInput label="State"
                                                        placeholder="New york"
                                                        require
                                                        type="text"
                                                        value={correspondState}
                                                        errorMsg={correspondStateError}
                                                        handleChange={(val) => setCorrespondState(val)} />
                                                </div>
                                            </div>

                                            {/*select city*/}
                                            <div className='col-lg-6'>
                                                <div id='corrs-city'
                                                    className={`${correspondState ? "" : "e-disable"} h-90 position-relative mb-2`}>
                                                    <CustomTextInput label="City"
                                                        require
                                                        placeholder="San Francisco"
                                                        type="text"
                                                        value={correspondCity}
                                                        errorMsg={correspondCityError}
                                                        handleChange={(val) => setCorrespondCity(val)} />
                                                </div>
                                            </div>
                                        </div>
                                        {/* zipcode */}
                                        <div id='corrs-pincode'
                                            className='h-90 mb-2'>
                                            <CustomTextInput label="Zip code"
                                                require
                                                placeholder="670676"
                                                type="number"
                                                value={correspondPinCode}
                                                errorMsg={correspondPinCodeError}
                                                handleChange={_handleCorrPinCodeChange} />
                                        </div>
                                    </>
                                }
                            </div>

                            <div className='pt-4 mt-2'>
                                <PrimaryBtn className={loading ? "e-padding-tb-12px w-40per" : "e-padding-tb-15px w-40per"}
                                    loading={loading}
                                    name="Save & Continue"
                                    handleClick={_handleSubmit} />
                            </div>
                        </div>
                    </div >
                ) : (
                    <div className="min-h-100vh text-center mt-5" >
                        <Loader />
                    </div>
                )}

            {/* State modal*/}
            {
                showSuggestModal && modalType === 1 &&
                <SelectionSuggestModal title="Choose a state"
                    data={stateData}
                    type={2}
                    id='select-state-modal'
                    search={true}
                    searchPlaceholder="Search state"
                    selectedOption={selectedState}
                    onSelectOption={_handleSelectState}
                    onModalClose={() => setShowSuggestModal(false)} />
            }

            {/* city modal */}
            {
                showSuggestModal && modalType === 2 &&
                <SelectionSuggestModal title="Choose a city"
                    data={cityData}
                    type={3}
                    id='select-city-modal'
                    search={true}
                    searchPlaceholder="Search city"
                    selectedOption={selectedCity}
                    onSelectOption={_handleSelectCity}
                    onModalClose={() => setShowSuggestModal(false)} />
            }

            {/* correspond Address */}

            {
                showSuggestModal && modalType === 3 &&
                <SelectionSuggestModal title="Choose a nationality"
                    data={countryData}
                    type={4}
                    search={true}
                    searchPlaceholder="Search country"
                    id='select-correspond-country-modal'
                    selectedOption={correspondNation}
                    onSelectOption={_handleCorrespondNation}
                    onModalClose={() => setShowSuggestModal(false)} />
            }

        </>
    )
}

export default AddAddress;