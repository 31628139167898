/*
 *   File : send-kyc-url.js
 *   Author : https://evoqins.com
 *   Description : Component to send KYC video URL to user
 *   Integrations :
 *   Version : 1.0.0
*/


import { memo, useEffect, useState } from "react";
import IcoMoon from "react-icomoon";
import iconSet from "../../Assets/Images/Icons/selection.json";
import { toast } from "react-toastify";

// Components
import { PrimaryBtn } from "../Cta";
import service from "../../Services/service";
import { _closeModal } from "../../Helper/function";

// Assets
import WhatsApp from "../../Assets/Images/General/whatsApp.png";

function SendKycUrl(props) {

    const [isCopied, setIsCopied] = useState(false);
    const [kycUrl, setKycUrl] = useState(props.url);
    const [loading, setLoading] = useState(false);

    // Closing modal when "ESC" pressed
    useEffect(() => {
        _closeModal(props.onClose);
    }, []);

    useEffect(() => {
        setKycUrl(props.url);
    }, [props.url]);

    const _handleCopyLink = () => {
        navigator.clipboard.writeText(kycUrl)
            .then(() => {
                setIsCopied(true);
            })
            .catch(() => {
                setIsCopied(false);
                toast.dismiss();
                toast.success("Failed to copy URL, try again");
            });

        // Allowing user to copy it again
        setTimeout(() => {
            setIsCopied(false);
        }, 5000);
    };

    const _handleShareLink = () => {
        window.open('https://web.whatsapp.com/send?phone=' + props.phone + `&text=${encodeURIComponent(kycUrl)}`, '_blank');
    };

    // API - send KYC notification to user
    const _sendKYCNotification = () => {

        setLoading(true);

        let is_post = true;
        let url = '/kyc/notify';
        let data = {
            "customer_id": props.customerId
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("Notification sent to the user successfully");
            } else {
                toast.dismiss();
                toast.success(response.data.message);
            };

            setLoading(false);

        });
    };

    return (
        <div className="modal fade"
            data-bs-backdrop="static"
            id="kyc-url-modal"
            tabIndex="-1"
            aria-labelledby="Kyc url modal"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content e-border-radius-8 p-4" >
                    <div className="modal-header align-items-center p-0">
                        <h6 className='mb-0 e-text-chinese-black e-font-16 e-font-manrope e-font-weight-700 line-height-20px'>
                            Share link and send email
                        </h6>
                        <button type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={props.onClose}></button>
                    </div>
                    <div className="modal-body px-0 pt-3 pb-0">
                        <p className='e-text-chinese-black e-font-14 e-font-manrope e-font-weight-500 line-height-18px'>
                            Copy the link below to share it through other mediums and send an email notification
                        </p>
                        <div className="d-flex flex-nowrap gap-3 e-bg-alice-blue  py-2 e-padding-lr-12px e-border-radius-8">
                            <p className='bg-white text-wrap flex-fill e-padding-all-12px e-border-radius-8 mb-0  e-text-chinese-black e-font-14 e-font-manrope e-font-weight-400 line-height-18px'>
                                {props.url}
                            </p>
                            <span className='text-end cursor-pointer col-auto d-flex align-items-center gap-2 e-text-primary e-font-14 e-font-manrope e-font-weight-600 line-height-18px'
                                onClick={_handleCopyLink}>
                                <IcoMoon iconSet={iconSet}
                                    icon={"copy-icon"}
                                    size={"1.125rem"} />
                                {isCopied ? "Copied" : "Copy link"}
                            </span>
                        </div>
                        <div className='text-end pt-4 d-flex gap-3 justify-content-end'>
                            <span className="e-text-chinese-black e-font-14 e-font-manrope e-font-weight-600 line-height-18px e-border-radius-8 e-border-otp px-3 cursor-pointer col-auto d-flex align-items-center gap-2 "
                                onClick={_handleShareLink}>
                                <img src={WhatsApp}
                                    alt="whatsApp icon"
                                    draggable="false"
                                    width={20}
                                    height={20} />
                                Share to WhatsApp
                            </span>
                            {/* <a href={`mailto:${props.email}`}> */}
                            <PrimaryBtn name="Send email"
                                handleClick={_sendKYCNotification}
                                loading={loading}
                                disabled={loading}
                                className="e-padding-tb-12px h-40 col-6 col-sm-4" />
                            {/* </a> */}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(SendKycUrl);