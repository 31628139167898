/*
 *   File : innerHeader.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Inner Header
 *   Integrations :
 *   Version : v1.0
 *   Created : 28-11-2023
 */

/*import packages*/
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

/*import components*/
import { ChangePassword } from '../Modal/index';
import { NotificationMenu } from './index';
import { Toast } from '../Note';

/*import styles*/
import styles from '../../Styles/Components/inner-header.module.scss';

/*import assets*/
import Notification from '../../Assets/Images/Navigation/notification.png';
import Menu from '../../Assets/Images/Navigation/menu.png';
import More from '../../Assets/Images/Navigation/more.png';
import Password from '../../Assets/Images/Navigation/password.png';
import Logout from '../../Assets/Images/Navigation/logout.png';

/* import services */
import service from '../../Services/service';
import devconfig from '../../Config/config';
import { GET_INVESTMENT_TYPES, GET_ORDER_STATUS, GET_PAYMENT_STATUS } from '../../Action/types';

export default function InnerHeader(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const wrapperRef = useRef(null);
    const wrapRef = useRef(null);
    const current = useLocation();

    const [notificationDown, setNotificationDown] = useState(true);
    const [logoutdDown, setLogoutdDown] = useState(false);
    const [header, setHeader] = useState();
    const [notificationData, setNotificationData] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        _filterData();
        //eslint-disable-next-line
    }, []);



    useEffect(() => {
        _getCurrentLocation();
        //eslint-disable-next-line
    }, [current]);

    useEffect(() => {
        document.addEventListener('mouseup', _closeMenu);
        return () => {
            document.removeEventListener('mouseup', _closeMenu);
        };
    }, []);

    useEffect(() => {

        window.addEventListener("click", (e) => {
            if (!e.target.closest("#notification")) {
                setNotificationDown(true);
            }
        });

        return window.removeEventListener("click", (e) => {
            if (!e.target.closest("#notification")) {
                setNotificationDown(true);
            }
        });

    }, []);

    const _getCurrentLocation = () => {
        var temp = '';
        //eslint-disable-next-line
        devconfig.sidebar.map((item, key) => {
            if (item.link === current.pathname) {
                return (
                    temp = item.header
                )
            }
        })
        setHeader(temp);
    };


    const _handleLogout = () => {

        localStorage.removeItem("access_token_ifsh_admin");
        dispatch({
            type: 'UPDATE_ACCESS_TOKEN',
            payload: null,
        });

    };

    /*---- filter data api --*/
    const _filterData = () => {
        let payment_options_temp = [];
        let status_options_temp = [];
        let investment_types = [];
        const url = '/order/filter-data';

        service(false, url).then((response) => {
            if (response.status_code === 200) {
                response.data.payment_status.map((item) => {
                    payment_options_temp.push({ value: item.status, label: item.name });
                    return true;
                });
                response.data.order_status.map((item) => {
                    status_options_temp.push({ value: item.status, label: item.name });
                    return true;
                });
                response.data.investment_types.map((item) => {
                    investment_types.push({ value: item.id, label: item.name });
                    return true;
                });
                dispatch({ type: GET_ORDER_STATUS, payload: status_options_temp });
                dispatch({ type: GET_PAYMENT_STATUS, payload: payment_options_temp });
                dispatch({ type: GET_INVESTMENT_TYPES, payload: investment_types });
                // setOrderStatusOptions(status_options_temp);
                // setPaymentStatusOptions(payment_options_temp);
            }
        });
    }

    const _closeMenu = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setLogoutdDown(false)
        }
    }

    return (
        <>
            <div className={`${styles.e_inner_header_wrap} `} id="header" >
                <div className="px-4">
                    <div className="row pt-4 ">
                        <div className="col col-lg-6 gx-0">
                            <img src={Menu} className={`${styles.e_menu}`} alt="Cashyu Menu" onClick={() => props.collapse()} />
                        </div>
                        <div className="col col-lg-6 text-end d-flex justify-content-end">
                            <div className='position-relative' id='notification'>
                                <img src={Notification}
                                    alt="Notification"
                                    draggable="false"
                                    className={`${styles.e_notification} px-2`}
                                    onClick={() => setNotificationDown((prev_status) => !prev_status)} />
                                {
                                    notificationDown == false ?
                                        <NotificationMenu
                                            close={() => { setNotificationDown(true); }}
                                            notificationData={notificationData}
                                        />
                                        :
                                        null
                                }
                            </div>
                            <span ref={wrapperRef}
                                className={`${styles.e_drop_down_wrap} px-2 ms-2`}
                                onClick={() => setLogoutdDown(true)}>
                                <img src={More} className={`${styles.e_header_more} `} alt="" />
                            </span>
                            {
                                logoutdDown ?
                                    <div>
                                        <div className={`${styles.e_dropdown}`} ref={wrapperRef}>
                                            {/* <span data-bs-toggle="modal" data-bs-target="#changePassword">
                                                <img src={Password} alt="Change Password" className="me-2" />
                                                Change password
                                            </span> */}
                                            <span onClick={() => {
                                                navigate("/change-password");
                                                setLogoutdDown(false);
                                            }}>
                                                <img src={Password} alt="Change Password" className="me-2" />
                                                Change password
                                            </span>
                                            <span className={`${styles.e_logout}`}
                                                onClick={_handleLogout}>
                                                <img src={Logout} alt="Logout" className="me-2" />
                                                Logout
                                            </span>
                                        </div>
                                    </div>
                                    :
                                    null
                            }



                        </div>
                    </div>
                    {/* <div className="row mt-5">
                        <div className="col-lg-12">
                            <h3 className="ps-4 ms-2">{header}</h3>
                        </div>
                    </div> */}
                </div>
            </div>
            <ChangePassword />
            {
                error !== null ?
                    <Toast data={error} id="1" type="error" />
                    :
                    null
            }
        </>
    )
}

