/*
 *   File : kyc-status.js
 *   Author : https://evoqins.com
 *   Description : kyc status modal component
 *   Integrations : NA
 *   Version : 1.0.0
*/


import React from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { PrimaryBtn } from '../Cta';

//custom component

const KycStatusModal = () => {

    const navigate = useNavigate();

    //handle to navigate home
    const _handleNavigate = () => {
        navigate("/onboarding-users");
    }

    return (
        <div className="modal fade"
            data-bs-backdrop="static"
            id="kyc-status-modal"
            tabIndex="-1"
            aria-labelledby="kycStatusModalLabel"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content e-border-radius-8">
                    <div className="modal-body text-center p-4">
                        <img src={require("../../Assets/Images/Kyc/kyc-success.png")}
                            draggable={false}
                            className='pt-5 col-5 img-thumbnail border-0'
                            alt='kyc success' />
                        <p className='mb-2 pt-4 mt-1 e-text-chinese-black e-font-18 e-font-manrope e-font-weight-600 line-height-24px'>
                            Congratulations!
                        </p>
                        <p className='mb-0 px-xl-5 px-lg-5 px-md-5 px-sm-0 px-0 e-text-dark-grey e-font-14 e-font-manrope e-font-weight-400 line-height-24px'>
                            The account creation process has been initiated. We will notify over mail once it is completed.
                        </p>

                        <div className='pt-4 mt-2'>
                            <PrimaryBtn name="Go To Listing"
                                className="w-100 e-padding-tb-12px"
                                cancel="modal"
                                handleClick={_handleNavigate} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default KycStatusModal;