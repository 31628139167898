/*
 *   File : orders.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Order list;
 *   Integrations :28-11-2023
 *   Version : v1.0
 *   Created :28-11-2023
*/
/*import packages*/
import { useState, useEffect } from 'react';

/*import components*/
import { DataTable, FundsTab } from '../../Components/DataFormating/index';
import { Loader } from '../../Components/Loader';
import { Toast } from '../../Components/Note';
import { EmptyNote } from '../../Components/Note';

/* import services */
import service from '../../Services/service';


/*---- active sip table columns -----*/

const ACTIVE_SIP_COLUMNS = [
    {
        name: 'Investment type',
        cell: (row) => <>
            {
                row.image ?
                    <img src={row.image} alt="" draggable={false}
                        width="24px"
                        className='e-sip-image' />
                    :
                    null
            }
            <span className='ps-2'>{row.name}</span>
        </>,
        sortable: false,
        left: true,
        width: "400px",
        defaultSortAsc: false,
    },
    {
        name: 'Sip date',
        selector: 'date',
        sortable: false,
        left: true,
        maxWidth: '80px',
        cell: (row) => <div className="col-lg-12" >
            {row.date}
        </div>
    },
    {
        name: 'Next date',
        selector: row => row.created,
        sortable: false,
        left: true,
        minWidth: '140px',
        maxWidth: '140px',
        // sortFunction: (a, b) => customDateSort(a, b, 'order_date',),
        cell: row => <div className="col-lg-12" >
            {row.next_sip_date ? row.next_sip_date : "--"}
        </div>
    },

    {
        name: 'Amount',
        selector: row => row.amount ? parseFloat(row.amount) : 0,
        // selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
        sortable: true,
        left: true,
        minWidth: '130px',
        cell: row => <div onClick={() => { }}>
            ₹{
                row.amount ?
                    row.amount.toLocaleString('en-IN')
                    :
                    0
            }
        </div>

    },


]

/*---- processing sip table columns -----*/

const PROCESSING_SIP_COLUMNS = [
    {
        name: 'Investment type',
        cell: (row) => <>
            {
                row.image ?
                    <img src={row.image} alt="" draggable={false}
                        width="24px"
                        className='e-sip-image' />
                    :
                    null
            }
            <span className='ps-2'>{row.name}</span>
        </>,
        sortable: false,
        left: true,
        maxWidth: "320px",

        defaultSortAsc: false,
    },


    {
        name: 'Executed date',
        // selector: row => row.created,
        sortable: false,
        left: true,
        maxWidth: '180px',
        // sortFunction: (a, b) => customDateSort(a, b, 'order_date',),
        cell: row => <div className="col-lg-12" >
            {row.executed_date}
        </div>
    },

    {
        name: 'Amount',
        selector: row => row.amount ? parseFloat(row.amount) : 0,
        // selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
        sortable: true,
        left: true,
        minWidth: '130px',
        cell: row => <div onClick={() => { }}>
            ₹{
                row.amount ?
                    row.amount.toFixed(2)
                    :
                    0
            }
        </div>

    },
]



const SipList = (props) => {

    const [index, setIndex] = useState(1);
    const [loader, setLoader] = useState(true);
    const [error, setError] = useState(null);
    const [sipData, setSipData] = useState([]);
    const [success, setSuccess] = useState(null);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });


    }, []);

    useEffect(() => {
        if (index === 1) {
            _getActiveSips();
        }
        //eslint-disable-next-line
    }, [index]);

    useEffect(() => {
        if (index === 2) {
            _getProcessingSips();
        }
        //eslint-disable-next-line
    }, [index]);


    useEffect(() => {
        setTimeout(() => {
            if (error !== null) {
                setError(null);
            }
        }, 3000);
    }, [error]);

    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null)
            }, 3000)
        }
    }, [success])



    /*--------- active sip -----------*/
    const _getActiveSips = (query) => {
        setLoader(true);
        const url = '/user/sip/active-list';

        const data = {
            user_id: Number(props.id),

        }
        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setSipData(response.data);
            } else {
                setSipData([]);
            }

            setLoader(false);
        });
    }

    /*--------- processing sips -----------*/
    const _getProcessingSips = (query) => {
        setLoader(true)
        const url = '/user/sip/processed-list';

        const data = {
            user_id: Number(props.id),
        }
        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                setSipData(response.data);
                setLoader(false);
            } else {
                setSipData([]);
                setLoader(false);
            }
        });
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card e-dashboard-card border-0  min-vh-100">
                        <div className="row pt-2">
                            <div className="col-lg-9 col-3 mb-3">
                                <FundsTab data={["Active", "Processing"]}
                                    index={index}
                                    setTab={(temp) => {
                                        setIndex(temp);

                                    }} />
                            </div>

                            {/*--------------- order table -----------*/}
                            {
                                loader ?
                                    <Loader />
                                    : (!sipData.length) ?
                                        <EmptyNote note="There are no sips to show" />
                                        :
                                        <div className="col-lg-12 px-0">
                                            <DataTable
                                                pagination={true}
                                                columns={index === 1 ? ACTIVE_SIP_COLUMNS : PROCESSING_SIP_COLUMNS}
                                                data={sipData}
                                                selectableRows={false}
                                                perPage={10}
                                                rowClick={(row) => { }}
                                            />
                                        </div>
                            }

                        </div>
                    </div>
                </div>
            </div>


            {/* error toast */}
            {
                error !== null ?
                    <Toast
                        data={error}
                        type="eror"
                        id="1"
                    />
                    :
                    null
            }
            {/* Success toast */}
            {
                success !== null ?
                    <Toast data={success}
                        type="success"
                        id="2" />
                    :
                    null
            }
        </>
    );
}

export default SipList;

