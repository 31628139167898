/*
 *   File : text-input.js
 *   Author : https://evoqins.com
 *   Description : Text input component
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { useEffect, useState } from "react";

import IcoMoon from "react-icomoon";
import iconSet from "../../Assets/Images/Icons/selection.json";

const CustomTextInput = (props) => {

    const [inputValue, setInputValue] = useState(props.value);
    const [isInputFocused, setIsInputFocused] = useState(false);

    useEffect(() => {
        setInputValue(props.value);
    }, [props.value]);

    const _handleFocus = () => {
        setIsInputFocused(true);
    };

    const _handleBlur = () => {
        setIsInputFocused(false);
    };

    //input handle change
    const _handleChange = (e) => {
        let value = e.target.value;

        if (props.type === "number") {
            const allowedValues = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", ""];
            if (allowedValues.includes(value.slice(-1)) !== false && value.length <= props.maxLength) {
                setInputValue(value);
                props.handleChange(value);
            }
        } else {
            setInputValue(value);
            props.handleChange(value);
        };
    };

    const _preventDefaults = (e) => {
        const key_code = e.keyCode || e.which;

        if (props.type === "number") {
            // preventing "e"
            if (key_code == 69) {
                e.preventDefault();
            }

            // Preventing number scroll
            if (key_code == 38 || key_code == 40) {
                e.preventDefault();
            }
        }
    };

    return (
        <div className="mb-2">
            {
                props.label &&
                <label className={`${props.labelStyle} e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14 mb-2`}>
                    {props.label}
                    {props.require && <span className='e-text-red'>*</span>}
                </label>
            }

            <div className={`${props.errorMsg && 'e-border-red-1px'} ${isInputFocused ? "e-border-1px-primary-blue" : "e-border-1px-tab-color"}  d-flex align-items-center e-border-radius-8 bg-white`}>
                <input id={props.id}
                    type={props.type}
                    className={`${props.className} cursor-pointer w-100 outline-none e-font-14 e-font-weight-400  border-0 px-3 e-padding-tb-12px e-border-radius-8`}
                    value={inputValue}
                    maxLength={props.maxLength}
                    required={props.required}
                    readOnly={props.readOnly}
                    placeholder={props.placeholder}
                    disabled={props.disabled}
                    onWheel={() => document.activeElement.blur()}
                    onChange={_handleChange}
                    onFocus={_handleFocus}
                    onBlur={_handleBlur}
                    onClick={props.onClick}
                    autoComplete="off"
                    onKeyDown={_preventDefaults} />

                {
                    props.type === 2 &&
                    <span onClick={props.onClick} className="me-sm-3 padding-8px-all-res">
                        <IcoMoon iconSet={iconSet}
                            icon={"calendar"}
                            size={"1.25rem"} />
                    </span>
                }

                {
                    props.dropdown &&
                    <span onClick={props.onClick}
                        className={`${inputValue ? "" : "e-disable"} cursor-pointer me-sm-2 padding-8px-all-res`}>
                        <IcoMoon iconSet={iconSet}
                            icon={"dropdown-arrow"}
                            size={"1.75rem"}
                            className={`${props.modalOpen ? "rotate-min-180deg " : ""} e-transition-all`} />
                    </span>
                }

            </div>

            {/* Error text */}
            <p className={`${props.errorMsg === "" ? "opacity-0" : "opacity-100 "} h-10 mb-0 e-error-message e-font-12 line-height-12px pt-1`}>
                {props.errorMsg}
            </p>

            {/* {props.successMsg &&
                <p className={`${props.successMsg === "" ? "opacity-0" : "opacity-100"} mb-0 e-text-ufo-green e-font-weight-400 e-font-12 ps-1`}>
                    {props.successMsg === "" ? "no error message" : props.successMsg}
                </p>
            } */}
        </div>
    )
}

CustomTextInput.defaultProps = {
    className: null,
    id: null,
    label: null,
    maxLength: 1000,
    handleChange: () => { },
}

export default CustomTextInput;