/*
 *   File : personal-detail.js
 *   Author : https://evoqins.com
 *   Description : Personal detail page
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';
import { format, subYears } from 'date-fns';

//custom component
import { CustomAmountInput, CustomTextInput, DatePicker, FilePicker, RadioGroup } from "../../../Components/Form-Elements";
import { SelectionSuggestModal, VerifyEmailModal } from '../../../Components/Modal';
import { _checkFileSize, _formatDateFormat, _getSignupProgress } from "../../../Helper";
import { PrimaryBtn } from '../../../Components/Cta';
import { Loader } from '../../../Components/Loader';
import awsService from '../../../Services/aws-service';
import service from '../../../Services/service';

//Assets
import LeftArrow from "../../../Assets/Images/Kyc/left-arrow.svg";

// Citizen select 
const CITIZEN = [
    {
        id: 1,
        display_name: "Yes"
    },
    {
        id: 2,
        display_name: "No"
    },
];

// Tax select 
const TAX = [
    {
        id: 1,
        display_name: "Yes"
    },
    {
        id: 2,
        display_name: "No"
    },
];

const PersonalDetail = (props) => {


    const navigate = useNavigate();

    //get general data from redux
    const GENERAL_DATA = useSelector((store) => store.INDEX_FUND_STORE.GENERAL_DATA);
    const [generalData, setGeneralData] = useState(GENERAL_DATA);

    const [progressData, setProgressData] = useState("");
    const [countryData, setCountryData] = useState([]);
    const [customerId, setCustomerId] = useState(props.customerId);
    const [pan, setPan] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");
    const [selectedOccupation, setSelectedOccupation] = useState([]);
    const [occupationError, setOccupationError] = useState("");

    const [selectedSource, setSelectedSource] = useState([]);
    const [incomeSourceError, setIncomeSourceError] = useState("");

    const [selectedCountry, setSelectedCountry] = useState([]);
    const [countryError, setCountryError] = useState("");

    const [dob, setDob] = useState("");
    const [dobError, setDobError] = useState("");

    const [genderSelect, setGenderSelect] = useState(29); // remove after api setup
    const [martialSelect, setMartialSelect] = useState(30); // remove after api setup
    const [citizenSelect, setCitizenSelect] = useState(1);
    const [taxSelect, setTaxSelect] = useState(2);
    const [income, setIncome] = useState("");
    const [incomeError, setIncomeError] = useState("");

    const [panFile, setPanFile] = useState(null);
    const [panFileError, setPanFileError] = useState("");
    const [panViewUrl, setPanViewUrl] = useState(null);

    const [image, setImage] = useState(null);
    const [imageError, setImageError] = useState("");
    const [imageViewUrl, setImageViewUrl] = useState(null);

    const [signatureFile, setSignatureFile] = useState(null);
    const [signatureFileError, setSignatureFileError] = useState("");
    const [signatureViewUrl, setSignatureViewUrl] = useState(null);

    const [fatherName, setFatherName] = useState("");
    const [fatherNameError, setFatherNameError] = useState("");

    const [motherName, setMotherName] = useState("");
    const [motherNameError, setMotherNameError] = useState("");

    // Tax Details
    const [taxPayingCountry, setTaxPayingCountry] = useState([]);
    const [taxPayingCountryError, setTaxPayingCountryError] = useState("");

    const [taxProof, setTaxProof] = useState([]);
    const [taxProofError, setTaxProofError] = useState("");

    const [taxReferenceNumber, setTaxReferenceNumber] = useState("");
    const [taxReferenceNumberError, setTaxReferenceNumberError] = useState("");

    const [showEmailEditModal, setShowEmailEditModal] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {
        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });
    }, []);

    useEffect(() => {
        setCustomerId(props.customerId);
        setCountryData(props.country);
    }, [props.customerId]);

    useEffect(() => {
        if (customerId) {
            setLoadingData(true);
            _getSignupProgress(customerId).then(response => {
                if (response.status_code === 200) {
                    setProgressData(response.data);
                } else {
                    setProgressData("");
                }
            });
        }
    }, [customerId]);

    useEffect(() => {

        if (progressData) {

            if (progressData.kyc_data.pan !== null) {
                setPan(progressData.kyc_data.pan);
            };

            if (progressData.kyc_data.email !== null) {
                setEmail(progressData.kyc_data.email);
            };

            if (progressData.kyc_data.name.length !== 0) {
                setName(progressData.kyc_data.name);
            };

            if (progressData.kyc_data.dob !== null) {
                setDob(_formatDateFormat(progressData.kyc_data.dob));
            };

            if (progressData.kyc_data.father_name !== null) {
                setFatherName(progressData.kyc_data.father_name);
            };

            if (progressData.kyc_data.mother_name !== null) {
                setMotherName(progressData.kyc_data.mother_name);
            };

            if (progressData.kyc_data.gender_id !== null) {
                setGenderSelect(progressData.kyc_data.gender_id);
            } else {
                setGenderSelect(generalData.genders[0].id);
            };

            if (progressData.kyc_data.marital_status_id !== null) {
                setMartialSelect(progressData.kyc_data.marital_status_id);
            } else {
                setMartialSelect(generalData.marital_statuses[0].id);
            };

            if (progressData.kyc_data.is_indian_citizen === true || progressData.kyc_data.is_indian_citizen === null) {
                setCitizenSelect(1);
            } else {
                setCitizenSelect(2);
            };

            if (progressData.kyc_data.is_indian_citizen === false) {
                if (progressData.kyc_data.country_code !== null && progressData.kyc_data.country) {
                    setSelectedCountry({ id: progressData.kyc_data.country_code, name: progressData.kyc_data.country });
                };
            }

            if (progressData.kyc_data.is_indian_tax_payer === true) {
                setTaxSelect(2);
            } else {
                setTaxSelect(1);
            };

            if (progressData.kyc_data.tax_country && progressData.kyc_data.tax_country_id) {
                setTaxPayingCountry({ id: progressData.kyc_data.tax_country_id, name: progressData.kyc_data.tax_country })
            };

            if (progressData.kyc_data.reference_proof_id && progressData.kyc_data.reference_proof) {
                setTaxProof({ id: progressData.kyc_data.reference_proof_id, display_name: progressData.kyc_data.reference_proof })
            }

            if (progressData.kyc_data.tax_reference_number) {
                setTaxReferenceNumber(progressData.kyc_data.tax_reference_number);
            };

            if (progressData.kyc_data.occupation_id !== null && progressData.kyc_data.occupation !== null) {
                setSelectedOccupation({ id: progressData.kyc_data.occupation_id, display_name: progressData.kyc_data.occupation });
            };

            if (progressData.kyc_data.income_source_id !== null && progressData.kyc_data.income_source) {
                setSelectedSource({ id: progressData.kyc_data.income_source_id, display_name: progressData.kyc_data.income_source });
            };

            if (progressData.kyc_data.anual_income > 0) {
                setIncome(progressData.kyc_data.anual_income);
            };

            // uploaded file 
            if (progressData.kyc_data.identity_proof_url !== null) {
                let pan_url = progressData.kyc_data.identity_proof_url;
                let updated_name = pan_url.split("/").pop().split("?")[0];

                setPanFile(updated_name);
                setPanViewUrl(progressData.kyc_data.identity_proof_url);
            };

            if (progressData.kyc_data.photo_proof_url !== null) {
                let image_url = progressData.kyc_data.photo_proof_url;
                let updated_image = image_url.split("/").pop().split("?")[0];

                setImage(updated_image);
                setImageViewUrl(progressData.kyc_data.photo_proof_url);
            }

            if (progressData.kyc_data.signature_proof_url !== null) {
                let signature_url = progressData.kyc_data.signature_proof_url;
                let updated_signature = signature_url.split("/").pop().split("?")[0];

                setSignatureFile(updated_signature);
                setSignatureViewUrl(progressData.kyc_data.signature_proof_url);
            };

            setLoadingData(false);
        }

    }, [progressData]);

    useEffect(() => {
        setGeneralData(GENERAL_DATA);
    }, [GENERAL_DATA]);

    // error clear 
    useEffect(() => {
        setNameError("");
    }, [name]);

    useEffect(() => {
        setDobError("");
    }, [dob]);

    useEffect(() => {
        setOccupationError("");
    }, [selectedOccupation]);

    useEffect(() => {
        setIncomeSourceError("");
    }, [selectedSource]);

    useEffect(() => {
        setCountryError("");
    }, [selectedCountry]);

    useEffect(() => {
        setPanFileError("");
    }, [panFile]);

    useEffect(() => {
        setImageError("");
    }, [image]);

    useEffect(() => {
        setSignatureFileError("");
    }, [signatureFile]);

    useEffect(() => {
        setFatherNameError("");
    }, [fatherName]);

    useEffect(() => {
        setMotherNameError("");
    }, [motherName]);


    // Open phone edit modal
    useEffect(() => {
        if (showEmailEditModal) {
            new Modal(document.querySelector("#verify-email-modal")).show();
        }
    }, [showEmailEditModal]);

    //handle change date
    const _handleDateChange = (date) => {
        setDob(date);
    };

    //open modal
    const _handleDropdownModal = (type) => {
        if (type === 1) {
            let suggestion_modal = new Modal(document.getElementById('select-suggest-modal'));
            suggestion_modal.show();
        } else if (type === 2) {
            let source_modal = new Modal(document.getElementById('select-source-modal'));
            source_modal.show();
        } else if (type === 3) {
            let country_modal = new Modal(document.getElementById('select-country-modal'));
            country_modal.show();
        } else if (type === 4) {
            let country_modal = new Modal(document.getElementById('select-tax-country-modal'));
            country_modal.show();
        } else if (type === 5) {
            let country_modal = new Modal(document.getElementById('select-tax-proof-modal'));
            country_modal.show();
        }
    };

    //handle select occupation
    const _handleSelectOccupation = (value) => {
        setSelectedOccupation(value);
    };

    //handle select income source
    const _handleSelectSource = (value) => {
        setSelectedSource(value);
    };

    //handle select country
    const _handleSelectCountry = (value) => {
        setSelectedCountry(value);
    };

    //handle gender select
    const _handleGenderSelect = (id) => {
        setGenderSelect(id);
    };

    //handle the martial status
    const _handleMartialSelect = (id) => {
        setMartialSelect(id);
    };

    //handle citizenship
    const _handleCitizenSelect = (id) => {
        setCitizenSelect(id);
    };

    //handle tax
    const _handleTaxSelect = (id) => {
        setTaxSelect(id);
    };

    const _handleChangeTaxReference = (value) => {
        setTaxReferenceNumber(value)
        setTaxReferenceNumberError("");
    };


    //handle INcome
    const _handleIncome = (value) => {
        setIncome(value);
        setIncomeError("");
    };

    //handle pan file upload
    const _handleUploadPAN = (file) => {

        if (file.target.files.length !== 0) {
            const is_file_size_valid = _checkFileSize(file.target.files[0].size);

            if (is_file_size_valid === false) {
                setPanFileError("Maximum file size should be 5mb only");
                return;
            }

            _getPresignedUrl(file, 1);
        }

    };

    // handle open tax paying country select modal
    const _handleSelectTaxPayingCountry = (value) => {
        setTaxPayingCountry(value);
        setTaxPayingCountryError("");
        // setShowTaxPayingCountryModal(false);
    };

    const _handleSelectTaxProof = (value) => {
        setTaxProof(value);
        setTaxProofError("");
    };


    //handle upload imag
    const _handleUploadImage = (file) => {

        if (file.target.files.length !== 0) {
            const is_file_size_valid = _checkFileSize(file.target.files[0].size);

            if (is_file_size_valid === false) {
                setImageError("Maximum file size should be 5mb only");
                return;
            }
            _getPresignedUrl(file, 2);
        }

    };

    //handle upload signature
    const _handleUploadSignature = (file) => {

        if (file.target.files.length !== 0) {
            const is_file_size_valid = _checkFileSize(file.target.files[0].size);

            if (is_file_size_valid === false) {
                setSignatureFileError("Maximum file size should be 5mb only");
                return;
            }

            _getPresignedUrl(file, 3);
        }

    };

    // handle back
    const _handleBack = () => {
        props.onUpdateStep(1);
    };

    //handle submit details
    const _handleSubmit = () => {

        let errors = [];
        let validation = true;

        if (name.length === 0) {
            setNameError("Name is required");
            errors.push("name");
            validation = false;
        };

        if (dob.length === 0) {
            setDobError("DOB is required");
            errors.push("dob");
            validation = false;
        };

        // ONLY FOR NON KYC USER
        if (progressData.kyc_user === false) {

            if (fatherName.length === 0) {
                setFatherNameError("Father name is required");
                errors.push("father-name");
                validation = false;
            }

            if (motherName.length === 0) {
                setMotherNameError("Mother name is required");
                errors.push("mother-name");
                validation = false;
            }
        };

        if (citizenSelect === 2) {
            if (selectedCountry.length === 0) {
                setCountryError("Select country");
                errors.push("country");
                validation = false;
            }
        };

        if (taxSelect === 1) {
            if (taxPayingCountry.length === 0) {
                setTaxPayingCountryError("Please select your tax paying country");
                errors.push("tax-paying-country");
                validation = false;
            };

            if (taxProof.length === 0) {
                setTaxProofError("Please select tax paying proof");
                errors.push("tax-proof");
                validation = false;
            }

            if (taxReferenceNumber.length === 0) {
                setTaxReferenceNumberError("Please enter tax reference number");
                errors.push("tax-reference");
                validation = false;
            }
        };

        if (selectedOccupation.length === 0) {
            setOccupationError("Select occupation");
            errors.push("occupation");
            validation = false;
        };

        if (selectedSource.length === 0) {
            setIncomeSourceError("Select income source");
            errors.push("source-of-income");
            validation = false;
        };

        if (income === 0 || income == "") {
            setIncomeError("Enter annual income");
            errors.push("income");
            validation = false;
        };

        // ONLY FOR NON KYC USER
        if (progressData.kyc_user === false) {

            if (panFile === null) {
                setPanFileError("Please upload a photo of your PAN card");
                errors.push("pan-file");
                validation = false;
            }

            if (image === null) {
                setImageError("Please upload a clear image of your photograph");
                errors.push("photo");
                validation = false;
            }

            if (signatureFile === null) {
                setSignatureFileError("Please upload a clear image of your signature");
                errors.push("sign");
                validation = false;
            }
        }



        if (validation) {
            setLoading(true);
            _savePersonalData();
        }

        // Scrolling to first error
        if (errors.length !== 0) {
            let first_err_id = errors[0];
            let element = document.getElementById(first_err_id);
            element.scrollIntoView({ behavior: "smooth" });
        };

    };

    //get presigned url
    async function _getPresignedUrl(file, type) {

        let file_name = file.target.files[0].name;

        let is_post = true;
        let url = '/file/upload';
        let data = {
            "file": file.target.files[0].name,
            "is_public": true,
            "customer_id": props.customerId
        };
        try {
            let response = await service(is_post, url, data);
            let aws_URL = '';
            if (response.status_code == 200) {

                aws_URL = response.data.upload_info.url;
                let view_url = response.data.view_info;

                let file_param = new File(
                    [file.target.files[0]],
                    file.target.files[0].name,
                    { type: file.target.files[0].type }
                );

                try {
                    let awsResponse = await awsService(response.data.upload_info.fields, file_param, aws_URL);
                    if (awsResponse.status == 'ok') {

                        if (type === 1) {
                            setPanViewUrl(view_url);
                            setPanFile(file_name);
                        }
                        if (type === 2) {
                            setImageViewUrl(view_url);
                            setImage(file_name);
                        }
                        if (type === 3) {
                            setSignatureViewUrl(view_url);
                            setSignatureFile(file_name);
                        }

                    } else {
                        toast.dismiss();
                        toast.error(awsResponse.message, { className: "e-toast" });
                    }
                } catch (err) {
                    toast.dismiss();
                    toast.error(err.message, { className: "e-toast" });
                }
            } else {
                toast.dismiss();
                toast.error("Something went wrong please try again", { className: "e-toast" });
            }
        } catch (err) {
            toast.dismiss();
            toast.error("Something went wrong please try again", { className: "e-toast" });
        }
    };

    //API- Post save personal data 
    const _savePersonalData = () => {

        let is_post = true;
        let url = '/kyc/save-personal-data';
        let data = {
            "customer_id": props.customerId,
            "name": name,
            "dob": format(dob, "dd-MM-yyyy"),
            "gender_id": genderSelect,
            "is_indian_citizen": citizenSelect === 1 ? true : false,
            "country_id": citizenSelect === 1 ? null : selectedCountry.id,
            "is_indian_tax_payer": taxSelect === 2 ? true : false,
            "reference_proof_id": taxSelect === 1 ? taxProof.id : null,
            "tax_reference_number": taxSelect === 1 ? taxReferenceNumber : null,
            "tax_country_id": taxSelect === 1 ? taxPayingCountry.id : null,
            "occupation_id": selectedOccupation.id,
            "income_source_id": selectedSource.id,
            "annual_income": income,
            "marital_status_id": martialSelect,
            "father_name": fatherName,
            "mother_name": motherName,
            "photo_proof_url": imageViewUrl,
            "signature_proof_url": signatureViewUrl,
            "identity_proof_url": panViewUrl
        }

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                toast.dismiss();
                toast.success("Personal details saved successfully");
                // _getProgressData();
                props.onUpdateStep(3, customerId);

            } else {
                toast.dismiss();
                toast.error(response.data.message);
            }

            setLoading(false);
        });
    };

    return (
        <>
            {
                loadingData === false ?
                    <div className='px-0 pb-4'>
                        <div className='col-xl-6 col-lg-7 col-sm-10 col-12'>
                            <img src={LeftArrow}
                                draggable={false}
                                className='cursor-pointer'
                                alt='arrow'
                                onClick={_handleBack} />

                            {/* step flow */}
                            <div className='position-relative'>
                                <div className='e-bg-lavender h-6 mt-4 e-border-radius-16'></div>
                                <div className='position-absolute h-6 e-bg-header-bg e-border-radius-16 top-0'
                                    style={{ width: `${(100 / progressData.step_detail.length) * 2}%`}}></div>
                                <p className='mb-0 text-end pt-1 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-400 line-height-24px'>
                                    Step 2 of {progressData.step_detail.length}
                                </p>
                            </div>

                            {/* Personal details */}
                            <div className='d-flex gap-2 flex-column mt-2'>
                                <h3 className='mb-2 e-text-chinese-black e-font-18 e-font-manrope e-font-weight-600 line-height-24px'>
                                    Personal details
                                </h3>

                                <CustomTextInput label="PAN"
                                    require={true}
                                    type="text"
                                    value={pan}
                                    readOnly
                                    disabled={true} />

                                <div id='email' className='position-relative'>
                                    <CustomTextInput label="Email"
                                        require
                                        type="text"
                                        placeholder="john@gmail.com"
                                        value={email}
                                        disabled={true}
                                        handleChange={(value) => setEmail(value.toLowerCase())} />
                                    <p className='position-absolute bottom-30px right-16px text-decoration-hover-underline mb-0 cursor-pointer e-text-primary e-font-14 e-font-manrope e-font-weight-600 line-height-20px'
                                        onClick={() => { setShowEmailEditModal(true) }}>
                                        Edit
                                    </p>
                                </div>

                                {/* name */}
                                <div id='name'>
                                    <CustomTextInput label="Full name(As per PAN)"
                                        require
                                        placeholder="John doe"
                                        type="text"
                                        value={name}
                                        errorMsg={nameError}
                                        handleChange={(value) => setName(value)} />
                                </div>

                                {/* Date picker */}
                                <div>
                                    <h6 id='dob'
                                        className='e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14 mb-2'>
                                        Date of birth
                                        <span className='e-text-red'>*</span>
                                    </h6>
                                    <DatePicker value={dob}
                                        minDate={subYears(new Date(), 100)}
                                        maxDate={subYears(new Date(), 18)}
                                        error={dobError}
                                        onChange={_handleDateChange} />
                                </div>

                                {
                                    progressData.kyc_user === false &&
                                    <>
                                        {/*Father name */}
                                        <div id='father-name'>
                                            <CustomTextInput label="Father name"
                                                require
                                                placeholder="John doe"
                                                type="text"
                                                value={fatherName}
                                                errorMsg={fatherNameError}
                                                labelStyle="e-font-weight-500"
                                                handleChange={(e) => {
                                                    setFatherName(e);
                                                }} />
                                        </div>

                                        {/* Mother name */}
                                        <div id='mother-name'>
                                            <CustomTextInput label="Mother name"
                                                require
                                                placeholder="John doe"
                                                type="text"
                                                value={motherName}
                                                errorMsg={motherNameError}
                                                handleChange={(e) => {
                                                    setMotherName(e);
                                                }} />
                                        </div>
                                    </>
                                }


                                {/* gender  */}
                                <div className='mb-2 pt-1'>
                                    <h6 className='e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14 mb-3'>
                                        Your gender
                                        <span className='e-text-red'>*</span>
                                    </h6>
                                    <div className='d-flex flex-wrap gap-3'>
                                        {/* <RadioGroup data={generalData.genders} */}
                                        <RadioGroup data={generalData.genders}
                                            selected={genderSelect}
                                            handleSelect={_handleGenderSelect} />
                                    </div>
                                </div>

                                {/* martial status */}
                                <div>
                                    <h6 className='e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14 mb-3'>
                                        Your martial status
                                        <span className='e-text-red'>*</span>
                                    </h6>
                                    <div className='d-flex flex-wrap gap-3'>
                                        <RadioGroup data={generalData.marital_statuses}
                                            selected={martialSelect}
                                            handleSelect={_handleMartialSelect} />
                                    </div>
                                </div>
                            </div>

                            {/* Nationality, Tax and Source */}
                            <div className='mt-4 d-flex flex-column gap-2'>
                                <h3 className='mb-2 e-text-chinese-black e-font-18 e-font-manrope e-font-weight-600 line-height-24px'>
                                    Nationality, Tax and Source
                                </h3>

                                {/* citizen */}
                                <div className='mb-2'>
                                    <h6 className='e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14 mb-3'>
                                        Are you an Indian citizen?
                                    </h6>
                                    <div className='d-flex flex-wrap gap-3'>
                                        <RadioGroup data={CITIZEN}
                                            selected={citizenSelect}
                                            handleSelect={_handleCitizenSelect} />
                                    </div>
                                </div>

                                {/* Country select */}
                                {
                                    citizenSelect === 2 &&
                                    <div id="country"
                                        className='position-relative h-max-content'>
                                        <CustomTextInput label="Country"
                                            className="w-100 cursor-pointer"
                                            require
                                            placeholder="Select country"
                                            type="text"
                                            value={selectedCountry.name}
                                            errorMsg={countryError}
                                            readOnly
                                            onClick={() => _handleDropdownModal(3)} />
                                        <img src={require("../../../Assets/Images/Kyc/drop-down.svg").default}
                                            draggable={false}
                                            className='position-absolute end-0 top-46px me-3'
                                            alt='icon' />
                                    </div>
                                }

                                {/* tax detail */}
                                <div className='mb-2'>
                                    <h6 className='e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14 mb-3'>
                                        Are you a tax-paying resident in any other country?
                                    </h6>
                                    <div className='d-flex flex-wrap gap-3'>
                                        <RadioGroup data={TAX}
                                            selected={taxSelect}
                                            handleSelect={_handleTaxSelect} />
                                    </div>
                                </div>

                                {
                                    taxSelect == 1 &&
                                    <>
                                        <div className='position-relative h-90'
                                            id='tax-paying-country'>
                                            <CustomTextInput label="Tax paying country"
                                                require
                                                placeholder="Select country"
                                                type="text"
                                                value={taxPayingCountry.name}
                                                errorMsg={taxPayingCountryError}
                                                readOnly
                                                className="w-100 px-3 padding-12px-top padding-12px-bottom cursor-pointer"
                                                labelStyle="e-font-weight-500"
                                                onClick={() => _handleDropdownModal(4)} />
                                            <img src={require("../../../Assets/Images/Kyc/drop-down.svg").default}
                                                draggable={false}
                                                className='position-absolute end-0 top-46px me-3'
                                                alt='icon' />
                                        </div>
                                        <div id='tax-proof'
                                            className='h-90 position-relative'>
                                            <CustomTextInput label="Tax proof"
                                                require
                                                placeholder="Select tax proof"
                                                type="text"
                                                value={taxProof.display_name}
                                                errorMsg={taxProofError}
                                                readOnly
                                                className="w-100 px-3 padding-12px-top padding-12px-bottom cursor-pointer"
                                                labelStyle="e-font-weight-500"
                                                onClick={() => _handleDropdownModal(5)} />
                                            <img src={require("../../../Assets/Images/Kyc/drop-down.svg").default}
                                                draggable={false}
                                                className='position-absolute end-0 top-46px me-3'
                                                alt='icon' />
                                        </div>
                                        <div id='tax-reference'
                                            className='h-90 position-relative'>
                                            <CustomTextInput label="Tax reference number"
                                                require
                                                dropdown={true}
                                                placeholder="FHDC28738"
                                                type="text"
                                                value={taxReferenceNumber}
                                                errorMsg={taxReferenceNumberError}
                                                className="w-100 px-3 padding-12px-top padding-12px-bottom "
                                                labelStyle="e-font-weight-500"
                                                handleChange={_handleChangeTaxReference} />
                                        </div>
                                    </>
                                }

                                {/* Occupation select */}
                                <div id='occupation'
                                    className='position-relative'>
                                    <CustomTextInput label="Occupation"
                                        require
                                        className="cursor-pointer"
                                        placeholder="Select occupation"
                                        type="text"
                                        value={selectedOccupation.display_name}
                                        errorMsg={occupationError}
                                        readOnly
                                        onClick={() => _handleDropdownModal(1)} />
                                    <img src={require("../../../Assets/Images/Kyc/drop-down.svg").default}
                                        draggable={false}
                                        className='position-absolute end-0 top-46px me-3'
                                        alt='icon' />
                                </div>

                                {/* source of Income */}
                                <div id='source-of-income'
                                    className='position-relative'>
                                    <CustomTextInput label="Source of income"
                                        require
                                        className="cursor-pointer"
                                        placeholder="Select source of income"
                                        type="text"
                                        value={selectedSource.display_name}
                                        errorMsg={incomeSourceError}
                                        readOnly
                                        onClick={() => _handleDropdownModal(2)} />
                                    <img src={require("../../../Assets/Images/Kyc/drop-down.svg").default}
                                        draggable={false}
                                        className='position-absolute end-0 top-46px me-3'
                                        alt='icon' />
                                </div>

                                {/* Annual income */}
                                <div id='income'>
                                    <CustomAmountInput label='Annual income'
                                        require
                                        placeholder={0}
                                        value={income}
                                        errorMsg={incomeError}
                                        maxLength={8}
                                        handleChange={(value) => _handleIncome(value)} />
                                </div>

                                {
                                    progressData.kyc_user === false &&
                                    <>
                                        {/* Upload PAN*/}
                                        <div id='pan-file'>
                                            <label className="e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14 mb-2">
                                                Upload PAN image
                                                <span className='e-text-red'>*</span>
                                                (max.5mb)
                                            </label>

                                            {/* uploaded pan */}
                                            {panFile &&
                                                <div className='e-bg-anti-flash-white e-border-radius-12 px-3 mb-2'>
                                                    <div className='d-flex justify-content-between py-1'>
                                                        <p className='mb-0 py-2 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-400 line-height-24px'>
                                                            {panFile}
                                                        </p>
                                                        <img src={require("../../../Assets/Images/Kyc/selected-close.svg").default}
                                                            draggable={false}
                                                            className='cursor-pointer py-2'
                                                            alt='delete'
                                                            onClick={() => setPanFile(null)} />
                                                    </div>
                                                </div>
                                            }

                                            <FilePicker id="pan"
                                                label="Click here to upload(PNG or JPG)"
                                                type="file"
                                                className="w-100"
                                                accept=".png, .jpg"
                                                error={panFileError}
                                                currentFile={panFile}
                                                selectFile={_handleUploadPAN} />
                                        </div>

                                        {/* upload your photo */}
                                        <div id='photo'>
                                            <label className="mb-3 e-text-dark-charcoal e-font-weight-500 e-font-manrope line-height-18px e-font-14 mb-2">
                                                Upload photo
                                                <span className='e-text-red'>*</span>
                                                (max.5mb)
                                            </label>
                                            {/* uploaded image */}
                                            {image &&
                                                <div className='e-bg-anti-flash-white e-border-radius-12 px-3 mb-3'>
                                                    <div className='d-flex justify-content-between py-1'>
                                                        <p className='mb-0 py-2 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-400 line-height-24px'>
                                                            {image}
                                                        </p>
                                                        <img src={require("../../../Assets/Images/Kyc/selected-close.svg").default}
                                                            draggable={false}
                                                            className='cursor-pointer py-2'
                                                            alt='delete'
                                                            onClick={() => setImage(null)} />
                                                    </div>
                                                </div>
                                            }
                                            <FilePicker id="your-image"
                                                label="Click here to upload(PNG or JPG)"
                                                type="file"
                                                className="w-100"
                                                accept=".png, .jpg"
                                                currentFile={image}
                                                error={imageError}
                                                selectFile={_handleUploadImage} />
                                        </div>

                                        {/* upload signature file */}
                                        <div id='sign'>
                                            <label className="mb-3 e-text-dark-charcoal e-font-weight-500 e-font-manrope line-height-18px e-font-14 mb-2">
                                                Upload signature
                                                <span className='e-text-red'>*</span>
                                                (max.5mb)
                                            </label>

                                            {signatureFile &&
                                                <div className='e-bg-anti-flash-white e-border-radius-12 px-3 mb-3'>
                                                    <div className='d-flex justify-content-between py-1'>
                                                        <p className='mb-0 py-2 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-400 line-height-24px'>
                                                            {signatureFile}
                                                        </p>
                                                        <img src={require("../../../Assets/Images/Kyc/selected-close.svg").default}
                                                            draggable={false}
                                                            className='cursor-pointer py-2'
                                                            alt='delete'
                                                            onClick={() => setSignatureFile(null)} />
                                                    </div>
                                                </div>
                                            }

                                            <FilePicker id="signature"
                                                label="Click here to upload(PNG or JPG)"
                                                type="file"
                                                className="w-100"
                                                accept=".png, .jpg"
                                                error={signatureFileError}
                                                currentFile={signatureFile}
                                                selectFile={_handleUploadSignature} />
                                        </div>
                                    </>
                                }


                            </div>

                            {/* button */}
                            <div className='pt-4 mt-2'>
                                <PrimaryBtn className={loading ? "e-padding-tb-12px w-40per" : "e-padding-tb-15px w-40per"}
                                    name={"Save & Continue"}
                                    loading={loading}
                                    handleClick={_handleSubmit} />
                            </div>
                        </div>
                    </div >
                    :
                    <div className="min-h-100vh text-center pt-5" >
                        <Loader />
                    </div>
            }

            {/* occupation modal*/}
            <SelectionSuggestModal title="Choose an occupation"
                data={generalData.occupation}
                type={1}
                id='select-suggest-modal'
                selectedOption={selectedOccupation}
                onSelectOption={_handleSelectOccupation} />

            {/* source of income select */}
            <SelectionSuggestModal title="Select source of income"
                data={generalData.source_of_wealths}
                type={2}
                id='select-source-modal'
                selectedOption={selectedSource}
                onSelectOption={_handleSelectSource} />

            {/* Country select modal */}
            <SelectionSuggestModal title="Choose a country"
                data={countryData}
                type={3}
                id='select-country-modal'
                search={true}
                searchPlaceholder="Search country"
                selectedOption={selectedCountry}
                onSelectOption={_handleSelectCountry} />

            {/* Tax paying country select modal */}
            <SelectionSuggestModal title="Choose a tax paying country"
                data={countryData}
                type={4}
                id='select-tax-country-modal'
                search={true}
                searchPlaceholder="Search country"
                selectedOption={taxPayingCountry}
                onSelectOption={_handleSelectTaxPayingCountry} />

            {/* Tax proof select modal */}
            <SelectionSuggestModal title="Choose a tax proof"
                data={generalData.fatca_verification}
                type={5}
                id='select-tax-proof-modal'
                selectedOption={taxProof}
                onSelectOption={_handleSelectTaxProof} />

            {
                showEmailEditModal &&
                <VerifyEmailModal email={email}
                    edit={true}
                    customerId={props.customerId}
                    onSuccess={(_, email) => {
                        setEmail(email);
                        setShowEmailEditModal(false);
                    }}
                    onCloseModal={() => setShowEmailEditModal(false)} />
            }

        </>
    )
}

export default PersonalDetail;