/*
 *   File : config.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Config file for the template
 *   Version : 1.0.0
 *   Created : 28-11-2023
 */

// Assets 
import Logo from '../Assets/Images/Navigation/inner-logo.svg';

/*----Meta -----*/
const Metadata = { title: 'Evo-admin-template' }

// prod api url = https://6d5tbkabq5.execute-api.ap-south-1.amazonaws.com/prod/api/v1/admin


/*----- Sidebar ------ */
const Sidebar = [
	{
		name: 'Dashboard',
		link: '/',
		header: 'Overview',
		icon_name: "dashboard"
	},
	{
		name: 'Users',
		link: '/users',
		header: 'Users',
		icon_name: "users"
	},
	{
		name: 'Orders list',
		link: '/orders',
		header: 'Orders',
		icon_name: "order-list"
	},
	{
		name: 'Redemption request',
		link: '/redemption',
		header: 'Redemption',
		icon_name: "redemption-list"
	},
	{
		name: 'Manage funds',
		link: '/funds',
		header: 'Manage funds',
		icon_name: "manage-funds"
	},
	{
		name: 'Manage baskets',
		link: '/baskets',
		header: 'Manage baskets',
		icon_name: "manage-baskets"
	},
	{
		name: 'Manage inflation',
		link: '/config',
		header: 'Manage inflation',
		icon_name: "manage-returns"
	},
	// {
	// 	name: 'Manage banner',
	// 	link: '/banner',
	// 	header: 'Manage home banner',
	// icon_name: "manage-banner"
	// },
	{
		name: 'Manage support',
		link: '/support',
		header: '',
		icon_name: "manage-support"
	},
	{
		name: 'Family users',
		link: '/family-users',
		header: '',
		icon_name: "manage-family"
	},
	{
		name: 'Risk questionnaire',
		link: '/risk-questionnaire',
		header: '',
		icon_name: "risk-questionnaire"
	},
	// {
	// 	name: 'General settings',
	// 	link: '/content',
	// 	header: '',
	// 	icon_name: "general-settings"
	// },

	{
		name: 'App notifications',
		link: '/notifications',
		header: 'Send app notifications',
		icon_name: "app-notification"
	},
	{
		name: 'Onboarding users',
		link: '/onboarding-users',
		header: 'Onboarding users list',
		icon_name: "onboarding-users"
	},

	{
		name: 'Create investments',
		link: '/create-investments',
		header: 'Create investments',
		icon_name: "create-investment"
	},

	// {
	// 	name: 'Value pairs',
	// 	icon: Pairs,
	// 	link: '/value-pairs',
	// 	header: 'value pairs',
	// },
];

/*--- api url ---*/
const API_URL = process.env.REACT_APP_API_ENDPOINT

/*----- Footer ----- */
const Footer = {
	logo: Logo,
	copy_right: `©  ${new Date().getFullYear()} Index fund Sahi Hai Ltd.`,
	support_link: 'support@indexfundsahihai.com',
	powered_by: 'evoqueinnovativelab.com',
	links: [
		{
			name: 'Home',
			link: '#',
		},
		{
			name: 'Terms',
			link: '#',
		},
		{
			name: 'Privacy',
			link: '#',
		},
	]
};

var devconfig = {
	sidebar: Sidebar,
	footer: Footer,
	meta: Metadata,
	admin: API_URL,
	AUTH_URL: '/',
}

export default devconfig;
