/*
 *   File : file-picker.js
 *   Author : https://evoqins.com
 *   Description : File picker component
 *   Integrations : NA
 *    Version : 1.0.0
*/

// Assets
import Upload from "../../Assets/Images/Kyc/upload-icon.svg";


const FilePicker = (props) => {

    //handle upload 
    const _handleUpload = () => {
        document.getElementById(props.id).click();
    }

    return (
        <div>
            <div className='cursor-pointer padding-tb-12px px-3 e-bg-anti-flash-white e-border-radius-8'
                onClick={_handleUpload}>
                <input id={props.id}
                    ref={props.ref}
                    type={props.type}
                    key={props.key}
                    accept={props.accept}
                    style={{ display: 'none' }}
                    onChange={(e) => {
                        props.selectFile(e);
                    }}
                    onClick={(e) => e.target.value = ""} />

                <div className='d-flex justify-content-center gap-2 py-1'>
                    <p className='py-2 no-scrollbar text-nowrap overflow-auto mb-0 e-text-light-grey e-font-14 e-font-manrope e-font-weight-400 line-height-24px'>
                        {props.label}
                    </p>
                    <img src={Upload}
                        draggable={false}
                        alt='upload' />
                </div>
            </div>
            <p className="h-10 mb-0 e-error-message e-font-12 ps-1">
                {props.error}
            </p>
        </div>

    )
}

export default FilePicker;