/*
 *   File : secondary.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Secondary Button
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 28-11-2023
 */

/*Style Import*/
import styles from '../../Styles/Components/secondary.module.scss';

// Assets
import LoaderImg from '../../Assets/Images/Loader/btn-loader-blue.gif';

const SecondaryBtn = (props) => {
	return (
		<button id={props.id}
			className={`${styles.e_s_btn} ${props.className}`}
			data-bs-dismiss={props.cancel}
			data-bs-toggle={props.toggle}
			data-bs-target={props.target}
			onClick={props.handleClick}
			type={props.type}>
			{
				props.loading ? (
					<img src={LoaderImg}
						width={50}
						height={22}
						className='object-fit-contain'
						draggable="false" />
				) : (
					<span>
						{props.img}
						{props.name}
					</span>
				)
			}
		</button>

	);
}


export default SecondaryBtn;
