import service from "../Services/service";


// KYC signup progress
export const _getSignupProgress = async (customer_id) => {

    let is_post = true;
    let url = "/kyc/signup-progress";
    let data = {
        "customer_id": customer_id
    };

    try {
        let response = await service(is_post, url, data);
        return response;
    } catch (error) {
        return error;
    }

};

// Saving goal details
export const _setGoal = async (param) => {

    let is_post = true;
    let url = "/goal/set";
    let data = {
        "customer_id": param.customer_id,
        "general_data": {
            "is_inflation_enabled": param.is_inflation_enabled,
            "tenure": param.tenure,
        },
        "goal_id": param.goal_id,
        "target_value": param.target,
        "lumpsum": {
            "amount": param.lumpsum
        },
        "sip": {
            "amount": param.sip,
            "step_sip": param.step_sip
        },

        "additional_data": {
        }
    };

    try {
        let response = await service(is_post, url, data);
        return response;
    } catch (error) {
        return error;
    }

};

// Sending otp to user
export const _sendOTP = async (param) => {

    let is_post = true;
    let url = "/consent/generate-2fa";
    let data = {
        "type": param.type,
        "customer_id": param.id
    };

    try {
        let response = await service(is_post, url, data);
        return response;
    } catch (error) {
        return error;
    }

};

// Mandate creation 
export const _createMandate = async (param) => {

    let is_post = true;
    let url = "/mandate/check";
    let data = {
        "create": param.create,
        "platform": "web",
        "customer_id": param.customer_id
    };

    try {
        let response = await service(is_post, url, data);
        return response;
    } catch (error) {
        return error;
    }

};

// Goal summary 
export const _goalSummary = async (param) => {

    let is_post = true;
    let url = "/goal/summary";
    let data = {
        "goal_id": param.goal_id,
        "type": null,
        "customer_id": param.customer_id
    };

    try {
        let response = await service(is_post, url, data);
        return response;
    } catch (error) {
        return error;
    }

};

// Basket summary 
export const _basketSummary = async (param) => {

    let is_post = true;
    let url = "/goal/basket-summary";
    let data = {
        "basket_id": param.basket_id,
        "type": null,
        "customer_id": param.customer_id
    };

    try {
        let response = await service(is_post, url, data);
        return response;
    } catch (error) {
        return error;
    }

};

// Basket summary 
export const _mfSummary = async (param) => {

    let is_post = true;
    let url = "/goal/mf-summary";
    let data = {
        "mf_id": param.mf_id,
        "type": null,
        "customer_id": param.customer_id
    };

    try {
        let response = await service(is_post, url, data);
        return response;
    } catch (error) {
        return error;
    }

};