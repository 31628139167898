/*
 *   File : edit-profile-log.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : onboarding users log details ;
 *   Integrations :21-02-2024
 *   Version : v1.0
 *   Created : 21-02-2024
 */

import { useState } from "react";
import { DataTable } from "../../Components/DataFormating";

function EditProfileLog() {
    // TABLE DATA
    const COLUMNS = [
        {
            name: 'Date & time',
            // selector: row => row["date_time"],
            selector: row => new Date(row["date_time"]).valueOf(),
            sortable: true,
            minWidth: "200px",
            cell: row => (
                <span>{row["date_time"]}</span>
            ),

        },
        {
            name: 'Edited by',
            selector: row => row["edited_by"].toLowerCase(),
            sortable: true,
            cell: row => (
                <span data-tag="allowRowEvents">{row["edited_by"]}</span>
            ),
        },
        {
            name: 'Edited field',
            selector: row => row["edited_field"],
        },
        {
            name: 'Previous value',
            selector: row => row["previous_value"],
            minWidth: "250px",
        },
        {
            name: 'edited_value',
            selector: row => row["edited_value"],
            minWidth: "250px",
        },
    ];

    const DATA = [
        {
            "date_time": "29 Jan 2024, 10:00 am",
            "edited_by": "Admin",
            "edited_field": "Phone",
            "previous_value": "xyz1@gmail.com",
            "edited_value": "xyz3@gmail.com",
        },
        {
            "date_time": "1 Feb 2024, 10:00 am",
            "edited_by": "User",
            "edited_field": "Phone",
            "previous_value": "xyz1@gmail.com",
            "edited_value": "xyz3@gmail.com",
        },
        {
            "date_time": "2 Jan 2024, 10:00 am",
            "edited_by": "Admin",
            "edited_field": "Phone",
            "previous_value": "xyz1@gmail.com",
            "edited_value": "xyz3@gmail.com",
        },
        {
            "date_time": "3 Feb 2024, 10:00 am",
            "edited_by": "User",
            "edited_field": "Phone",
            "previous_value": "xyz1@gmail.com",
            "edited_value": "xyz3@gmail.com",
        },
    ]

    const [editLog, setEditLog] = useState(DATA);

    const _handleSelectLog = () => {

    };

    return (
        <div>
            <div id="data-table"
                className="bg-white mt-4 e-border-radius-16 overflow-hidden">
                <p className="e-filter-label px-4 mt-3 mb-0">
                    Edit log
                </p>
                <DataTable
                    pagination={true}
                    columns={COLUMNS}
                    data={editLog}
                    perPage={30}
                    // clearSelectedRows={clearSelected ? true : false}
                    selectableRows={true}
                    selectRows={_handleSelectLog}
                // rowClick={(row) => navigate(`/onboarding-users-detail?id=${row.user_id}`)}
                />
            </div>
        </div>
    );
};

export default EditProfileLog;