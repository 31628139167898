/*
 *   File : order-status.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Order status modal
 *   Integrations :03-05-2024
 *   Version : v1.0
 */

import { memo, useState } from "react";
import IcoMoon from "react-icomoon";
import iconSet from "../../Assets/Images/Icons/selection.json";
import { toast } from "react-toastify";

// Assets
import WhatsApp from "../../Assets/Images/General/whatsApp.png";


function OrderStatusModal(props) {

    const [isCopied, setIsCopied] = useState(false);

    const _handleCopyLink = (type) => {
        const URL = type === "MANDATE" ? props.mandateURL : props.paymentURL;

        navigator.clipboard.writeText(URL)
            .then(() => {
                setIsCopied(true);
            })
            .catch(() => {
                setIsCopied(false);
                toast.dismiss();
                toast.success("Failed to copy URL, try again");
            });

        // Allowing user to copy it again
        setTimeout(() => {
            setIsCopied(false);
        }, 10000);
    };

    const _handleShareLink = (type) => {
        const url = type === "MANDATE" ? props.mandateURL : props.paymentURL;
        window.open('https://web.whatsapp.com/send?phone=' + props.userData.country_code + props.userData.phone + `&text=${encodeURIComponent(url)}`, '_blank');
    };

    return (
        <div className="modal fade"
            data-bs-backdrop="static"
            id="order-status-modal"
            tabIndex="-1"
            aria-labelledby="order status modal"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content e-border-radius-8 p-4" >
                    <div className="modal-header p-0 justify-content-end">
                        <button type="button"
                            id='mf-invest-modal-close'
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={props.onClose}></button>
                    </div>
                    {
                        props.paymentURL ?
                            <div className="modal-body px-0 pb-0 pt-0  text-center d-flex flex-column gap-3">
                                <h6 className='mb-0 e-text-chinese-black e-font-18 e-font-manrope e-font-weight-700 line-height-18px'>
                                    Payment URL successfully generated
                                </h6>
                                <p className='mb-0 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-500 line-height-24px'>
                                    You can share the URL with the customer using the following options
                                </p>
                                <div className="d-flex gap-3 justify-content-center py-2 e-padding-lr-12px e-border-radius-8">
                                    <span className='e-border-radius-8 e-border-otp p-3 cursor-pointer col-auto d-flex align-items-center gap-2 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-600 line-height-18px'
                                        onClick={_handleCopyLink}>
                                        <IcoMoon iconSet={iconSet}
                                            icon={"copy-icon"}
                                            size={"1.125rem"} />
                                        {isCopied ? "Copied" : "Copy link"}
                                    </span>
                                    <span className="e-text-chinese-black e-font-14 e-font-manrope e-font-weight-600 line-height-18px e-border-radius-8 e-border-otp p-3 cursor-pointer col-auto d-flex align-items-center gap-2 "
                                        onClick={_handleShareLink}>
                                        <img src={WhatsApp}
                                            alt="whatsApp icon"
                                            draggable="false"
                                            width={20}
                                            height={20} />
                                        Share to WhatsApp
                                    </span>
                                </div>
                            </div>
                            : props.mandateURL ?
                                <div className="modal-body px-0 pb-0 pt-0  text-center d-flex flex-column gap-3">
                                    <h6 className='mb-0 e-text-chinese-black e-font-18 e-font-manrope e-font-weight-700 line-height-18px'>
                                        Mandate URL successfully generated
                                    </h6>
                                    <p className='mb-0 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-500 line-height-24px'>
                                        You can share the URL with the customer using the following options
                                    </p>
                                    <div className="d-flex gap-3 justify-content-center py-2 e-padding-lr-12px e-border-radius-8">
                                        <span className='e-border-radius-8 e-border-otp p-3 cursor-pointer col-auto d-flex align-items-center gap-2 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-600 line-height-18px'
                                            onClick={() => _handleCopyLink("MANDATE")}>
                                            <IcoMoon iconSet={iconSet}
                                                icon={"copy-icon"}
                                                size={"1.125rem"} />
                                            {isCopied ? "Copied" : "Copy link"}
                                        </span>
                                        <span className="e-text-chinese-black e-font-14 e-font-manrope e-font-weight-600 line-height-18px e-border-radius-8 e-border-otp p-3 cursor-pointer col-auto d-flex align-items-center gap-2 "
                                            onClick={() => _handleShareLink(("MANDATE"))}>
                                            <img src={WhatsApp}
                                                alt="whatsApp icon"
                                                draggable="false"
                                                width={20}
                                                height={20} />
                                            Share to WhatsApp
                                        </span>
                                    </div>
                                </div>
                                :
                                <div className="modal-body px-0 pb-0 pt-0 text-center d-flex flex-column gap-3">
                                    <h6 className='mb-0 e-text-chinese-black e-font-18 e-font-manrope e-font-weight-700 line-height-18px'>
                                        SIP Scheduled successfully
                                    </h6>
                                    <p className='mb-0 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-500 line-height-24px'>
                                        Starting next month, the AMC will deduct the amount from the user's bank account using the mandate.                                </p>
                                </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default memo(OrderStatusModal);