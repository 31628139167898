/*
 *   File : phone-country-select.js
 *   Author : https://evoqins.com
 *   Description : phone-country-select modal
 *   Integrations : NA
 *   Version : 1.0.0
 *   Date : 25-05-2024
*/

import { memo, useEffect, useState } from 'react';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import countryNames from 'react-phone-number-input/locale/en';

// Components
import IcoMoon from "react-icomoon";
import iconSet from "../../Assets/Images/Icons/selection.json";
import { CustomTextInput } from '../Form-Elements';
import { OverLay } from '../Other';

function PhoneCountrySelectModal({ value, handleCountrySelect, disableCountrySelect, selectedCountry }) {

    // Creating country object
    let country_list = getCountries().map((country, index) => (
        {
            id: index + 1,
            country_name: countryNames[country],
            country_locale: country,
            country_code: `+${getCountryCallingCode(country)}`,
            flag: `https://purecatamphetamine.github.io/country-flag-icons/3x2/${country}.svg`,
        }
    ));

    const [countries, setCountries] = useState(country_list);
    const [searchValue, setSearchValue] = useState("");
    const [showPhoneCountryDropDown, setShowPhoneCountryDropDown] = useState(false);

    useEffect(() => {
        if (showPhoneCountryDropDown) {
            document.querySelector("#custom-phone-otp").scrollIntoView({ behavior: "smooth", block: "start" });
        }
    }, [showPhoneCountryDropDown]);

    useEffect(() => {

        if (searchValue == "") {
            setCountries(country_list);
        } else {
            if (searchValue.length > 2) {
                let filtered_countries = countries.filter(country => (
                    country.country_name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
                ));
                setCountries(filtered_countries);

            }
        }

    }, [searchValue]);

    // handle country select
    const _handleCountrySelect = (country) => {
        handleCountrySelect(country);
        setShowPhoneCountryDropDown(false);
        setSearchValue("");
    };

    //handle search
    const _handleSearch = (value) => {
        if (value.trim().length == 0) {
            setSearchValue("");
            return;
        };
        setSearchValue(value);
    };

    // handle modal close
    const _handleCloseModal = () => {
        setShowPhoneCountryDropDown((prev) => !prev);
        setSearchValue("");
    };

    // handle clear search
    const _handleClearSearch = () => {
        setSearchValue("");
    };


    return (
        <div className={disableCountrySelect ? "pe-none" : ""}>
            {/* selected Country */}
            <span className="cursor-pointer d-flex me-2 gap-0 align-items-center ps-2 ps-sm-3 e-font-14"
                onClick={_handleCloseModal}>
                {selectedCountry}
                <IcoMoon iconSet={iconSet}
                    icon="phone-dropdown-arrow"
                    id='phone-dropdown-arrow'
                    className={`${showPhoneCountryDropDown ? "rotate-min-180deg " : ""} opacity-75 e-transition-all`}
                    size={20} />
            </span>

            {/* Country dropdown modal */}
            {
                showPhoneCountryDropDown &&
                <>
                    <OverLay isOpen={true}
                        onClose={() => setShowPhoneCountryDropDown(false)} />
                    <div className="z-index-1100 position-absolute  start-0 top-100 p-3 pt-0 e-modal-box-shadow mt-2 e-border-radius-8  w-100 bg-white">
                        <div className="position-relative">
                            <div className="position-sticky top-0 bg-white mb-1">
                                <h3 className="bg-white py-3 mb-3 e-border-bottom-1px-platinum-gray d-flex justify-content-between align-items-center e-text-chinese-black e-font-manrope e-font-weight-600 e-font-14 line-height-24px">
                                    Select your country
                                    <IcoMoon iconSet={iconSet}
                                        icon="close-btn"
                                        size={24}
                                        className="cursor-pointer"
                                        onClick={() => setShowPhoneCountryDropDown(false)} />
                                </h3>
                                <div className="position-relative mt-3">
                                    <CustomTextInput id="search-phone-country"
                                        type="text"
                                        className="w-100 outline-none py-2 px-4 me-2 ms-3"
                                        placeholder="Search by country name"
                                        inputWrapperClassName="e-border-1px-bright-gray"
                                        inputWrapperBorderStyle="e-border-radius-8"
                                        height="auto"
                                        value={searchValue}
                                        handleChange={_handleSearch} />

                                    <IcoMoon iconSet={iconSet}
                                        icon={"search"}
                                        size={20}
                                        className='position-absolute top-0 margin-top-12px  start-0 ms-2 ms-sm-2' />
                                    {
                                        searchValue &&
                                        <IcoMoon iconSet={iconSet}
                                            icon={"close-btn"}
                                            size={24}
                                            className="z-3 bg-white object-fit-contain position-absolute top-0 margin-top-8px end-0 me-2 cursor-pointer"
                                            onClick={_handleClearSearch} />
                                    }
                                </div>
                            </div>

                            <div className="h-240px bg-white overflow-auto no-scrollbar">
                                {
                                    countries.length !== 0 ?
                                        countries.map((country, index) => (
                                            <div key={index + country}
                                                className="cursor-pointer e-padding-tb-12px e-border-bottom-1px-platinum-gray d-flex justify-content-between align-content-center"
                                                onClick={() => _handleCountrySelect(country.country_locale)}>
                                                <div className="d-flex gap-2 align-items-center">
                                                    <img className="object-fit-contain e-border-radius-4"
                                                        src={country.flag}
                                                        alt="country flag"
                                                        draggable="false"
                                                        width={32} />
                                                    <span className="e-text-black e-font-manrope e-font-weight-400 e-font-14 line-height-22px ">
                                                        {country.country_name}({country.country_locale})
                                                    </span>
                                                </div>
                                                <span className="e-text-black e-font-manrope e-font-weight-400 e-font-14 line-height-22px">
                                                    {country.country_code}
                                                </span>
                                            </div>
                                        ))
                                        :
                                        <p className='mt-5 text-center e-text-black e-font-manrope e-font-weight-500 e-font-14 line-height-22px'>
                                            No countries found!
                                        </p>
                                }
                            </div>

                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default memo(PhoneCountrySelectModal);