/*
 *   File : support-ticket-details.js
 *   Author : https://evoqins.com
 *   Description : support ticket close modal
 *   Integrations :
 *   Version : 1.0.0
*/

import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';

// Components
import { TextArea } from '../Input';
import { PrimaryBtn, SecondaryBtn } from '../Cta';
import { _closeModal } from '../../Helper/function';
import service from '../../Services/service';

/*import styles*/

function SupportTicketDetail(props) {

    const [message, setMessage] = useState("");

    // Closing modal when "ESC" pressed
    useEffect(() => {
        _closeModal(props.onClose);
    }, []);

    const _closeTicket = () => {
        const url = '/support/close-ticket';
        const data = {
            "ticket_id": Number(props.ticket.id),
            "remarks": message || null
        };

        service(true, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("Ticket closed successfully");
                let close_btn = document.querySelector("#close-modal");
                close_btn.click();
                props.onClose("SUCCESS");
            }
            else {
                toast.dismiss();
                toast.error(response.data.message);
            }
        });
    };

    return (
        <div className="modal fade mx-auto"
            id="support-ticket-modal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="success"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                {
                    props.ticket.status == "Open" ? (

                        <div className="modal-content">
                            <div className="modal-header px-2 pt-2 pb-1">
                                <h6>Close ticket</h6>
                                <button type="button"
                                    id='close-modal'
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={props.onClose}></button>
                            </div>
                            <div className="modal-body pt-0 px-2 pb-2">
                                <div className="col-lg-12">
                                    {
                                        props.ticket.status == "Open" ? (
                                            <>
                                                <label>Message</label>
                                                <TextArea rows="4"
                                                    placeholder="Write your message here"
                                                    value={message}
                                                    valueChange={(value) => setMessage(value)}
                                                    type="text" />
                                            </>
                                        ) : (
                                            <div className='mt-3'>
                                                <h6>Remarks</h6>
                                                <p className='e-font-14'>{props.ticket.remarks || "No remarks"}</p>
                                            </div>
                                        )
                                    }

                                </div>
                                <div className='col-12 d-flex gap-3 pt-3 mt-2  justify-content-end'>
                                    <SecondaryBtn name="Cancel"
                                        className={`col-4 py-2`}
                                        cancel="modal"
                                        handleClick={props.onClose} />
                                    <PrimaryBtn name="Submit"
                                        className="col-4 e-modal-submit"
                                        handleClick={_closeTicket} />
                                </div>
                            </div>
                        </div>

                    ) : (
                        <div className="modal-content p-0 ">
                            <div className="modal-header  px-4 pt-2 pb-1 my-5">
                                <h6 className='mb-0'>{props.ticket.remarks}</h6>
                                <button type="button"
                                    className="ps-2 ms-auto btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={props.onClose}></button>
                            </div>
                        </div>
                    )
                }

            </div>
        </div >
    )
}

export default SupportTicketDetail;
