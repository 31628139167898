/*
 *   File :add-bank.js
 *   Author : https://evoqins.com
 *   Description : Add bank page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'bootstrap';

//custom component
import { CustomTextInput, FilePicker, RadioGroup } from '../../../Components/Form-Elements';
import { SelectionSuggestModal } from '../../../Components/Modal';
import { _checkFileSize, _getSignupProgress } from '../../../Helper';
import { Loader } from '../../../Components/Loader';
import { PrimaryBtn } from '../../../Components/Cta';
import { toast } from 'react-toastify';
import service from '../../../Services/service';
import awsService from '../../../Services/aws-service';


const AddBank = (props) => {

    //get general data from redux
    const GENERAL_DATA = useSelector((store) => store.INDEX_FUND_STORE.GENERAL_DATA);
    const [generalData, setGeneralData] = useState(GENERAL_DATA);
    const [progressData, setProgressData] = useState("");

    const [customerId, setCustomerId] = useState(props.customerId);
    const [accountNumber, setAccountNumber] = useState("");
    const [accountNumberError, setAccountNumberError] = useState("");
    const [reConfirmAccountNumber, setReConfirmAccountNumber] = useState("");
    const [reConfirmAccountNumberError, setReConfirmAccountNumberError] = useState("");
    const [holderName, setHolderName] = useState("");
    const [holderNameError, setHolderNameError] = useState("");
    const [ifsc, setIfsc] = useState("");
    const [ifscError, setIfscError] = useState("");
    const [accountType, setAccountType] = useState(40); // update later

    const [bankProofFile, setBankProofFile] = useState(null);
    const [bankProofFileError, setBankProofFileError] = useState("");
    const [bankProofViewUrl, setBankProofViewUrl] = useState(null);

    const [selectedBank, setSelectedBank] = useState("");
    const [selectedBranch, setSelectedBranch] = useState("");
    const [bankList, setBankList] = useState([]);
    const [branchList, setBranchList] = useState([]);
    const [branchListLoading, setBranchListLoading] = useState(true);
    const [showBankSelectModal, setShowBankSelectModal] = useState(false);
    const [showBranchSelectModal, setShowBranchSelectModal] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(true);
    const [showSuggestModal, setShowSuggestModal] = useState(false);
    const [bankTypes, setBankTypes] = useState([]);

    useEffect(() => {

        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });

        // API call - get bank list
        _getBankList();
    }, []);

    useEffect(() => {
        setCustomerId(props.customerId);
    }, [props.customerId]);

    useEffect(() => {
        if (customerId) {
            setLoadingData(true);
            _getSignupProgress(customerId).then(response => {
                if (response.status_code === 200) {
                    setProgressData(response.data);
                } else {
                    setProgressData("");
                }
            });
        }
    }, [customerId]);

    useEffect(() => {

        if (progressData) {

            if (Object.hasOwn(progressData.kyc_data.bank_details, "account_number")) {

                setAccountNumber(progressData.kyc_data.bank_details.account_number);
                setReConfirmAccountNumber(progressData.kyc_data.bank_details.account_number);
                setHolderName(progressData.kyc_data.bank_details.account_holder_name);
                setIfsc(progressData.kyc_data.bank_details.ifsc);

                if (progressData.kyc_data.bank_details.account_type_id !== null) {
                    setAccountType(progressData.kyc_data.bank_details.account_type_id);
                }

            } else {
                setAccountType(generalData.bank_account_types[0].id);
            }

            if (progressData.kyc_data.bank_proof_url !== null) {
                let bank_url = progressData.kyc_data.bank_proof_url;
                let updated_bank = bank_url.split("/").pop().split("?")[0];

                setBankProofFile(updated_bank);
                setBankProofViewUrl(progressData.kyc_data.bank_proof_url);
            }

            setLoadingData(false);
        }


    }, [progressData]);

    useEffect(() => {
        setGeneralData(GENERAL_DATA);
    }, [GENERAL_DATA]);

    //===== Opening Modals ====== //

    useEffect(() => {

        if (showSuggestModal === true) {
            let proof_modal = new Modal(document.getElementById('select-account-proof-modal'));
            proof_modal.show();
        };

    }, [showSuggestModal]);

    // Open bank select modal
    useEffect(() => {
        if (showBankSelectModal) {
            let suggestion_modal = new Modal(document.getElementById('select-bank-name-modal'));
            suggestion_modal.show();
        }
    }, [showBankSelectModal]);

    // Open bank branch select modal
    useEffect(() => {
        if (showBranchSelectModal) {
            let suggestion_modal = new Modal(document.getElementById('select-bank-branch-modal'));
            suggestion_modal.show();
        }
    }, [showBranchSelectModal]);

    useEffect(() => {
        setAccountNumberError("");
    }, [accountNumber]);

    useEffect(() => {
        setHolderNameError("");
    }, [holderName]);

    useEffect(() => {
        setIfscError("");
    }, [ifsc]);

    useEffect(() => {
        setBankProofFileError("");
    }, [bankProofFile]);


    const _handleAccountNumberChange = (value) => {
        if (value.length <= 18) {
            setAccountNumber(value);
        };
    };

    //handle account type
    const _handleAccountType = (id) => {
        setAccountType(id)
    }

    // handle confirm account number change
    const _handleReconfirmACNumber = (e) => {
        if (e.length < 19) {
            setReConfirmAccountNumber(e);
            setReConfirmAccountNumberError("");
        }
    };

    // handle change IFSC code
    const _handleChangeIFSCCode = (value) => {
        setIfsc(value.toUpperCase());
    };

    // handle open branch select modal
    const _handleOpenBankSelectModal = () => {
        setShowBankSelectModal(true);
    };

    // handle bank select
    const _handleBankSelect = (selected_bank) => {
        setSelectedBank(selected_bank);
        setShowBankSelectModal(false);
        setBranchList([]);
        setBranchListLoading(true);
        // API call
        _getBranchList(selected_bank);
    };

    // handle branch select
    const _handleBranchSelect = (value) => {
        setSelectedBranch(value);
        setIfsc(value.ifsc);
        setShowBranchSelectModal(false);
    };

    // upload bank proof front image 
    const _handleUploadBankProof = (file) => {

        if (file.target.files.length !== 0) {

            const is_file_size_valid = _checkFileSize(file.target.files[0].size);

            if (is_file_size_valid === false) {
                setBankProofFileError("Maximum file size should be 5mb only");
                return;
            }

            _getPresignedUrl(file);
        };
    };

    //handle submit add bank
    const _handleSubmitAddBank = () => {

        let errors = [];
        let validation = true;

        if (accountNumber.length === 0) {
            setAccountNumberError("Enter account number");
            errors.push("account-number");
            validation = false;
        }
        if (reConfirmAccountNumber.length === 0) {
            setReConfirmAccountNumberError("Please confirm your account number");
            errors.push("confirm-account-number");
            validation = false;
        } else {
            if (accountNumber !== reConfirmAccountNumber) {
                setReConfirmAccountNumberError("Account number is not matching");
                errors.push("confirm-account-number");
                validation = false;
            }
        }

        if (holderName.length === 0) {
            setHolderNameError("Account holder name required");
            errors.push("account-holder");
            validation = false;
        }
        if (ifsc.length === 0) {
            setIfscError("Enter your IFSC");
            errors.push("ifsc-code");
            validation = false;
        }

        // ONLY FOR NON KYC USER
        if (progressData.kyc_user === false) {
            if (bankProofFile === null) {
                setBankProofFileError("Select bank account proof");
                errors.push("bank-account-proof-file");
                validation = false;
            }
        }

        if (validation) {
            setLoading(true);
            _addBank();
        };

        // Scrolling to first error
        if (errors.length !== 0) {
            let first_err_id = errors[0];
            let element = document.getElementById(first_err_id);
            element.scrollIntoView({ behavior: "smooth" });
        };

    };


    //========== API CALLS ============= // 

    //get presigned url
    async function _getPresignedUrl(file) {

        let is_post = true;
        let url = '/file/upload';
        let data = {
            "file": file.target.files[0].name,
            "is_public": true,
            "customer_id": customerId
        };
        try {
            let response = await service(is_post, url, data);
            let aws_URL = '';
            if (response.status_code == 200) {

                aws_URL = response.data.upload_info.url;
                let view_url = response.data.view_info;

                let file_param = new File(
                    [file.target.files[0]],
                    file.target.files[0].name,
                    { type: file.target.files[0].type }
                );

                try {
                    let awsResponse = await awsService(response.data.upload_info.fields, file_param, aws_URL);
                    if (awsResponse.status == 'ok') {
                        setBankProofViewUrl(view_url);
                        setBankProofFile(file.target.files[0].name);
                    } else {
                        toast.dismiss();
                        toast.error(awsResponse.message, { className: "e-toast" });
                    }
                } catch (err) {
                    toast.dismiss();
                    toast.error(err.message, { className: "e-toast" });
                }
            } else {
                toast.dismiss();
                toast.error("Something went wrong please try again", { className: "e-toast" });
            }
        } catch (err) {
            toast.dismiss();
            toast.error("Something went wrong please try again", { className: "e-toast" });
        }
    };

    // API - Search bank
    const _getBankList = () => {
        let is_post = true;
        let url = '/general/banks';
        let data = {
            "query": null
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                // Adding label prop for search options 
                let search_results = response.data.map(item => {
                    return {
                        ...item,
                        id: item.id,
                        label: item.name,
                        value: item.name
                    }
                });

                setBankList(search_results);
                setBranchList([]);
            } else {
                setBankList([]);
                setBranchList([]);
            }
        });

    };

    // API - Search branch 
    const _getBranchList = (selected_bank) => {
        let is_post = true;
        let url = '/general/branches';
        let data = {
            "bank_id": selected_bank.id,
            "query": ""
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                // Adding label prop for search options 
                let search_results = response.data.map(item => {
                    return {
                        ...item,
                        label: item.name,
                        id: item.name,
                        value: item.name
                    }
                });
                setBranchList(search_results);
                setShowBranchSelectModal(true);

            } else {
                setBranchList([]);
            }

            setBranchListLoading(false);

        });

    };

    //API- Add bank
    const _addBank = () => {

        if (progressData.kyc_user === false && bankProofFile === null) {
            setLoading(false);
            return;
        };

        let is_post = true;
        let url = '/kyc/save-bank';
        let data = {
            "customer_id": customerId,
            "account_number": accountNumber,
            "account_holder_name": holderName,
            "account_type": accountType,
            "ifsc": ifsc,
            "bank_proof_url": bankProofViewUrl
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("Bank details saved successfully");
                props.onUpdateStep(6, customerId);
            } else {
                toast.dismiss();
                toast.error(response.data.message);
            }

            setLoading(false);
        });
    };

    return (
        <>
            {
                loadingData === false ? (
                    <div className='px-0 pb-4'>
                        <div className='col-xl-6 col-lg-7 col-sm-10 col-12'>
                            <div className='padding-26px-top'>
                                <img src={require("../../../Assets/Images/Kyc/left-arrow.svg").default}
                                    draggable={false}
                                    className='cursor-pointer'
                                    alt='arrow'
                                    onClick={() => props.onUpdateStep(4)} />
                            </div>
                            {/* step flow */}
                            <div className='position-relative'>
                                <div className='e-bg-lavender h-6 mt-4 e-border-radius-16'></div>
                                <div className='position-absolute h-6 e-bg-header-bg e-border-radius-16 top-0'
                                    style={{ width: `${(100 / progressData.step_detail.length) * (progressData.kyc_data.is_kyc_compliant ? 4 : 5)}%` }}></div>
                                <p className='mb-0 text-end pt-1 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-400 line-height-24px'>
                                    Step {progressData.kyc_data.is_kyc_compliant ? 4 : 5} of {progressData.step_detail.length}
                                </p>
                            </div>

                            {/* Bank account details */}
                            <div className='pt-3'>
                                <h3 className='mb-4 e-text-chinese-black e-font-18 e-font-manrope e-font-weight-600 line-height-24px'>
                                    Bank account details
                                </h3>
                                {/* Account number */}
                                <div id="account-number" className='h-90 mb-2'>
                                    <CustomTextInput label="Account number"
                                        require
                                        placeholder="99980107428"
                                        maxLength={18}
                                        value={accountNumber}
                                        errorMsg={accountNumberError}
                                        handleChange={_handleAccountNumberChange} />
                                </div>

                                <div id="confirm-account-number" className='h-90 mb-2'>
                                    <CustomTextInput label="Confirm account number"
                                        placeholder="99980107428"
                                        require
                                        type="password"
                                        maxLength={18}
                                        value={reConfirmAccountNumber}
                                        errorMsg={reConfirmAccountNumberError}
                                        className="w-100 px-3"
                                        labelStyle="e-font-weight-500"
                                        handleChange={_handleReconfirmACNumber} />
                                </div>

                                {/* holder name */}
                                <div id="account-holder" className='h-90 mb-2'>
                                    <CustomTextInput label="Account holder name"
                                        require
                                        placeholder="Jhon Doe"
                                        type="text"
                                        value={holderName}
                                        errorMsg={holderNameError}
                                        className="w-100 px-3 padding-12px-top padding-12px-bottom"
                                        labelStyle="e-font-weight-500"
                                        handleChange={(e) => {
                                            setHolderName(e);
                                        }} />
                                </div>

                                {/* Account type select */}
                                <div className='pt-2 pb-4'>
                                    <h6 className='mb-3 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-500 line-height-24px'>
                                        Account type
                                        <span className='e-text-red'>*</span>
                                    </h6>
                                    <div className='d-flex gap-3 e-flex-wrap'>
                                        <RadioGroup data={generalData.bank_account_types}
                                            selected={accountType}
                                            handleSelect={_handleAccountType} />
                                    </div>
                                </div>

                                {/* IFSC code */}
                                <div id="ifsc-code" className='h-90 position-relative'>
                                    <CustomTextInput label="IFSC code"
                                        require
                                        placeholder="SBIN0015895"
                                        type="text"
                                        value={ifsc}
                                        errorMsg={ifscError}
                                        className="w-100 px-3 padding-12px-top padding-12px-bottom"
                                        labelStyle="e-font-weight-500"
                                        handleChange={_handleChangeIFSCCode} />
                                    <span className="text-decoration-hover-underline cursor-pointer position-absolute top-90 end-0 e-text-primary e-font-manrope e-font-weight-500  e-font-14 link-offset-1"
                                        onClick={_handleOpenBankSelectModal}>
                                        Find IFSC Code
                                    </span>
                                </div>

                                {
                                    progressData.kyc_user === false &&
                                    <>
                                        {/* upload Cancelled cheque proof*/}
                                        <div id="bank-account-proof-file" className='mt-3'>
                                            <label className="mb-3 e-text-dark-charcoal e-font-weight-500 e-font-manrope line-height-18px e-font-14 mb-2">
                                                Cancelled cheque image
                                                <span className='e-text-red'>*</span>
                                                (max.5mb)
                                            </label>
                                            {/* Cancelled cheque  */}
                                            {bankProofFile &&
                                                <div className='e-bg-anti-flash-white e-border-radius-12 px-3 mb-3'>
                                                    <div className='d-flex justify-content-between py-1'>
                                                        <p className='mb-0 py-2 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-400 line-height-24px'>
                                                            {bankProofFile}
                                                        </p>
                                                        <img src={require("../../../Assets/Images/Kyc/selected-close.svg").default}
                                                            draggable={false}
                                                            className='cursor-pointer'
                                                            alt='delete'
                                                            onClick={() => setBankProofFile(null)} />
                                                    </div>
                                                </div>
                                            }

                                            <FilePicker id="bank-proof"
                                                label="Click here to upload(PNG or JPG)"
                                                type="file"
                                                className="w-100"
                                                accept=".png, .jpg"
                                                currentFile={bankProofFile}
                                                error={bankProofFileError}
                                                selectFile={_handleUploadBankProof} />
                                        </div>
                                    </>
                                }

                                {/* button */}
                                <div className='pt-4 mt-2'>
                                    <PrimaryBtn className={loading ? "e-padding-tb-12px w-40per" : "e-padding-tb-15px w-40per"}
                                        loading={loading}
                                        name="Save & Continue"
                                        handleClick={_handleSubmitAddBank} />
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="min-h-100vh text-center mt-5" >
                        <Loader />
                    </div>
                )
            }


            {/* Bank search and select modal */}
            {
                showBankSelectModal && bankList.length > 0 &&
                <SelectionSuggestModal title="Select bank"
                    id='select-bank-name-modal'
                    type={1}
                    search={true}
                    searchPlaceholder="Search bank"
                    selectedOption={selectedBank}
                    data={bankList}
                    onModalClose={() => setShowBankSelectModal(false)}
                    onSelectOption={_handleBankSelect} />
            }

            {/* Bank branch select modal */}
            {
                showBranchSelectModal &&
                <SelectionSuggestModal title="Select branch"
                    id='select-bank-branch-modal'
                    type={2}
                    hasLimit
                    search={true}
                    searchPlaceholder="Search branch"
                    selectedOption={selectedBranch}
                    data={branchList}
                    loading={branchListLoading}
                    onModalClose={() => setShowBranchSelectModal(false)}
                    onSelectOption={_handleBranchSelect} />
            }

        </>
    )
}

export default AddBank;