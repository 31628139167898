/*
 *   File : summary.js
 *   Author : https://evoqins.com
 *   Description : kyc summary page
 *   Integrations : NA
 *   Version : 1.0.0
*/

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';

// Components
import { PrimaryBtn } from '../../../Components/Cta';
import { VerifyEmailModal } from '../../../Components/Modal';
import { Loader } from '../../../Components/Loader';
import { _getSignupProgress } from '../../../Helper';

// Assets
import KYCProfile from "../../../Assets/Images/Kyc/kyc-profile.svg";
import KYCError from "../../../Assets/Images/Kyc/kyc-err.svg";
import KYCChecklist from "../../../Assets/Images/Kyc/checklist.svg";
import LeftArrow from "../../../Assets/Images/Kyc/left-arrow.svg";

const Summary = (props) => {

    const navigate = useNavigate();
    const [progressData, setProgressData] = useState("");
    const [customerId, setCustomerId] = useState(props.customerId);
    const [kycError, setKycError] = useState([]);
    const [showEmailVerifyModal, setShowEmailVerifyModal] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    useEffect(() => {

        window.scrollTo({
            top: 0, behavior: 'smooth' //scroll top
        });

    }, []);

    useEffect(() => {
        setCustomerId(props.customerId);
    }, [props.customerId]);

    useEffect(() => {
        if (customerId) {
            setLoadingData(true);
            _getSignupProgress(customerId).then(response => {
                if (response.status_code === 200) {
                    setProgressData(response.data);
                } else {
                    setProgressData("");
                }
            });
        }
    }, [customerId]);

    // Updating kyc errors
    useEffect(() => {
        if (progressData) {
            let kyc_errors = progressData.step_detail.filter(item => item.error === true).map((item) => {
                return { error: item.message };
            }).flatMap((item => {
                return [...item.error]
            }));
            setKycError(kyc_errors);
            setLoadingData(false);
        }
    }, [progressData]);

    // Open phone verify modal
    useEffect(() => {
        if (showEmailVerifyModal) {
            new Modal(document.querySelector("#verify-email-modal")).show();
        }
    }, [showEmailVerifyModal]);

    //Email verify modal
    const _handleOpenVerify = () => {
        if (progressData.kyc_data.is_email_verified === false) {
            setShowEmailVerifyModal(true);
        } else {
            // 1. progressData.step_detail[0] = Pan related
            // 2. If we get any error in this step we need to navigate to pan page
            // 3. Other wise continue the flow 1 => 2 => 3 ....
            if (progressData.step_detail[0].error == true) {
                // 0 - will be pan verifying step
                let is_pan_error = true;
                props.onUpdateStep(0, customerId, is_pan_error);
            } else {
                // 2 - will be personal detail step
                props.onUpdateStep(2, customerId);
            };
        }
    };

    // handle back
    const _handleBack = () => {
        navigate("/onboarding-users");
    };

    // Handle update step
    const _handleUpdateStep = (email_otp) => {
        setShowEmailVerifyModal(false);
        props.onUpdateStep(2, customerId, email_otp);
    };

    return (
        <>
            {
                loadingData == false ?
                    <div className='px-0 pb-5'>
                        <img src={LeftArrow}
                            draggable={false}
                            className='cursor-pointer mb-4'
                            alt='arrow'
                            onClick={_handleBack} />
                        {
                            kycError.length !== 0 ?
                                <div className="col-md-10 col-12">
                                    {/* KYC Errors */}
                                    <div className='e-bg-snow p-3 e-border-radius-16'>
                                        <div className='d-flex gap-2 mb-4'>
                                            <img src={KYCError}
                                                width={48}
                                                height={48}
                                                className='object-fit-contain e-border-radius-10 e-bg-white p-1'
                                                draggable={false}
                                                alt='profile' />
                                            <p className='mb-0 e-text-chinese-black e-font-16 e-font-manrope e-font-weight-600 line-height-28px'>
                                                Reason for rejection
                                            </p>
                                        </div>
                                        <div className='d-flex gap-4 flex-column'>
                                            {
                                                kycError.map((item, key) => (
                                                    <div key={key} className='d-flex gap-2'>
                                                        <p className='mb-0 e-text-chinese-black e-font-12 e-font-manrope e-font-weight-700 line-height-24px e-bg-misty-rose e-border-radius-6 px-2'>
                                                            {key + 1}
                                                        </p>
                                                        <p className='mb-0 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-500 line-height-20px'>
                                                            {item}
                                                        </p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                                :

                                <div className='col-md-10 col-12'>
                                    <h3 className='e-font-16-res line-height-22px-res mb-4 e-text-raisin-black e-font-18 e-font-manrope e-font-weight-700 line-height-26px'>
                                        Things to keep in mind when opening an account
                                    </h3>
                                    {/* investment steps */}
                                    <div className='e-bg-lavender p-3 e-border-radius-16'>
                                        <div className='d-flex gap-2 mb-4 align-items-center'>
                                            <img src={KYCProfile}
                                                draggable={false}
                                                alt='profile' />
                                            <p className='e-font-14-res line-height-22px-res mb-0  e-text-chinese-black e-font-16 e-font-manrope e-font-weight-700 line-height-28px'>
                                                Invest in {progressData.step_detail.length} simple steps
                                            </p>
                                        </div>
                                        <div className='d-flex gap-3 flex-wrap'>
                                            {
                                                progressData.step_detail.map((data, key) => (
                                                    <div key={key} className='d-flex gap-2'>
                                                        <p className='mb-0 e-text-chinese-black e-font-12 e-font-manrope e-font-weight-700 line-height-24px e-bg-lavender-blue e-border-radius-6 px-2'>
                                                            {data.step}
                                                        </p>
                                                        <p className='mb-0 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-500 line-height-20px'>
                                                            {data.step_name}
                                                        </p>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>

                                    {/* checklist */}
                                    <div className='mt-4 e-bg-anti-flash-white e-border-radius-16 p-3'>
                                        <div className='d-flex gap-2 align-items-center'>
                                            <img src={KYCChecklist}
                                                draggable={false}
                                                alt='checklist' />
                                            <h6 className='e-font-14-res line-height-22px-res mb-0 e-text-chinese-black e-font-manrope e-font-16 e-font-weight-700 line-height-24px'>
                                                Checklist
                                            </h6>
                                        </div>
                                        <p className='mb-0 pt-3 e-text-onyx e-font-14 e-font-manrope e-font-weight-400 line-height-22px'>
                                            Details below are needed for a smooth account opening process.
                                        </p>

                                        <div className={`pt-3 `}>
                                            {
                                                progressData.check_list.map((data, key) => (
                                                    <ul key={key} className='mb-0'>
                                                        <li className='pb-3 e-text-chinese-black e-font-14 e-font-manrope e-font-weight-500 line-height-24px'>
                                                            {data}
                                                        </li>
                                                    </ul>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }
                        {/* custom button */}
                        <div className='padding-32px-top'>
                            <PrimaryBtn name="Edit & Update"
                                className="px-4"
                                handleClick={_handleOpenVerify} />
                        </div>
                    </div >
                    :
                    <div className="min-h-100vh text-center pt-5" >
                        <Loader />
                    </div>
            }

            {
                showEmailVerifyModal &&
                <VerifyEmailModal edit={false}
                    customerId={customerId}
                    onSuccess={_handleUpdateStep}
                    onCloseModal={() => setShowEmailVerifyModal(false)} />
            }

        </>
    )
}

export default Summary;