/*
 *   File : phone-input.js
 *   Author : https://evoqins.com
 *   Description : Custom phone input with country select
 *   Integrations : NA
 *   Version : 1.0.0
*/

import React, { memo, useEffect, useState } from "react";
import PhoneInput, { getCountries } from 'react-phone-number-input';
import { getCountryCallingCode } from 'react-phone-number-input/input';

// Components
import { PhoneCountrySelectModal } from "../Modal";

const CustomPhoneInput = (props) => {


    // When admin resumes KYC flow for NRI
    // we need to know the country name => defaultCountry={"IN" || "US"} - should be dynamic
    // Here getCountries function used to create the array with country name data
    let country_list = getCountries().map((country) => ({
        country: country,
        country_code: `+${getCountryCallingCode(country)}`,
    }));

    let default_country = country_list.find(country => country.country_code === props.selectedCountryCode).country;

    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        setInputValue(props.value); //already entered number
    }, [props.value]);

    //input handle change
    function _handleChange(value) {
        setInputValue(value);
        props.handleChange(value);
    };

    // handle country select
    function _handleCountrySelect(country) {
        let country_code = "+" + getCountryCallingCode(country);
        props.handleChange("", country_code);
    };

    return (
        <div id="custom-phone-otp">
            {
                props.label &&
                <label className={`${props.labelStyle} e-text-chinese-black e-font-manrope e-font-weight-500  e-font-14 mb-2`}>
                    {props.label}
                    {props.require && <span className='e-text-red'>*</span>}
                </label>
            }
            <div className="position-relative">
                <PhoneInput
                    defaultCountry={default_country || "IN"}
                    value={inputValue}
                    limitMaxLength
                    onChange={_handleChange}
                    inputComponent={CustomInput}
                    numberInputProps={{ country_code: props.selectedCountryCode }}
                    onCountryChange={_handleCountrySelect}
                    countrySelectComponent={({ value, onChange }) => (
                        <PhoneCountrySelectModal value={value}
                            selectedCountry={props.selectedCountryCode}
                            disableCountrySelect={props.disableCountrySelect}
                            handleCountrySelect={onChange} />
                    )}
                />
                {
                    props.textLink &&
                    <p className={`cursor-pointer e-text-egyptian-blue me-3 mb-0 position-absolute end-0 top-50 translate-middle-y e-font-14 e-font-weight-500 e-alt-font-poppins line-height-24px`}
                        onClick={props.handleSendOtp}>
                        {props.textLabel}
                    </p>
                }
            </div>
            {/* Error text */}
            <p className={`${props.error === "" ? "opacity-0" : "opacity-100 "} h-16 mb-0 e-error-message e-font-12 line-height-12px pt-1`}>
                {props.error}
            </p>
        </div >
    )
}

export default memo(CustomPhoneInput);


const CustomInput = React.forwardRef(({ value, onChange, country_code }, ref) => {

    const _handleChange = (event) => {
        let value = event.target.value;
        if (Number(country_code) === 91) {
            if (value.length < 11) {
                onChange(value);
            }
        } else {
            onChange(value);
        }
    };

    // handle input focus 
    const _handleFocusInput = () => {
        document.querySelector(".PhoneInput").dataset.focus = true;
    };

    // handle input focus 
    const _handleBlurInput = () => {
        document.querySelector(".PhoneInput").dataset.focus = false;
    };

    return (
        <input
            ref={ref}
            placeholder="Enter phone number"
            value={value ? value.replace(country_code, '').replace(/\s+/g, '') : ""}
            onChange={_handleChange}
            className="h-100 w-100 custom-input"
            onFocus={_handleFocusInput}
            onBlur={_handleBlurInput}
        />
    )
})
