/*
 *   File : slider.js
 *   Author : https://evoqins.com
 *   Description : Custom slider component
 *   Integrations : react-slider
 *   Version : 1.0.0
*/

import { memo, useEffect, useState } from "react";

import ReactSlider from "react-slider";

const CustomSlider = (props) => {

    const [sliderValue, setSliderValue] = useState(props.defaultValue);

    useEffect(() => {
        setSliderValue(props.defaultValue);
    }, [props.defaultValue]);

    // slider change function
    function _handleSliderChange(value) {
        setSliderValue(value);
        props.handleChange(value);
    }

    // slider track click function
    function _handleSliderClick(value) {
        setSliderValue(value);
        props.handleChange(value);
    }

    return (
        <>
            <ReactSlider
                value={sliderValue}
                max={props.max}
                min={props.min}
                step={props.step}
                className="h-24 d-flex align-items-center"
                thumbClassName="slider-thumb"
                trackClassName="slider-track"
                onChange={_handleSliderChange}
                onSliderClick={_handleSliderClick} />
            {/* Label */}
            <div className="d-flex justify-content-between pt-1">
                <p className="mb-0 e-text-light-grey e-font-12 e-font-manrope e-font-weight-400 line-height-14px">
                    {props.min} {props.labelType === "Year" ? `${props.min === 1 ? "Yr" : "Yrs"}` : props.labelType === "Percentage" ? "%" : `${props.min === 1 ? "Month" : "Months"}`}
                </p>
                <p className="mb-0 e-text-light-grey e-font-12 e-font-manrope e-font-weight-400 line-height-14px">
                    {props.max} {props.labelType === "Year" ? "Yrs" : props.labelType === "Percentage" ? "%" : "Months"}
                </p>
            </div>
        </>
    )
};

export default memo(CustomSlider);