/*
 *   File : users.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Users file for users container;
 *   Integrations : 28-11-2023
 *   Version : v1.0
 *   Created : 28-11-2023
 */
/*import pakcages*/
import { useState, useEffect, useRef, } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

/*import components*/
import { DataTable } from '../../Components/DataFormating/index';
import { FilterBtn, DownloadBtn } from '../../Components/Cta/index';
import { DateRangePicker, SearchInput } from '../../Components/Input/index';
import SelectBox from '../../Components/Dropdown/select-box';
import { Loader } from '../../Components/Loader';
import { EmptyNote, Toast } from '../../Components/Note';
import { ConfirmationforBlock } from '../../Components/Modal';
import { Modal } from 'bootstrap';

/*import styles*/
import styles from '../../Styles/Components/search-input.module.scss';

/*import assets*/
import View from '../../Assets/Images/DataFormating/view.svg';
import BlockUser from '../../Assets/Images/General/block-user.png';
import UnBlockUser from '../../Assets/Images/General/unblock-user.png';

/* import services */
import service from '../../Services/service';



const STATUS_OPTIONS = [
	{
		value: null, label: 'All',
	},
	{
		value: true, label: 'Invested'
	},
	{
		value: false, label: 'Not yet invested'
	},
]

const Users = (props) => {

	const navigate = useNavigate();
	const wrapperRef = useRef(null);
	const userDataRef = useRef([])
	const [search, setSearch] = useState('');
	const [more, setMore] = useState(false);
	const [filterDown, setFilterDown] = useState(false);
	const [dates, setDates] = useState(null);
	const [refKey, setRefKay] = useState('');
	const [check, setCheck] = useState([])
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [userData, setUserData] = useState([]);
	const [error, setError] = useState(null);
	const [loader, setLoader] = useState(true);
	const [downloadLoader, setDownloadLoader] = useState(false);
	const [success, setSuccess] = useState(null);
	const [userId, setUserId] = useState(null);
	const [blockStatus, setBlockStatus] = useState(false);

	/* ---- columns for user table ---*/
	const USER_COLUMNS = [
		{
			name: 'Cust. ID',
			selector: row => row.user_id,
			sortable: true,
			left: true,
			minWidth: "120px",
			defaultSortAsc: false,
			cell: row => row.if_customer_id ? row.if_customer_id : "--"
		},
		{
			name: 'Name',
			selector: (row) => row.name ? row.name : '--',
			sortable: true,
			left: true,
			minWidth: '180px',
			cell: row => <>
				<div className='e-link link-offset-1 py-2' onClick={() => {
					navigate(`/user-detail?id=${row.user_id}`)
				}}>{row.name}</div>
			</>,
			sortFunction: (a, b) => _customStringSort(a, b, 'name',),
		},
		{
			name: 'Joined on',
			sortable: false,
			right: false,
			minWidth: '140px',
			cell: row => row.joined,
			defaultSortAsc: false,
			sortFunction: (a, b) => _customDateSort(a, b, 'joined',),
		},
		{
			name: 'AUM',
			selector: row =>
				row.aum ?
					row.aum
					:
					0
			,
			sortable: true,
			minWidth: "160px",
			right: false,
			cell: row => <>
				{
					row.aum ?
						"₹ " + row.aum.toLocaleString('en-IN')
						:
						"₹ 0"
				}
			</>
		},
		{
			name: 'Email',
			selector: row => row.email,
			cell: (row) => row.email ? row.email : '--',
			sortable: false,
			left: true,
			minWidth: "250px",
		},

		{
			name: 'Phone',
			cell: (row) => row.phone ? row.phone : '--',
			sortable: false,
			right: false,
			minWidth: "180px",
		},
		{
			name: 'Added by',
			selector: row => new Date(row["created_by"]).valueOf(),
			minWidth: "120px",
			sortable: true,
			cell: row => (
				<span>{row.created_by === null ? "User" : "Admin"}</span>
			),
		},
		{
			name: '',
			sortable: false,
			right: true,
			width: "100px !important;",
			cell: row => (
				<div ref={wrapperRef}>
					<div className="col-5"
						onMouseOver={() => { setRefKay(row.user_id); setMore(true); }}
						onMouseLeave={() => { setRefKay(row.user_id); setMore(false); }}>
						<span className="e-more" ></span>
						{
							more && row.user_id === refKey ?
								<div className="e-card" ref={wrapperRef} >
									<div onClick={() => navigate(`/user-detail?id=${row.user_id}`)}><img src={View} alt="View details" className="me-3" /><span>View details</span></div>
									{
										row.is_blocked === true ?
											<div className='mt-3' onClick={() => _openBlockUserModal(row)}>
												<img src={UnBlockUser} alt="Block" className="me-3" width={16} />
												Unblock user
											</div>
											:
											<div className='mt-3' onClick={() => _openBlockUserModal(row)}>
												<img src={BlockUser} alt="Block" className="me-3" width={16} />
												Block user
											</div>
									}
								</div>
								:
								null
						}
					</div>
				</div>
			)
		},
	];


	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});

		// filterData();

		document.addEventListener('mouseup', _closeMenu);
		return () => {
			document.removeEventListener('mouseup', _closeMenu);
		};
	}, []);

	useEffect(() => {
		if (sessionStorage.getItem("invested_status")) {
			setSelectedStatus(JSON.parse(sessionStorage.getItem("invested_status")));
			setFilterDown(true);
		} else {
			setSelectedStatus(STATUS_OPTIONS[0]);
		}
	}, []);

	useEffect(() => {
		if (sessionStorage.getItem("range")) {
			setDates(JSON.parse(sessionStorage.getItem("range")));
			setFilterDown(true);
		} else {
			setDates({
				start: null,
				end: null
			});
		}
	}, []);

	useEffect(() => {
		if (sessionStorage.getItem("user_search")) {
			setSearch(JSON.parse(sessionStorage.getItem("user_search")));
		} else {
			setSearch('');
		}
		//eslint-disable-next-line
	}, [sessionStorage.getItem("user_search")]);


	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null);
			}, 3000);
		}
	}, [error]);

	useEffect(() => {
		if (success !== null) {
			setTimeout(() => {
				setSuccess(null);
			}, 3000);
		}
	}, [success]);


	useEffect(() => {
		if (dates !== null && selectedStatus !== null) {
			_getUsersList();
		}

	}, [selectedStatus, dates]);

	const _customDateSort = (a, b, selector, given) => {
		if (selector === "joined") {
			let format = given ? given : "DD MMM YYYY"
			if (a[selector] && b[selector]) return moment(a[selector], format).valueOf() - moment(b[selector], format).valueOf();
			else if (a[selector]) return -1;
			else if (b[selector]) return 1;
			else return 0;
		}
	}

	const _customStringSort = (a, b, selector) => {
		if (selector === "name") {
			if (a[selector] && b[selector]) {
				let stringA = a[selector].toUpperCase().trim();
				let stringB = b[selector].toUpperCase().trim();
				if (stringA < stringB) return -1;
				else if (stringA > stringB) return 1;
				else return 0;
			}
			else if (a[selector]) return -1;
			else if (b[selector]) return 1;
			else return 0;
		}

	}



	/*--- block user---*/
	const _openBlockUserModal = (row) => {
		setUserId(row.user_id);
		setBlockStatus(row.is_blocked);
		const modal = new Modal(document.getElementById("Confirm"), {});
		modal.show();
	};

	const _closeMenu = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setMore(false);
		}
	};

	/* --- selected row pushed to array ---*/
	const _handleChange = (row) => {
		let selected = []
		for (let i = 0; i < row.selectedRows.length; i++) {
			selected.push(row.selectedRows[i]['user_id']);
		}
		setCheck(selected);
	};


	/*--------- active users list -----------*/
	const _getUsersList = (query) => {
		setCheck([]);
		setLoader(true);
		setUserData([])
		const url = '/user/list-user';

		const data = {
			from_date: dates.start,
			to_date: dates.end,
			is_invested: selectedStatus.value,
			query: query ? query.value : search,
			page_num: null,
			page_size: null
		};

		service(true, url, data).then((response) => {
			if (response.status_code === 200) {
				setCheck([]);
				userDataRef.current = response.data.users;
				setUserData(userDataRef.current);
			}
			else {
				setUserData([]);
			}

			setLoader(false);
		})
	}

	/*---- download list api--*/
	const _downloadData = () => {

		const url = '/user/list-download';

		const data = {
			user_ids: check
		}

		service(true, url, data).then((response) => {
			if (response.status_code === 200) {
				if (response.status === true) {
					let a = document.createElement("a");
					a.href = response.data.url;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					setCheck([]);
					setDownloadLoader(false);
					setSearch("");
					setSuccess("List downloaded successfully")

				} else {
					setError(response.data.message);
					setDownloadLoader(false);
					setCheck([]);
				}
			}
			else {
				setError(response.data.message);
				setDownloadLoader(false);
				setCheck([]);
			}
		})
	};

	


	return (
		<div className="row position-relative">
			<div className="col-lg-12">
				<h3 className="mb-4 pb-2 e-font-manrope e-font-weight-600 e-font-24 e-text-white">
					Users
				</h3>
				<div className="card e-dashboard-card border-0 h-auto">
					<div className="row pt-2">
						<div className="col-lg-4 col-3">
							<h6 className="e-section-title p-2">All Users</h6>
						</div>
						<div className="col-lg-3 col-4 align-items-center text-end" >
							<DownloadBtn
								loading={downloadLoader}
								check={check}
								onClick={() => {
									setDownloadLoader(true);
									_downloadData()
								}}
							/>
						</div>
						<div className="col-lg-4 col-4 ">
							<div className="position-relative d-flex align-items-center e-bg-search-bg e-border-radius-4">
								<SearchInput
									placeholder="Search with name/email/number/ID"
									value={search}
									// disabled={loader}
									valueChange={(value) => {
										setSearch(value);
										if (value === "") {
											_getUsersList({ value: "" })
											sessionStorage.removeItem("user_search");
										}
									}}
									onSearch={(value) => {
										_getUsersList({ value: value });
										sessionStorage.setItem("user_search", JSON.stringify(value));
									}}
								/>
								{
									search?.length > 0 &&
									<>
										<button className={`${styles.e_search_close} pe-3`}
											onClick={() => {
												setSearch("");
												_getUsersList({ value: "" });
												sessionStorage.removeItem("user_search");
											}} ></button>
										<span className={`${styles.e_search_submit}`} onClick={() => { _getUsersList(); }} ></span>
									</>
								}
							</div>
						</div>
						<div className="col-lg-1 col-1">
							<FilterBtn
								className={filterDown ? "e-filter-btn e-active" : "e-filter-btn"}
								handleClick={() => {
									setFilterDown(!filterDown);
									// setSearch("");
									// if (filterDown === true) {
									// 	setDates({ start: null, end: null });
									// 	setSelectedStatus(STATUS_OPTIONS[0])
									// }
								}}
							/>
						</div>
						{
							filterDown ?
								<div className="e-filter-wraper e-sort mt-3">
									<div className="row">
										<div className="col-auto pe-0">
											<label className="mb-2">Joined from - till</label>
											<DateRangePicker
												start={dates.start}
												end={dates.end}
												selectPrevious={true}
												// maxDate={moment().format('DD-MM-YYY')}
												onDatesChange={(start, end) => {
													setDates({ start: start, end: end });
													if (start === null && end === null) {
														sessionStorage.removeItem("range")
													} else {
														sessionStorage.setItem("range", JSON.stringify({ start: start, end: end }))
													}
												}}
											/>
										</div>
										<div className="col-lg-2 col-4 px-2">
											<label className="mb-2">Status</label>
											<SelectBox
												value={Object.keys(selectedStatus).length > 0 ? selectedStatus : null}
												options={STATUS_OPTIONS}
												selectChange={(temp) => {
													setSelectedStatus(temp);
													if (temp.value === null) {
														sessionStorage.removeItem("invested_status")
													} else {
														sessionStorage.setItem("invested_status", JSON.stringify(temp))
													}
												}}
												isSearchable={false}
											/>
										</div>

									</div>
								</div>
								:
								null
						}
						{
							loader ?
								<Loader />
								: userData.length > 0 ?
									<div className="col-lg-12 px-0">
										<DataTable
											pagination={true}
											columns={USER_COLUMNS}
											data={userData}
											perPage={30}
											clearSelectedRows={downloadLoader ? true : false}
											selectableRows={true}
											selectRows={(row) => { _handleChange(row) }}
											rowClick={(row) => navigate(`/user-detail?id=${row.user_id}`)}
										/>
									</div>
									:
									<EmptyNote title="Please try different filter" note="User list not found" />
						}
					</div>
				</div>
			</div>
			{/* confirmation modal for blocking */}
			{

				<ConfirmationforBlock
					title={`Are you sure, do you want to ${blockStatus === true ? "Unblock" : "Block"} this user`}
					id={userId}
					blockStatus={blockStatus}
					updateStatus={() => {
						const temp_users_list = [...userData]
						const user_index = temp_users_list.findIndex((item) => item.user_id === userId)
						temp_users_list[user_index].is_blocked = !blockStatus
						setUserData(temp_users_list)
					}} />
			}

			{/* error toast */}
			{
				error !== null ?
					<Toast
						data={error}
						type="fail"
						id="1"
					/>
					:
					null
			}
			{/* Success toast */}
			{
				success !== null ?
					<Toast data={success}
						type="success"
						id="2" />
					:
					null
			}

		</div>
	);
}

export default Users;

