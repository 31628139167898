export const _checkFileSize = (file_size) => {

    // 1 mb == 1000000 bytes , we are receiving the file size in bytes so,
    // Converting bytes to mb for easy usage
    let file_size_in_mb = file_size / 1000000;

    if (file_size_in_mb <= 5) {
        return true;
    } else {
        return false;
    };

};

export const _formatDateFormat = (selected_date) => {
    const [day, month, year] = selected_date.split("-");
    return new Date(`${year}-${month}-${day}`);
};

export const _formatSipDate = (date) => {
    switch (date) {
        case 1: return "1st of every month";
        case 2: return "2nd of every month";
        case 3: return "3rd of every month";
        case 21: return "21st of every month";
        case 22: return "22nd of every month";
        case 23: return "23rd of every month";
        default: return date + "th of every month";
    };
};

export const _closeModal = (close_modal) => {
    window.addEventListener("keydown", (event) => {
        if (event.key === "Escape" || event.key === "Esc") {
            close_modal();
        }
    });

    return window.removeEventListener("keydown", (event) => {
        if (event.key === "Escape" || event.key === "Esc") {
            close_modal();
        }
    });
};