/*
 *   File : orders.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Order list;
 *   Integrations : 28-11-2023
 *   Version : v1.0
 *   Created :28-11-2023
*/
/*import packages*/
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'bootstrap';
// import moment from 'moment';

/*import components*/
import { DataTable } from '../../Components/DataFormating/index';
import { FilterBtn, DownloadBtn } from '../../Components/Cta/index';
import { OrderDetail } from '../../Components/Modal/index';
import { SearchInput, DateRangePicker } from '../../Components/Input/index';
import SelectBox from '../../Components/Dropdown/select-box';
import { Loader } from '../../Components/Loader';
import { Toast } from '../../Components/Note';
import { EmptyNote } from '../../Components/Note';
/*import styles*/
import styles from '../../Styles/Components/search-input.module.scss';

/*import assets*/
import View from '../../Assets/Images/DataFormating/view.svg';
import Tooltip from '../../Components/Badge/tooltip';


/* import services */
import service from '../../Services/service';

const Orders = (props) => {

	const wrapperRef = useRef(null);
	const ORDER_STATUS_OPTIONS = useSelector(state => state.INDEX_FUND_STORE.ORDER_STATUS);
	const PAYMENT_STATUS_OPTIONS = useSelector(state => state.INDEX_FUND_STORE.PAYMENT_STATUS);
	const TYPE_OPTIONS = useSelector(state => state.INDEX_FUND_STORE.ORDER_TYPE_OPTIONS);
	const INVESTMENT_OPTIONS = useSelector(state => state.INDEX_FUND_STORE.INVESTMENT_TYPE_OPTIONS)

	const [search, setSearch] = useState("");
	const [filterDown, setFilterDown] = useState(false);
	const [dates, setDates] = useState({ start: null, end: null });
	const [more, setMore] = useState(false);
	const [refKey, setRefKay] = useState();
	const [loader, setLoader] = useState(true);
	const [error, setError] = useState(null);
	const [type, setType] = useState(TYPE_OPTIONS[0]);
	const [selectedOrderStatus, setSelectedOrderStatus] = useState(ORDER_STATUS_OPTIONS[0]);
	const [selectedPayStatus, setSelectedPayStatus] = useState(PAYMENT_STATUS_OPTIONS[0]);
	const [check, setCheck] = useState([])
	const [show, setShow] = useState(null);
	const [orderData, setOrderData] = useState([]);
	const [details, setDetails] = useState({});
	const [success, setSuccess] = useState(null);
	const [downloadLoader, setDownloadLoader] = useState(false);
	const [investmentType, setInvestmentType] = useState(INVESTMENT_OPTIONS[0]);

	// const [updatedInvestMentOption, setUpdatedInvestMentOption] = useState(INVESTMENT_OPTIONS);

	// useEffect(() => {
	// 	// setUpdated(pre => [
	// 	// 	...pre, {
	// 	// 		id: 4,
	// 	// 		label: "All"
	// 	// 	}
	// 	// ])

	// 	setUpdatedInvestMentOption(pre => {
	// 		const newOptions = [...pre];
	// 		newOptions.unshift({ id: 4, label: 'All' });
	// 		return newOptions;
	// 	});


	// }, [INVESTMENT_OPTIONS]);

	useEffect(() => {
		if (INVESTMENT_OPTIONS.length > 0) {
			setInvestmentType(INVESTMENT_OPTIONS[0]);
		}
	}, [INVESTMENT_OPTIONS]);

	// useEffect(() => {
	// 	_getOrderList();
	// }, []);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});

		document.addEventListener('mouseup', _closeMenu);
		return () => {
			document.removeEventListener('mouseup', _closeMenu);
		};
	}, []);

	useEffect(() => {
		_getOrderList();
	}, [dates, selectedPayStatus, type, selectedOrderStatus, investmentType]);

	useEffect(() => {
		setTimeout(() => {
			if (error !== null) {
				setError(null);
			}
		}, 3000);
	}, [error]);

	useEffect(() => {
		if (success !== null) {
			setTimeout(() => {
				setSuccess(null)
			}, 3000)
		}
	}, [success]);

	const _customDateSort = (a, b, selector, given) => {
		const dateA = new Date(a.order_time);
		const dateB = new Date(b.order_time);
		if (dateA < dateB) {
			return -1;
		} else if (dateA > dateB) {
			return 1
		} else {
			return 0
		}
	};

	/*--- func to open order detail modal ---*/
	const _modalOpen = (row) => {
		setDetails(row);
		var myModal = new Modal(document.getElementById("orderDetail"), {});
		myModal.show();
	};

	const _closeMenu = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setMore(false);
		}
	};

	/* --------selecting rows---------*/
	const _handleChange = (row) => {
		let selected = []
		for (let i = 0; i < row.selectedRows.length; i++) {
			selected.push(row.selectedRows[i]['order_id']);
		}
		setCheck(selected);
	};

	/*--------- order list -----------*/
	const _getOrderList = (query) => {
		setLoader(true)
		const url = '/user/order-list';
		const data = {
			user_id: Number(props.id),
			from_date: dates.start,
			to_date: dates.end,
			type: type.value,
			order_status: selectedOrderStatus.value,
			payment_status: selectedPayStatus.value,
			query: query ? (query.value) : search,
			investment_type: investmentType?.value
		}
		service(true, url, data).then((response) => {
			if (response.status_code === 200) {
				setOrderData(response.data);
				setCheck([]);
			} else {
				setOrderData([]);
			};

			setLoader(false);

		});

	};

	/*---- download orders--*/
	const _downloadOrderList = () => {

		const url = '/order/list-download';
		const data = {
			user_id: Number(props.id),
			order_ids: check
		};

		service(true, url, data).then((response) => {
			if (response.status_code === 200) {
				if (response.status === true) {
					let a = document.createElement("a");
					a.href = response.data.url;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					setSearch("");
					setCheck([]);
					setSuccess("Order list downloaded successfully");
				} else {
					setError(response.data.message)
				}

				setDownloadLoader(false);
			}
			else {
				setDownloadLoader(false);
				setError(response.data.message);
			}
		})
	};


	/*---- order table columns -----*/

	const ORDER_COLUMNS = [
		{
			name: 'Order ID',
			selector: (row) =>
				row.order_no ? row.order_no : '--',
			sortable: true,
			left: true,
			minWidth: "180px",
			defaultSortAsc: false,
		},
		{
			name: 'FP ID',
			selector: (row) => row.order_id ? row.order_id : '--',
			sortable: true,
			left: true,
			minWidth: "110px",
			maxWidth: "110px",
			defaultSortAsc: false,
		},
		{
			name: 'Created on',
			selector: row => row.order_time,
			sortable: true,
			left: true,
			minWidth: '140px',
			sortFunction: (a, b) => _customDateSort(a, b, 'order_time',),
			cell: row => <div className="col-lg-12" onClick={() => _modalOpen(row)}>
				{row.order_time}
			</div>
		},
		{
			name: 'Type',
			selector: 'type',
			sortable: true,
			left: true,
			minWidth: '120px',
			cell: (row) => <div className="col-lg-12" onClick={() => _modalOpen(row)} >
				{row.type}
			</div>
		},
		{
			name: 'Fund name',
			left: true,
			minWidth: "280px",
			cell: row => (
				<div className="d-flex gap-2 py-2"
					onClick={() => _modalOpen(row)}>
					<img src={row.icon}
						alt="fund_image"
						draggable="false"
						className='me-2 rounded-4 bg-white'
						width="24"
						height="24" />
					{row.name}
				</div>
			),
			omit: investmentType.value === 3 ? true : false

		},
		{
			name: 'Basket',
			selector: 'basket_name',
			sortable: true,
			left: true,
			minWidth: '120px',
			cell: (row) => <div className="col-lg-12" onClick={() => _modalOpen(row)} >
				{row.basket_name}
			</div>,
			omit: investmentType.value === 3 ? false : true
		},
		{
			name: 'Amount',
			selector: row => row.amount ? Number(row.amount.replaceAll(/[₹,]/g, "")) : 0,
			// selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
			sortable: true,
			left: true,
			minWidth: '130px',
			cell: row => <div onClick={() => _modalOpen(row)}>
				{
					row.amount ?
						row.amount
						:
						0
				}
			</div>

		},
		{
			name: 'Payment method',
			left: true,
			minWidth: "180px",
			cell: row => <div onClick={() => _modalOpen(row)}>
				{
					row.payment_type_id === 1 ?
						"Net banking"
						:
						"UPI"
				}
			</div>

		},
		{
			name: 'Order status',
			selector: 'order_status',
			sortable: false,
			left: true,
			minWidth: '160px',
			cell: row => <>{row.order_status ?
				<div className="d-inline-flex">
					<div className="col-lg-10">
						<div className={
							row.order_status === "Partially Success" ? `e-status-badge e-status-badge-partially` :
								row.order_status === "Success" ? `e-status-badge e-status-badge-success` :
									row.order_status === "Failed" ? `e-status-badge e-status-badge-fail` :
										row.order_status === "Pending" ? `e-status-badge e-status-badge-pending` :
											row.order_status === "Processing" ? `e-status-badge e-status-badge-pending` :
												'e-status-badge'
						}>
							{
								row.order_status
							}
						</div>
					</div>
				</div>
				:
				"--"}</>
		},
		{
			name: 'Payment status',
			selector: 'payment_status',
			sortable: false,
			left: true,
			minWidth: "160px",

			// cell: row => <div className="">
			//   <Badge type="success" text="success"/>
			// </div>

			cell: row => <>{row.payment_status ?
				<div className="d-inline-flex">
					<div className="col-lg-10">
						<div className={
							row.payment_status === "Success" ? `e-status-badge e-status-badge-success` :
								row.payment_status === "Failed" ? `e-status-badge e-status-badge-fail` :
									row.payment_status === "Pending" ? `e-status-badge e-status-badge-pending` :
										row.payment_status === "Processing" ? `e-status-badge e-status-badge-pending` :
											'e-status-badge'
						}>
							{row.payment_status.toLowerCase()}
						</div>
					</div>
					<div className="col-lg-2">
						<span onMouseEnter={() => setShow(row.order_id)} onMouseLeave={() => setShow(null)} className={
							row.payment_status === "Failed" ? `e-icon` :
								row.payment_status === "Processing" ? `e-icon` :
									null
						}>

						</span>
					</div>
					{
						show === row.order_id ?
							<Tooltip className={"min-width-170px left-25-per"}
								content={row.payment_failed_reason ? row.payment_failed_reason : row.payment_status === "Processing" ? "Transaction pending" : "Transaction failed"} />
							:
							null
					}
				</div>
				:
				"--"}</>
		},
		{
			name: '',
			selector: 'actions',
			sortable: false,
			right: true,
			width: "100px !important;",
			cell: (row) => (
				<div className="d-flex align-items-end"
					ref={wrapperRef}>
					<div className='col-5'
						onMouseOver={() => { setRefKay(row.order_id); setMore(true); }}
						onMouseLeave={() => { setRefKay(row.order_id); setMore(false); }}>


						<span className="e-more" >
						</span>
						{
							more && row.order_id === refKey ?
								<div className="e-card-transaction"
									ref={wrapperRef}
									onClick={() => _modalOpen(row)}>
									<span >
										<img src={View} alt="View details" className="me-2" />View Details
									</span>
								</div>
								:
								null
						}
					</div>

				</div>
			)
		},
	];

	return (
		<>
			<div className="row">
				<div className="col-lg-12">
					<div className="card e-dashboard-card border-0 min-vh-100">
						{
							orderData.length === 0 ?
								<EmptyNote note="There is no transaction to show" />
								:
								<div className="row pt-2">
									<div className="col-lg-4 col-3">
										<h6 className="e-card-title p-2">All Orders</h6>
									</div>
									<>
										<div className="col-lg-3 col-4 align-items-center text-end" >
											<DownloadBtn
												loading={downloadLoader}
												check={check}
												onClick={() => {
													setDownloadLoader(true);
													_downloadOrderList();
												}}
											/>
										</div>
										<div className="col-lg-4 col-4 position-relative">
											<div className="d-flex align-items-center e-bg-search-bg e-border-radius-4">
												<SearchInput
													placeholder="Search with Order ID"
													value={search}
													valueChange={(value) => {
														setSearch(value);
														if (value === "") {
															_getOrderList({
																value: ""
															})
														}
													}}
													onSearch={(value) => {
														// searchOrders(value)
														_getOrderList({ value: value })
													}}
												/>
												{
													search && search.length ?
														<>
															<button
																className={`${styles.e_search_close} pe-3`}
																onClick={() => {
																	setSearch("");
																	_getOrderList({
																		value: ""
																	});

																}}>
															</button>
															<span
																className={`${styles.e_search_submit} `}
																onClick={() => {
																	_getOrderList()
																}}>
															</span>

														</>
														:
														null
												}
											</div>
										</div>
										<div className="col-lg-1 col-1">
											<FilterBtn
												// img={Filter}
												className={filterDown ? "e-filter-btn e-active" : "e-filter-btn"}
												handleClick={() => {
													setFilterDown(!filterDown);
													setSearch("");
													// if (filterDown === true) {
													// 	setDates({ start: null, end: null });
													// 	setSelectedPayStatus({ label: "All", value: null });
													// 	setType({ label: "All", value: null });
													// }
												}}
											/>
										</div>
									</>
									{/*-------------- Filters  ---------------*/}
									{
										filterDown ?
											<div className="e-filter-wraper e-sort mt-3">
												<div className="row">
													<div className="col-lg-3 col-4 ">
														<label className="mb-2">Transactions from - till</label>
														<DateRangePicker
															start={dates.start}
															end={dates.end}
															onDatesChange={(start, end) => {
																setDates({ start: start, end: end })
															}}
														/>
													</div>
													<div className="col-lg-2 col-3  p-0">
														<label className="mb-2">Investment type</label>
														<SelectBox
															value={investmentType}
															options={INVESTMENT_OPTIONS}
															selectChange={(temp) => {
																setInvestmentType(temp);
															}}
															isSearchable={false}
														/>
													</div>
													<div className="col-lg-2 col-3 px-2">
														<label className="mb-2">Type</label>
														<SelectBox
															value={type}
															options={TYPE_OPTIONS}
															selectChange={(temp) => setType(temp)}
															isSearchable={false}
														/>
													</div>
													<div className="col-lg-2 col-3  p-0">
														<label className="mb-2">Payment status</label>
														<SelectBox
															value={selectedPayStatus}
															options={PAYMENT_STATUS_OPTIONS}
															selectChange={(temp) => setSelectedPayStatus(temp)}
															isSearchable={false}
														/>
													</div>
													<div className="col-lg-2 col-3 px-2">
														<label className="mb-2">Order status</label>
														<SelectBox
															value={selectedOrderStatus}
															options={ORDER_STATUS_OPTIONS}
															selectChange={(temp) => setSelectedOrderStatus(temp)}
															isSearchable={false}
														/>
													</div>
												</div>
											</div>
											:
											null
									}

									{/*--------------- order table -----------*/}
									{
										loader === true ?
											<Loader />

											:
											<div className="col-lg-12 px-0">
												<DataTable
													pagination={true}
													columns={ORDER_COLUMNS}
													data={orderData}
													selectableRows={true}
													perPage={30}
													clearSelectedRows={downloadLoader ? true : false}
													selectRows={(row) => { _handleChange(row) }}
													rowClick={(row) => _modalOpen(row)}
												/>
											</div>
									}
								</div>
						}
					</div>
				</div>
			</div>

			{/*--------- Order detail modal component ----------------*/}
			<div ref={wrapperRef}>
				<OrderDetail
					data={details}
					id={props.id}
					order_status={details.order_status}
					payment_status={details.payment_status}
					orders={details.funds}
					fName={props.fName}
				/>
			</div>
			{/* error toast */}
			{
				error !== null ?
					<Toast
						data={error}
						type="eror"
						id="1"
					/>
					:
					null
			}
			{/* Success toast */}
			{
				success !== null ?
					<Toast data={success}
						type="success"
						id="2" />
					:
					null
			}
		</>
	);
}

export default Orders;

