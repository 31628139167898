/*
 *   File : date-picker.js
 *   Author : https://evoqins.com
 *   Description : Date picker component
 *   Integrations : react-calendar
 *   Version : 1.0.0
*/

import React, { useEffect, useRef, useState } from 'react';

import Calendar from 'react-calendar';
import { format } from "date-fns";

import 'react-calendar/dist/Calendar.css';


// custom component 
import { OverLay } from '../Other';


// Styles
// import Styles from "../../Styles/Components/date-picker.module.scss";

// Assets
import CalenderIcon from "../../Assets/Images/Kyc/calendar.svg";

const DatePicker = (props) => {

    const calendarRef = useRef(null);

    const [value, setValue] = useState(new Date());
    const [showValue, setShowValue] = useState("");
    const [showCalender, setShowCalender] = useState(false);

    useEffect(() => {
        if (props.value) {
            setValue(format(props.value, "MM/dd/yyyy"));
            setShowValue(format(props.value, "dd MM yyyy").replaceAll(" ", "-"));
        } else {

            if (props.activeDate) {
                setValue(props.activeDate);
            } else {
                setShowValue("");
                setValue(new Date());
            }
        };
    }, [props.value]);

    useEffect(() => {

        if (showCalender === true) {
            const react_calendar = document.querySelector("#calender-wrapper");
            react_calendar.scrollIntoView({ behavior: "smooth", block: "start" });
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = 'unset';
        }

    }, [showCalender]);

    //handle onchange date
    const _handleOnChangeDate = (date) => {

        setValue(date);
        setShowValue(format(date, "dd MM yyyy").replaceAll(" ", "-"));
        props.onChange(date);
        setShowCalender(false);
    };

    const _handleShowCalender = () => {
        setShowCalender((showCalender) => !showCalender);
    };

    // format day according to the design
    function formatWeekDay(locale, date) {
        const day_format = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
        const day_index = date.getDay();
        const formatted_day = day_format[day_index];
        return formatted_day;
    };

    return (
        <div className='position-relative cursor-pointer' ref={calendarRef}>

            <div id='calender' className={`${showCalender === true ? "e-border-1px-primary-blue" : "e-border-1px-tab-color"} ${props.error && "border-red-1px"} position-relative e-border-radius-8`}
                onClick={_handleShowCalender}>
                <img src={CalenderIcon}
                    draggable={false}
                    className='position-absolute end-0 top-50 translate-middle-y me-3'
                    alt='calender' />

                {/* Displayed value */}
                <p className={`${showValue == "" && "opacity-50"} px-3 e-padding-tb-12px e-text-chinese-black e-font-manrope e-font-14 mb-0 `}>
                    {showValue !== "" ? showValue : "DD-MM-YYYY"}&nbsp;
                </p>
            </div>

            {/* Error message */}
            <p className={`${props.error === "" ? "opacity-0" : "opacity-100 "} h-10 mb-0 e-error-message e-font-12 line-height-12px pt-1`}>
                {props.error}
            </p>

            {/* Background overlay */}
            {showCalender === true &&
                <div className='py-4' id='calender-wrapper'>
                    {
                        props.overLay &&
                        <OverLay isOpen={showCalender} onClose={_handleShowCalender} />
                    }
                    <div className={`z-index-1100 position-absolute pb-4`}>
                        <Calendar value={value}
                            minDate={props.minDate}
                            maxDate={props.maxDate}
                            nextLabel=">"
                            next2Label={null}
                            prevLabel="<"
                            prev2Label={null}
                            showNeighboringMonth={false}
                            locale="en-IN"
                            formatShortWeekday={formatWeekDay}
                            onChange={_handleOnChangeDate} />
                    </div>
                </div>
            }
        </div>
    )
}

export default DatePicker;

DatePicker.defaultProps = {
    overLay: true
}