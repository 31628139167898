/*
 *   File : verify-email.js
 *   Author : https://evoqins.com
 *   Description : verify email and otp modal
 *   Integrations : react-toastify
 *   Version : 1.0.0
*/

import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

//custom component
import { PrimaryBtn, SecondaryBtn } from '../Cta';
import { OTPInput } from '../Form-Elements';
import service from '../../Services/service';
import { _closeModal } from '../../Helper/function';

const VerifyPhoneAndEmailModal = (props) => {

    const inputPhoneRef = useRef(null);
    const inputEmailRef = useRef(null);

    const [phoneOTP, setPhoneOTP] = useState("");
    const [phoneOTPError, setPhoneOTPError] = useState("");

    const [emailOTP, setEmailOTP] = useState("");
    const [emailOTPError, setEmailOTPError] = useState("");

    const [focusedPhoneInput, setFocusedPhoneInput] = useState(0);
    const [focusedEmailInput, setFocusedEmailInput] = useState(0);

    const [timer, setTimer] = useState(30);
    const [loading, setLoading] = useState(false);

    // Closing modal when "ESC" pressed
    useEffect(() => {
        _closeModal(props.onCloseModal);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setFocusedPhoneInput(2);
        }, 500);
    }, []);

    //for timer
    useEffect(() => {
        if (timer > 0) {
            // Start the timer when requestOtpSuccess becomes true
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            // Clear the timer when the component unmounts or when the timer reaches 0
            return () => {
                clearInterval(countdown);
            };
        }
    }, [timer]);

    //handle phone otp change
    const _handleChangePhoneOTP = (value) => {
        setPhoneOTP(value);
        setPhoneOTPError("");

        if (value.length == 4) {
            setFocusedEmailInput(2);
        }
    };

    //handle email otp change
    const _handleChangeEmailOTP = (value) => {
        setEmailOTP(value);
        setEmailOTPError("");
    };

    // handle OTP resend
    const _handleOTPResend = () => {
        setTimer(30);
        _resendOTP();
    };

    //handle verify otp
    const _handleVerifyOTP = () => {

        let valid = true;

        // Phone OTP verification
        if (props.indianUser) {
            if (phoneOTP.length === 0) {
                setPhoneOTPError("Please enter a valid OTP.");
                valid = false;
            } else if (phoneOTP.length !== 4) {
                setPhoneOTPError("Please enter the full OTP");
                valid = false;
            }
        }

        // Email OTP verification
        if (emailOTP.length === 0) {
            setEmailOTPError("Please enter a valid OTP.");
            valid = false;
        } else if (emailOTP.length !== 4) {
            setEmailOTPError("Please enter the full OTP");
            valid = false;
        }

        if (valid) {
            setLoading(true);
            _verifyOTP();
        }
    };

    // Send phone otp
    const _resendOTP = () => {

        let is_post = true;
        let url = '/kyc/get-signin-otp';
        let data = {
            "name": props.name,
            "country_code": props.countryCode,
            "phone_number": props.phone,
            "email": props.email,
            "pan": props.pan,
            "type": "RESEND"
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setPhoneOTP("");
                setEmailOTP("");
                setPhoneOTPError("");
                setEmailOTPError("");
                setFocusedPhoneInput(2);
                toast.dismiss();
                toast.success(`OTP re-sent successfully to ${props.indianUser && "phone number and"} email`);
            } else {
                toast.dismiss();
                toast.error(response.data.message);
            }
        });
    };

    //API- verify OTP 
    const _verifyOTP = () => {
        let is_post = true;
        let url = '/kyc/verify-otp';
        let data = {
            "country_code": props.indianUser ? props.countryCode : undefined,
            "phone_number": props.indianUser ? props.phone : undefined,
            "phone_otp": props.indianUser ? phoneOTP : undefined,
            "email": props.email,
            "email_otp": emailOTP
        };

        service(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                toast.dismiss();
                toast.success("Account details verified successfully");

                let close_btn = document.getElementById("verify-phone-modal-close");
                close_btn.click();

                props.onSuccess(response.data.customer_id);
            } else {
                toast.dismiss();
                toast.error(response.data.message);
            }

            setLoading(false);
        });
    };

    return (
        <div className="modal fade"
            id="verify-phone-email-modal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="verifyEmailModalLabel"
            aria-hidden="true">
            <div className={`modal-dialog  modal-dialog-centered`}>
                <div className="modal-content e-border-radius-8 p-0">
                    <div className="modal-header  pt-4 px-4 pb-3 border-0">
                        <h6 className='mb-0 e-text-chinese-black e-font-18 e-font-manrope e-font-weight-600 line-height-32px'>
                            OTP Verification
                        </h6>
                        <button type="button"
                            id="verify-phone-modal-close"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={props.onCloseModal}></button>
                    </div>
                    <div className="modal-body pb-4 px-4 pt-0">
                        <label className="e-text-chinese-black e-font-14 e-font-manrope e-font-weight-500 line-height-24px">
                            Enter the 4-digit OTP received on {props.indianUser && "phone number and"} email
                        </label>
                        {
                            props.indianUser === true &&
                            <div className='h-64 mt-2'>
                                <label className="e-text-chinese-black e-font-14 e-font-manrope e-font-weight-500 line-height-24px">
                                    Enter Phone number OTP<span className='e-text-red'>*</span>
                                </label>
                                <OTPInput ref={inputPhoneRef}
                                    id="otp-phone"
                                    autoFocus={true}
                                    value={phoneOTP}
                                    spellCheck="false"
                                    focused_input={focusedPhoneInput}
                                    error={phoneOTPError}
                                    onFocus={() => setFocusedPhoneInput(2)}
                                    onBlur={() => setFocusedPhoneInput(0)}
                                    onChange={_handleChangePhoneOTP} />
                            </div>
                        }
                        <div className='h-64 mt-1'>
                            <label className="e-text-chinese-black e-font-14 e-font-manrope e-font-weight-500 line-height-24px">
                                Enter Email OTP<span className='e-text-red'>*</span>
                            </label>
                            <OTPInput ref={inputEmailRef}
                                id="otp-mobile"
                                autoFocus={true}
                                value={emailOTP}
                                spellCheck="false"
                                focused_input={focusedEmailInput}
                                error={emailOTPError}
                                onFocus={() => setFocusedEmailInput(2)}
                                onBlur={() => setFocusedEmailInput(0)}
                                onChange={_handleChangeEmailOTP} />
                        </div>
                        {/* timer */}
                        <div className='d-flex pt-1 align-items-center'>
                            <button className={`${timer !== 0 && "e-disable"} ps-0 border-0 bg-transparent e-text-egyptian-blue e-font-12 e-font-manrope e-font-weight-500 line-height-26px`}
                                onClick={_handleOTPResend} >
                                Re-send OTP
                            </button>
                            {
                                timer !== 0 &&
                                <p className='mb-0 e-text-chinese-black e-font-12 e-font-manrope e-font-weight-600 line-height-26px'>
                                    in {timer} {timer == 1 ? "second" : "seconds"}
                                </p>
                            }
                        </div>
                        {/* custom buttons */}
                        <div className='row pt-4 justify-content-end'>
                            <div className='col-4'>
                                <SecondaryBtn name="Close"
                                    className="w-100 h-100 py-0"
                                    cancel="modal"
                                    handleClick={props.onCloseModal} />
                            </div>
                            <div className='col-4'>
                                <PrimaryBtn name="Continue"
                                    loading={loading}
                                    disabled={loading}
                                    className="w-100 h-40 d-flex justify-content-center"
                                    handleClick={_handleVerifyOTP} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyPhoneAndEmailModal;